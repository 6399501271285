import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { INewMessagePage } from "netbank-shared/src/libs/models/Content/Page";
import { IMessage } from "netbank-shared/src/libs/models/Message/Conversations";
import { getQueryParam } from "../../../../utils/misc";
import { Button } from "~views/shared";
import styles from "./Conversation.scss";
import { TextArea } from "~views/shared/TextArea/TextArea";
import santanderLogo from "~assets/logo-icon.svg";
import rootStyles from "../../Root.scss";
import undoIcon from "~assets/undo.svg";
import { MessageEmptyState } from "./MessageEmptyState";
import { InitialsIcon } from "./InitialsIcon";
import { LoadingLottie } from "~views/shared/Lottie/LoadingLottie";
import {
  AllowedAttachmentAmountSetting,
  ConversationEmptyState,
  Direction,
} from "netbank-shared/src/libs/models/Content/Enums";
import { MessageAttachment } from "./MessageAttachment";
import { FileAttachment } from "~views/shared/FileAttachment/FileAttachment";
import { processMessageHtml, getMaxAttachmentsAmount, getSubjectsFromProduct } from "netbank-shared/src/libs/utils";

export const Conversation = observer(() => {
  const { uiStore, messageStore, contentStore } = useStores();
  const page = contentStore.currentPage as INewMessagePage;
  const navigate = useNavigate();
  const [invalidMessage, setInvalidMessage] = useState(false);
  const conversationId = getQueryParam(window.location.search, "conversationId");

  const { conversationData } = messageStore;

  useEffect(() => {
    if (conversationData?.hasUnreadMessages) {
      messageStore.markAsRead(conversationId);
    }
  }, [messageStore.conversationData]);

  const handleClear = () => {
    messageStore.setReplyMessageBody("");
  };

  const handleSubmit = async () => {
    if (!messageStore.replyMessageBody) {
      setInvalidMessage(true);
    } else {
      await messageStore.reply(conversationId);
    }
  };

  const handleDownloadClick = async (id: string) => messageStore.downloadAttachment(id);

  const handleBack = () => {
    navigate(page?.messageInboxPage ?? `/${tx("routing.lang")}`);
    messageStore.resetStore();
  };

  const renderPreviousMessage = (dark: boolean, prevMessageData: IMessage) => {
    const classes = [styles.prevMessageWrapper];
    if (dark) {
      classes.push(styles.dark);
    }

    return (
      <div key={prevMessageData.messageId} className={classes.join(" ")}>
        <div className={styles.messageHeader}>
          <div className={styles.from}>
            {prevMessageData.direction === Direction.Received ? (
              <img src={santanderLogo} alt="santander-logo" />
            ) : (
              <InitialsIcon name={prevMessageData.createdBy} />
            )}
            <span>{prevMessageData.createdBy}</span>
          </div>
          <div className={styles.date}>
            <span>{prevMessageData.createdDate.split("T")[0]}</span>
          </div>
        </div>
        <div
          className={styles.messageContent}
          dangerouslySetInnerHTML={{
            __html: processMessageHtml(prevMessageData.body, prevMessageData.direction),
          }}
        />
        <div className={styles.attachments}>
          {prevMessageData.attachments?.map((a) => (
            <MessageAttachment
              key={a.id}
              attachment={a}
              onDownloadClick={(id) => handleDownloadClick(id)}
              loading={messageStore.downloadingAttachments.has(a.id)}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderPreviousMessages = (messages: IMessage[]) => {
    messages.shift();
    return messages.map((message, index) => {
      let dark = true;
      if (index % 2 === 0) {
        dark = false;
      }
      return renderPreviousMessage(dark, message);
    });
  };

  const renderEmptyState = () => {
    switch (messageStore.conversationEmptyState) {
      case ConversationEmptyState.ConversationNotFound:
        return <MessageEmptyState data={page.errorCaseEmptyState} />;
      case ConversationEmptyState.ReplyMessageSuccess:
        return <MessageEmptyState data={page.happyCaseEmptyState} />;
      case ConversationEmptyState.ReplyMessageFailed:
        return <MessageEmptyState data={page.errorCaseEmptyState} />;
      default:
        return undefined;
    }
  };

  const products = page?.productDropdown;
  const product = products.find((p) => conversationData?.productType === p.product);
  const subjects = (product && getSubjectsFromProduct(product, uiStore.lang)) || [];
  const subject = subjects.find((s) => conversationData?.subjectName === s.value);

  const showFileAttachment =
    !!subject?.extra?.allowedAttachmentAmountSetting &&
    subject?.extra?.allowedAttachmentAmountSetting !== AllowedAttachmentAmountSetting.None;

  return (
    <>
      {!!messageStore.conversationEmptyState && renderEmptyState()}
      {messageStore.conversationEmptyState === undefined && (
        <section className={uiStore.isMobile ? rootStyles.noPadding : ""}>
          <div className={styles.backButtonWrapper}>
            <Button className={styles.backButton} onClick={() => handleBack()}>
              <div className={styles.backButtonContent}>
                <img className={styles.backIcon} src={undoIcon} alt="undo icon" />
                <span className={styles.backButtonTitle}>{tx("message.goBack")}</span>
              </div>
            </Button>
          </div>
          <div className={styles.messageWrapper}>
            {!conversationData && <LoadingLottie />}
            {conversationData && (
              <>
                <div className={styles.messageTitle}>{conversationData.title}</div>
                <div className={styles.message}>
                  <div className={styles.messageHeader}>
                    <div className={styles.from}>
                      {conversationData.messages[0].direction === Direction.Received ? (
                        <img src={santanderLogo} alt="santander-logo" />
                      ) : (
                        <InitialsIcon name={conversationData.messages[0].createdBy} />
                      )}
                      <span>{conversationData.messages[0].createdBy}</span>
                    </div>
                    <div className={styles.date}>
                      <span>{conversationData.messages[0].createdDate.split("T")[0]}</span>
                    </div>
                  </div>
                  <div
                    className={styles.messageContent}
                    dangerouslySetInnerHTML={{
                      __html: processMessageHtml(
                        conversationData.messages[0].body,
                        conversationData.messages[0].direction,
                      ),
                    }}
                  />
                  <div className={styles.attachments}>
                    {conversationData.messages[0].attachments?.map((a) => (
                      <MessageAttachment
                        key={a.id}
                        attachment={a}
                        onDownloadClick={(id) => handleDownloadClick(id)}
                        loading={messageStore.downloadingAttachments.has(a.id)}
                      />
                    ))}
                  </div>
                </div>
                {!conversationData.isClosed && (
                  <>
                    <div className={styles.replyFormWrapper}>
                      {showFileAttachment && (
                        <FileAttachment
                          id="newConversation"
                          acceptedMimeTypes={messageStore.acceptedDocumentMimeTypes}
                          acceptedExtensions={messageStore.acceptedDocumentExtensions}
                          attachments={messageStore.attachments}
                          addAttachments={(attachments) => messageStore.addAttachments(attachments)}
                          removeAttachment={(fileId) => messageStore.removeAttachment(fileId)}
                          maxAttachments={getMaxAttachmentsAmount(
                            subject?.extra?.allowedAttachmentAmountSetting || AllowedAttachmentAmountSetting.None,
                            messageStore.maxFilesPerMessage,
                          )}
                        />
                      )}
                      <TextArea
                        rows={6}
                        value={messageStore.replyMessageBody}
                        label={tx("message.reply")}
                        placeholder={tx("message.textareaReplyPlaceholder")}
                        onChange={(e) => {
                          messageStore.setReplyMessageBody(e.target.value);
                          setInvalidMessage(!e.target.value);
                        }}
                        error={(invalidMessage && tx("message.messageError")) || undefined}
                      />
                      <div className={styles.buttonWrapper}>
                        <div className={styles.buttonActionsWrapper}>
                          <Button
                            title={tx("misc.clear")}
                            bordered
                            centered
                            borderColor="grey"
                            onClick={() => handleClear()}
                          />
                          <Button
                            title={tx("misc.send")}
                            bordered
                            centered
                            color="red"
                            borderColor="grey"
                            onClick={() => handleSubmit()}
                            loading={messageStore.sendingMessage}
                          />
                        </div>
                      </div>
                    </div>
                    {conversationData.messages.length > 1 && (
                      <div className={styles.replyLabel}>{tx("message.prevDialog")}</div>
                    )}
                  </>
                )}
                <div className={styles.prevMessages}>{renderPreviousMessages([...conversationData.messages])}</div>
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
});
