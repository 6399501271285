import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchData, useStores, useTracking } from "netbank-shared/src/hooks";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { tx } from "netbank-shared/src/libs/i18n";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import accordionStyle from "~views/shared/ContentArea/Blocks/AccordionBlock.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import { EmptyState, InfoText } from "~views/shared";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { IPendingApplication } from "netbank-shared/src/libs/models/Offer/Offer";
import { AccordionName, PendingApplicationState } from "netbank-shared/src/libs/models/Content/Enums";
import { AccordionContext } from "netbank-shared/src/contexts/accordionContext";

export interface LoanPageProps {
  location: Location;
}

const renderDisabledAccountContent = (blockedEmptyState: IEmptyState) => {
  return (
    <section>
      <div className={[accordionStyle.wrapper, accordionStyle.open].join(" ")}>
        <div className={accordionStyle.header}>{blockedEmptyState.header}</div>
        <div className={accordionStyle.content}>
          <div className={accordionStyle.verticalPadding}>
            <EmptyState data={blockedEmptyState} />
          </div>
        </div>
      </div>
    </section>
  );
};

const SalesFinancePage = observer(({ location }: LoanPageProps) => {
  const navigate = useNavigate();
  const { loanId } = useParams<IProductRouteParams>();
  const { contentStore, loanStore, uiStore, offerStore } = useStores();

  const dataLayer = useTracking((window as any).dataLayer);

  const { currency, locale } = uiStore;

  const { currentAccount } = loanStore;

  const isDisabled = currentAccount?.isDisabled;

  const loanTemplate = contentStore.currentSalesFinanceTemplatePage;

  const showHasOverdueInvoiceBanner =
    !isDisabled && currentAccount?.hasOverdueInvoice && loanTemplate?.accountHasOverdueInvoiceText;
  const showAMLFrozenBanner = currentAccount?.isAmlFrozen && loanTemplate?.accountIsFrozenAMLText;

  const showAccountStatusBanners = !isDisabled && (showHasOverdueInvoiceBanner || showAMLFrozenBanner);

  let pendingApplication: IPendingApplication | undefined;
  if (currentAccount) {
    pendingApplication = offerStore.getActivePendingApplicationByAccountNumber(currentAccount.displayNumber, true);
  }

  useFetchData({
    condition: !!loanId && !loanStore.loadingAccount,
    callback: () => {
      loanStore.getLoanAccount(loanId);
    },
    cleanup: () => {
      loanStore.resetLoanPayments();
      loanStore.resetCurrentAccount();
    },
  });

  useFetchData({
    condition: !!loanId && !loanStore.loadingOverduePayments && !loanStore.overduePayments,
    callback: () => {
      loanStore.getOverduePayments(loanId);
    },
  });

  useFetchData({
    condition: !!loanId && !loanStore.loadingDuePayment && loanStore.duePayment === undefined,
    callback: () => {
      loanStore.getDuePayment(loanId);
    },
  });

  useFetchData({
    callback: () => {
      if (loanStore.currentAccountId !== loanId && loanId) loanStore.currentAccountId = loanId;
      if (currentAccount?.accountId === loanId) contentStore.getSalesFinanceTemplate();
    },
    deps: [location, currentAccount],
  });

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage || loanStore.loadingAccount) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const expandedAccordions: AccordionName[] = [];
  if (loanStore.overduePayments && loanStore.overduePayments.length > 0) {
    expandedAccordions.push(AccordionName.AccordionOverduePaymentsBlock);
  }
  if (loanStore.duePayment) {
    expandedAccordions.push(AccordionName.AccordionActiveInvoiceBlock);
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        <div>
          <h1>{currentAccount.name}</h1>
          <p>
            <span className={styles.bold}>{loanTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
        </div>
      </section>
      {!isDisabled && (
        <section>
          <div className={styles.templateHeader}>
            {!uiStore.isMobile && (
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${loanTemplate?.image?.url})`,
                }}
              />
            )}
            <div className={styles.templateContent}>
              <div className={styles.availableBalance}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.actualAmount}</span>
                  <h3>{toLocaleString(currentAccount.bookedBalance, currency, locale)}</h3>
                </div>
                {uiStore.isMobile && (
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${loanTemplate?.image?.url})`,
                    }}
                  />
                )}
              </div>
              <div className={styles.flexBetween}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.interest || tx("misc.interest")}</span>
                  <h4>{`${toLocaleString(currentAccount.accountInterest || 0, undefined, locale, 2)} %`}</h4>
                </div>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.totalAmount}</span>
                  <h4>{toLocaleString(currentAccount.creditLimit, currency, locale)}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!isDisabled && pendingApplication?.applicationId && loanTemplate?.loanTopUpPage && (
        <>
          {pendingApplication.applicationState === PendingApplicationState.Disbursement && (
            <section>
              <InfoText
                header={
                  loanTemplate.pendingApplicationsDisbursementBannerHeader ||
                  tx("pendingApplications.disbursementBannerHeader")
                }
                text={
                  loanTemplate.pendingApplicationsDisbursementBannerContent ||
                  tx("pendingApplications.disbursementBannerContent")
                }
                level={NotificationLevel.important}
              />
            </section>
          )}

          {pendingApplication.applicationState !== PendingApplicationState.Disbursement && (
            <section>
              <InfoText
                header={loanTemplate.pendingApplicationsBannerHeader || tx("pendingApplications.bannerHeader")}
                text={loanTemplate.pendingApplicationsBannerContent || tx("pendingApplications.bannerContent")}
                buttonText={
                  loanTemplate.pendingApplicationsBannerButtonText || tx("pendingApplications.bannerButtonText")
                }
                buttonAction={() => {
                  dataLayer.pushInteraction(TrackingCategory.ProductLoan, TrackingAction.LoanResume);
                  navigate(`${loanTemplate.loanTopUpPage}?applicationId=${pendingApplication!.applicationId}`);
                }}
                level={NotificationLevel.important}
              />
            </section>
          )}
        </>
      )}
      {showAccountStatusBanners && (
        <section className={styles.accountStatusContainer}>
          {showHasOverdueInvoiceBanner && (
            <InfoText
              accountHasOverdueInvoiceText={loanTemplate.accountHasOverdueInvoiceText}
              level={NotificationLevel.information}
              accountNumber={currentAccount.accountId}
            />
          )}
          {showAMLFrozenBanner && (
            <InfoText text={loanTemplate?.accountIsFrozenAMLText} level={NotificationLevel.error} />
          )}
        </section>
      )}

      {!isDisabled && (
        <AccordionContext.Provider value={{ expandedAccordions }}>
          <ContentArea page={loanTemplate} />
        </AccordionContext.Provider>
      )}
      {isDisabled &&
        loanTemplate?.accountBlockedEmptyState?.[0] &&
        renderDisabledAccountContent(loanTemplate.accountBlockedEmptyState[0])}
    </div>
  );
});

export default withRoot(SalesFinancePage);
