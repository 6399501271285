import { PaymentHolidayConsecutivePeriodConditionType } from "../Content/Enums";
import { IAccountInsurance } from "../Content/Insurance";
import { IBaseAccount } from "./BaseAccount";
import { ICarInformation } from "./Car";
import { IPromiseToPayInvoiceInfo, InvoiceDistributionFormatType } from "./Invoice";

export interface ILoanAccountApiResponse {
  loanAccount?: ILoanAccount;
  error?: string;
}

export interface ILoanAccountsApiResponse {
  loanAccounts?: ILoanAccount[];
  errors?: string;
}
export interface IGetPaymentsRequest {
  accountId: string;
  paymentType: PaymentType;
  dateFrom?: string;
  dateTo?: string;
}

export enum PaymentType {
  All = "all",
  Due = "due",
  Overdue = "overdue",
  Paid = "paid",
}

export interface IPaymentsResponse {
  payments: IPayment[];
}

export interface ILoanTerminationDetailsDocument {
  fileName: string;
  mimeType: string;
  fileBytes: string;
}

export interface IPayment {
  customerNumber?: string;
  periodStartDate?: string;
  periodEndDate?: string;
  sequenceNumber?: string;
  totalAmount?: number;
  balance?: number;
  insuranceFee?: number;
  adminFee?: number;
  interestAmount?: number;
  remainderAmount?: number;
  invoiceNumber: string;
  status?: string;
  interestRate?: number;
  installmentAmount?: number;
  invoiceDate?: string;
  isPending?: boolean;
  dueDate?: string;
  valueDate: string;
  bookDate?: string;
  paymentPeriod?: string;
  paymentReference?: IPaymentReference;
  additionalAmounts?: IAdditionalAmount[];
  feesAndInsurance?: number;
}

export interface IPaymentReference {
  bankAccount: string;
  businessIdentificationCode: string;
  invoiceReference: string;
  receiverName: string;
  virtualBarcode?: string;
}

export interface IAdditionalAmount {
  amount: number;
  description: string;
  type: AdditionalAmountType;
}

export enum AdditionalAmountType {
  UseDescription = "UseDescription",
  Insurance = "Insurance",
  MonthlyFee = "MonthlyFee",
  ReminderFee = "ReminderFee",
  PrincipalPenalty = "PrincipalPenalty",
  TerminationFee = "TerminationFee",
}

export interface ILoanAccountTerminationDetails {
  accountId: string;
  accountNumber: string;
  accountName: string;
  validToDate: string;
  dueDate: string;
  matureDate: string;
  interestRate: number;
  balance: number;
  calculatedBalance: number;
  interest: number;
  invoiceTotal: number;
  total: number;
  expenses: number;
  fees: number;
  paymentInformation: IPaymentInformation;
  consentQuestions: ILoanTerminitionConsentQuestion[];
  terminationCalculationBreakdown?: ITerminationCalculationBreakdown;
  detailsDocument: ILoanTerminationDetailsDocument;
}

export interface ILoanAccountSubmitTermination {
  accountId: string;
  accountNumber: string;
  accountName: string;
  success: boolean;
}

export interface IConsentReply {
  questionId: string;
  answerId: string;
  contactPhoneNumber?: string;
}

export interface ILoanTerminitionConsentQuestion {
  questionId: string;
  questionText: string;
  sortOrder: number;
  answerOptions: ILoanTerminitionAnswerOption[];
}

export interface ILoanTerminitionAnswerOption {
  answerId: string;
  answerText: string;
  isDefaultAnswer: boolean;
  sortOrder: number;
  collectContactPhoneNumber: boolean;
}

export enum LoanTerminationDebtor {
  Customer = "Customer",
  ThirdParty = "ThirdParty",
}

export interface IPaymentInformation {
  registrationNumber: string;
  accountNumber: string;
  referenceNumber: string;
  ibanNumber: string;
}

export interface ITerminationCalculationBreakdown {
  interest: number;
  openInvoices: number;
  overDue: number;
  penaltyFees: number;
  periodicFees: number;
  restBalance: number;
  terminationFee: number;
  prePayment: number;
  otherExpenses: number;
}

export interface IPaymentHolidayDetails {
  elligibleFromDate: string;
  elligibleToDate: string;
  isRecurringPaymentHoliday: boolean;
  allowedPaymentHolidayMonths: number;
  allowedPaymentHolidayMonthsPerYear: number;
  consecutivePeriodCondition: PaymentHolidayConsecutivePeriodConditionType;
  currentPaymentHolidayMonths: string[];
  canUpdateCurrentPaymentHolidayMonths: boolean;
}

export interface ILoanAccount extends ILoanBaseAccount {
  accountDetails?: AccountDetails;
  canCalculateTerminationAmount?: boolean;
  mainDebtor?: string;
  coApplicantName?: string;
  hasCoApplicant?: boolean;
  paymentAccount?: string;
  hasInsurance?: boolean;
  bankTransferMinimumFee?: number;
  bankTransferPercentageFee?: number;
  creditLimit?: number;
  hasDirectDebit?: boolean;
  accountInterest?: number;
  interestOffsetRate?: number;
  interestVariableRateType?: string;
  invoiceFee?: number;
  coDebtor?: string;
  invoiceDay?: number;
  carInformation?: ICarInformation;
  paymentHolidayDetails?: IPaymentHolidayDetails;
  openDate?: string;
  contractEndDate?: string;
  contractMileage?: number;
  balloonAmount?: number;
  insurances?: IAccountInsurance[];
  canTerminateAccount?: boolean;
  canUpdateDueDay?: boolean;
  canPromiseToPay?: boolean;
  canPromiseToPayInvoiceInformation?: IPromiseToPayInvoiceInfo;
  invoiceDistributionFormat?: InvoiceDistributionFormatType;
  invoiceMethod?: string;
  canRequestMileageChange?: boolean;
  canReduceInstallments?: boolean;
  canIncreaseInstallments?: boolean;
  canExtendResidualAmountPayment?: boolean;
  canAddInstallment?: boolean;
  canRequestInsurance?: boolean;
  canRequestPaymentHoliday?: boolean;
  fees?: {
    reduceInstallmentsFee?: number;
    mileageChangeFee?: number;
    extendResidualAmountPaymentFee?: number;
    paymentHolidayFee?: number;
  };
  eInvoice?: {
    eInvoiceDate: string;
  };
}

export interface ILoanBaseAccount extends IBaseAccount {
  hasPendingApplication?: boolean;
}

export interface AccountDetails {
  bankAccount: string;
  businessIdentificationCode: string;
  description: string;
  receiverName: string;
  referenceNumber: string;
  thirdPartyFee: number;
}

export interface IGetInvoicesRequest {
  accountId: string;
  invoiceStatus: InvoiceStatus;
}

export enum InvoiceStatus {
  Paid = "paid",
}

export interface IInvoicesResponse {
  invoices: IPayment[];
}
