/* eslint-disable no-undef */
import {
  TopupHousingType,
  ApplicantEmploymentType,
  PurposeOfLoan,
  SourceOfFunds,
  PendingApplicationState,
} from "../Content/Enums";
import { IMultiSelectOption } from "../MultiSelect/MultiSelectOption";

export interface IOffersResponse {
  offers: IOffer[];
}

export interface IOffer {
  offerId: string;
  accountNumber: string;
  offerType?: string;
  offerCategory: OfferCategory;
  validFrom: string;
  validTo: string;
  priority: number;
  cardNumber?: string;
  insurance?: IInsurance;
  status?: string;
  offerAmount: number;
  campaignId?: string;
  ansokCampaignId?: string;
  ansokStoreId?: string;
  latestResponseCode?: string;
  salesText?: string;
  description?: string;
  categoryText?: string;
  latestResponseText?: string;
  inVisible: boolean;
  isInsuranceOffer?: boolean;
  isPFMOffer?: boolean;
  isAddCardOffer?: boolean;
  isActivateCardOffer?: boolean;
}

export enum OfferCategory {
  LINC = "LINC",
  PFMC = "PFMC",
  PFML = "PFML",
  PPI = "PPI",
  PPIC = "PPIC",
  PPIL = "PPIL",
  AMC = "AMC",
  AOL = "AOL",
  CIR = "CIR",
}

export interface IInsurance {
  schemaNumber: string;
  schemeName?: string;
  cost: number;
  costCalculatedOn: number;
  ppiCostCalculatedOnText: string;
  startDate?: Date;
  endDate?: Date;
  premium?: number;
  status?: string;
}

export interface ILoanCalculation {
  effectiveInterest: number;
  totalCost: number;
  monthlyCost: number;
}

export interface ITopUpApplicantForm {
  email: ValidationObject<string>;
  phone: ValidationObject<string>;
  monthlyIncome: ValidationObject<number>;
  employmentType: ValidationObject<string>;
  employer: ValidationObject<string>;
  housingType: ValidationObject<string>;
  housingCost: ValidationObject<number>;
  numberOfChildren: ValidationObject<number>;
  sourceOfFunds: ValidationObject<string>;
  sourceOfFundsIsSalary: ValidationObject<boolean | undefined>;
  customSourceOfFunds: ValidationObject<string>;
  hasApprovedCreditCheck: ValidationObject<boolean>;
  citizenships: ValidationObject<IMultiSelectOption[]>;
  isApplyingPersonally: ValidationObject<boolean>;
  isPep: ValidationObject<boolean | undefined>;
}

export interface IApplicantEmploymentDetails {
  employmentStatus: ApplicantEmploymentType;
  employer: string;
}

export interface IApplicantFinancialDetails {
  monthlyGrossIncome: number;
  housingCost: number;
}

export interface IApplicantAmlDetails {
  originOfFunds: SourceOfFunds;
  originOfFundsOther: string | undefined;
}

export interface IApplicantDetails {
  accommodationStatus: TopupHousingType;
  employmentDetails: IApplicantEmploymentDetails;
  financialDetails: IApplicantFinancialDetails;
  numberOfChildrenInHousehold: number;
  email: string;
  mobile: string;
  amlDetails: IApplicantAmlDetails;
}

export interface IAddTopUpRequest {
  addOnApplication: IAddOnApplication;
}

export interface IAddOnApplication {
  appliedAmount: number;
  paybackTime: number;
  mainApplicantDetail?: IApplicantDetails;
  coApplicantDetail?: IApplicantDetails;
  applicantsShareLivingCost?: boolean;
  purposeOfLoan?: PurposeOfLoan;
  customPurposeOfLoan?: string;
}

export interface IAddTopUpResponse {
  applicationId: string;
  title: string;
  errors?: any;
  detail?: string;
}

export interface IUpdateApplicantDetails {
  citizenships: string[];
  politicallyExposedPerson: boolean;
  applyingOnBehalfOfSomeoneElse: boolean;
}

export interface IUpdateApplicationRequest {
  mainApplicantDetail?: IUpdateApplicantDetails;
  coApplicantDetail?: IUpdateApplicantDetails;
  accountNumber: string;
  clearingNumber: string;
}

export interface IUpdateApplicationResponse {
  applicationId: string;
}

export interface IPendingApplication {
  customerId: number;
  applicationId: string;
  accountType: string;
  accountNumber: string;
  monthlyCost: number;
  requestedAmount: number;
  approvedAmount: number;
  paybackTime: number;
  interestRate: number;
  coApplicantName?: string;
  applicationState: PendingApplicationState;
  signUrl?: string;
}

export interface IPendingApplicationsResponse {
  pendingApplications?: IPendingApplication[];
  title?: string;
  status: number;
  errors?: any;
  detail?: string;
}
