import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { ITransferAccountFields } from "netbank-shared/src/libs/models/Transfer/Transfer";
import styles from "../LoanTopUpFlow.scss";
import offerStyles from "~views/pages/Offers/Offers.scss";
import { Button } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import {
  ListItem,
  TransferAccountDropdown,
} from "~views/shared/ContentArea/Blocks/Accordions/Transfer/TransferAccountDropdown";
import { SavedAccountForm } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/SavedAccountForm";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { usePushInteraction } from "~hooks";
import { LoadingLottie } from "~views/shared/Lottie/LoadingLottie";

export interface LoanTopUpPayoutSelectionStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpPayoutSelectionStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpPayoutSelectionStepProps) => {
    const { transferStore, offerStore } = useStores();
    const { payoutSelectionStep } = data;
    const [createPayoutAccount, setCreatePayoutAccount] = useState(false);
    const [noPayoutAccount, setNoPayoutAccount] = useState(false);

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise3Account,
    });

    useEffect(() => {
      const fetchData = async () => {
        if (!transferStore.savedAccounts) {
          await transferStore.getSavedAccounts();
        }
      };
      fetchData();
    }, []);

    const { payoutAccount, setPayoutAccountByName, setPayoutAccount } = offerStore;

    const dropdownClasses = [styles.savedAccountsDropdown];

    if (createPayoutAccount) {
      dropdownClasses.push(styles.createAccount);
    }

    const createSavedAccount = async () => {
      const accountToCreate = transferStore.newAccount;
      const created = await transferStore.createSavedAccount(true);
      if (created) {
        setCreatePayoutAccount(false);
        setNoPayoutAccount(false);
        setPayoutAccount(accountToCreate);
      }
    };

    if (transferStore.fetchingSavedAccounts) {
      return <LoadingLottie />;
    }

    return (
      <div>
        {payoutSelectionStep.payoutAccountHeader && (
          <HtmlContent html={`<h1>${payoutSelectionStep.payoutAccountHeader}</h1>`} />
        )}
        {payoutSelectionStep.payoutAccountTopText && <HtmlContent html={payoutSelectionStep.payoutAccountTopText} />}

        <div className={dropdownClasses.join(" ")}>
          <TransferAccountDropdown
            infoPopover={{
              content: payoutSelectionStep.choosePayoutAccountInformation,
              popoverTitle: payoutSelectionStep.choosePayoutAccountHeader || tx("topUp.choosePayoutAccountHeader"),
            }}
            label={payoutSelectionStep.choosePayoutAccountHeader || tx("topUp.choosePayoutAccountHeader")}
            subtext={payoutSelectionStep.choosePayoutAccountText}
            value={
              payoutAccount
                ? `${payoutAccount.displayName} | ${payoutAccount.clearingNumber}-${payoutAccount.accountNumber}`
                : tx("transfer.chooseReceiver")
            }
            onChange={(value: ListItem) => {
              setNoPayoutAccount(false);
              setPayoutAccountByName(value.label);
            }}
            createToAccount={createPayoutAccount}
            setCreateToAccount={(value: boolean) => {
              if (value) {
                setPayoutAccountByName(undefined);
              }
              setCreatePayoutAccount(value);
            }}
            error={(noPayoutAccount && !createPayoutAccount && tx("transfer.noToAccountError")) || undefined}
          />
        </div>

        {createPayoutAccount && (
          <SavedAccountForm
            header={tx("transfer.addNewReceiver")}
            handleSavedAccount={createSavedAccount}
            account={transferStore.newAccount}
            setAccount={(account: ITransferAccountFields) => {
              setNoPayoutAccount(false);
              transferStore.setNewAccount(account);
            }}
            loading={transferStore.creatingSavedAccount}
            clearingNumberLabel={payoutSelectionStep.clearingNumberLabel || tx("transfer.clearingNumber")}
            accountNumberLabel={payoutSelectionStep.accountNumberLabel || tx("transfer.accountNumber")}
            clearingNumberInformation={payoutSelectionStep.clearingNumberInformation}
            clearingStartsWithEightThenFiveDigitsError={payoutSelectionStep.clearingStartsWithEightThenFiveDigitsError}
            accountNumberInformation={payoutSelectionStep.accountNumberInformation}
            saveLabel={payoutSelectionStep.saveAndUseLabel}
            error={
              (transferStore.duplicateSavedAccountError && tx("transfer.duplicateAccount")) ||
              (noPayoutAccount &&
                createPayoutAccount &&
                transferStore.newAccount.clearingNumber &&
                transferStore.newAccount.accountNumber &&
                transferStore.newAccount.displayName &&
                tx("transfer.saveToAccountError")) ||
              undefined
            }
          />
        )}
        <div className={offerStyles.submit}>
          <Button
            title={payoutSelectionStep.previousButtonText || tx("misc.cancel")}
            onClick={setPreviousTopUpStep}
            borderColor="black"
            bordered
            large
          />
          <Button
            title={payoutSelectionStep.continueButtonText || tx("misc.continue")}
            color="red"
            onClick={() => {
              if (!offerStore.payoutAccount) {
                setNoPayoutAccount(true);
                return;
              }
              setNextTopUpStep();
            }}
            large
          />
        </div>
      </div>
    );
  },
);
