import React from "react";
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Button } from "../Button/Button";
import styles from "./Forms.scss";

interface IFormWrapperProps<T extends FieldValues> {
  formMethods: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  submitLabel: string;
  children: React.ReactNode;
  cancelAction?: () => void;
  cancelLabel?: string;
}

export const FormWrapper = <T extends FieldValues>({
  formMethods,
  onSubmit,
  submitLabel,
  children,
  cancelAction,
  cancelLabel,
}: IFormWrapperProps<T>) => {
  const { isValid, isSubmitting, isSubmitted } = formMethods.formState;

  const isSubmittable = !isSubmitted || isValid;

  return (
    <FormProvider {...formMethods}>
      <form className={styles.wrapper}>
        {children}
        <div className={styles.formActions}>
          {cancelAction && cancelLabel && (
            <Button
              onClick={cancelAction}
              title={cancelLabel}
              bordered
              borderColor="black"
              color="white"
              large
              disabled={isSubmitting}
            />
          )}
          <Button
            onClick={formMethods.handleSubmit(onSubmit)}
            title={submitLabel}
            color={isSubmittable ? "red" : ""}
            bordered={!isSubmittable}
            loading={isSubmitting}
            centered
            large
          />
        </div>
      </form>
    </FormProvider>
  );
};
