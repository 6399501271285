/* eslint-disable new-cap */
/* eslint-disable no-undef */

import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { AxiosAdapter } from "axios";
import MockAdapter from "axios-mock-adapter";
import { IConfig } from "../models/Config";
import { DocumentType, Limits } from "../models/Content/Enums";
import {
  IDocument,
  IEvryToken,
  IDataPortabilityDocument,
  ITransactionDocumentRequest,
  ITransactionDocument,
} from "../models/CustomerProducts";
import { IAccountInvoices } from "../models/CustomerProducts/Invoice";
import { IGetTerminationAmountRequest, ITerminationAmount } from "../models/CustomerProducts/TerminationAmount";
import { Store } from "../../stores";

export class EngagementsApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "api-version": "1.0",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
      };
      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: "",
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getAccountInvoices = async (accountNumber: string, offset?: number, limit: number = Limits.invoice) =>
    this.client?.get<IAccountInvoices>(`invoices`, { accountNumber, offset, limit, v: "1.0" }, this.getHeaders());

  getInvoicePdf = async (accountNumber: string, documentId: string) =>
    this.client?.get<BlobPart>(
      `invoicePdfs`,
      { accountNumber, documentId, v: "1.0" },
      {
        responseType: "blob",
        ...this.getHeaders({ Accept: "application/pdf" }),
      },
    );

  getShowPinToken = async () => this.client?.get<IEvryToken>("/Cards/ShowPinToken", { v: "1.0" }, this.getHeaders());

  getShowCardToken = async () => this.client?.get<IEvryToken>("/Cards/ShowCardToken", { v: "1.0" }, this.getHeaders());

  activateCard = async (cardId: string) =>
    this.client?.put<boolean>("/Cards/activatecard?v=1.0", { cardId }, this.getHeaders());

  hardblockCard = async (cardId: string, blockCardReqReason?: any) =>
    this.client?.put<boolean>("/Cards/hardblockcard?v=1.0", { cardId, blockCardReqReason }, this.getHeaders());

  orderCard = async (accountNumber: string, reason?: any) =>
    this.client?.post<boolean>("/Cards/ordercard?v=1.0", { accountNumber, reason }, this.getHeaders());

  getDocuments = async (accountNumber?: string, documentType?: DocumentType) =>
    this.client?.get<IDocument[]>(`/Documents`, { accountNumber, documentType, v: "1.0" }, this.getHeaders());

  getDocument = async (documentId: string) =>
    this.client?.get<IDocument>(`/Documents/${documentId}`, { v: "1.0" }, this.getHeaders());

  getTransactionDocument = async ({ accountNumber, fromDate, toDate }: ITransactionDocumentRequest) =>
    this.client?.get<ITransactionDocument>(
      `/Documents`,
      {
        accountNumber,
        toDate,
        fromDate,
        v: "1.0",
        documentType: DocumentType.Transactions,
      },
      this.getHeaders(),
    );

  getDataPortabilityDocument = async () =>
    this.client?.get<IDataPortabilityDocument>(
      `/DataPortabilityDocuments/${DocumentType.DataPortability}`,
      { v: "1.0" },
      this.getHeaders(),
    );

  getTerminationAmount = async (data: IGetTerminationAmountRequest) => {
    const { accountNumber, terminationDate } = data;
    return this.client?.get<ITerminationAmount>(
      `/${accountNumber}/terminationAmounts`,
      { terminationDate, v: "1.0" },
      this.getHeaders(),
    );
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
