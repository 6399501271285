import React from "react";

import { observer } from "mobx-react";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { IHtmlContentQueryLinkBlock } from "netbank-shared/src/libs/models/Content/IHtmlContentQueryLinkBlock";
import { objectToQueryString } from "netbank-shared/src/libs/utils";

interface IHtmlContentQueryLink {
  data: IHtmlContentQueryLinkBlock;
  accountNumber?: string;
  className?: string;
}

export const HtmlContentQueryLinkBlock = observer(({ data, accountNumber, className }: IHtmlContentQueryLink) => {
  const { textContent, link, includeAccountNumber, queryParams } = data;

  if (!textContent || !link) return null;

  const obj: {} = {};

  if (includeAccountNumber && accountNumber) {
    Object.assign(obj, { accountNumber });
  }

  if (queryParams) {
    queryParams.forEach((qp) => {
      Object.assign(obj, { [qp.queryKey]: qp.queryValue || qp.messageSubjectQueryValue });
    });
  }

  const queryString = objectToQueryString(obj);

  // Only able to select on link in CMS
  const linkItem = link[0].link[0];

  const queryParamLink = {
    queryParamLink: `<a href=${`${linkItem.href}?${queryString}`}>${linkItem.title}</a>`,
  };

  return <HtmlContent className={className} html={textContent} queryParamLink={queryParamLink} />;
});
