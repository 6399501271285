import MockAdapter from "axios-mock-adapter";
import { mockUpdateContactInfo } from "./customer";
import { mockSECustomerData } from "./customer/seCustomer";
import { mockDKCustomerData } from "./customer/dkCustomer";
import { mockFICustomerData } from "./customer/fiCustomer";

export const MockCustomerApi = (mock: MockAdapter) => {
  mock.onGet("").reply((config) => {
    if (config && config.headers) {
      const country = config.headers["x-country"];
      switch (country) {
        case "SE":
          return [200, mockSECustomerData];
        case "DK":
          return [200, mockDKCustomerData];
        case "FI":
          return [200, mockFICustomerData];
        default:
          return [500];
      }
    }
    return [500];
  });

  mock.onPut("").reply(201, mockUpdateContactInfo);
};
