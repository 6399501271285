import { ENPathNames } from "../routing";

export default {
  translation: {
    locale: "en",
    routing: {
      ...ENPathNames,
      term: "terms",
    },
    routeNames: {
      home: "Home",
      transfers: "Transfers",
      messages: "Messages",
      card: "Card",
      loan: "Loan",
      deposit: "Deposit",
      leasing: "Leasing",
      menu: "Menu",
    },
    validators: {
      required: "{{- name, capital}} is required",
      email: "Incorrect email address",
      phone: "Incorrect {{- name, lower}}",
      pidn: "Incorrect SSN",
    },
    invoice: {
      currentInvoice: "Current invoice",
      overdueAmount: "Overdue amount",
      movedToDebtCollection: "Moved to debt collection",
      accountName: "Account name",
      accountNumber: "Account number",
      autogiro: "Autogiro",
      bankgiro: "Bankgiro",
      customerNumber: "Customer number",
      loadMore: "Load more invoices",
      disclaimer: `To avoid being charged interest, pay {{- amount }} this month. The amount includes
      interest-bearing purchases, cash withdrawals and any fees.`,
      downloadInvoice: "Download PDF",
      showInvoice: "Show invoice",
      dueDate: "Due date",
      invoice: "Invoice",
      invoiceAndDate: "Invoice {{- date, lower}}",
      invoiceDate: "Invoice date",
      invoiceDetails: "Invoice details",
      invoiceNumber: "Invoice number",
      invoicePeriod: "Invoice period",
      invoiceArchive: "Invoice archive",
      amountToPay: "Amount to pay",
      minimumPayment: "Minimum payment",
      maximumPayment: "Maximum payment",
      ocr: "Reference number (OCR)",
      plusGiro: "plusGiro",
      stopped: "The invoice is stopped.",
      stopText: `We have received documentation regarding your complaint. Invoicing has been stopped awaiting
      the outcome of the external review. If you have any questions, please contact us at {{- phoneNumber }}.`,
      total: "Total credit amount",
      balanceAtDate: "Balance at date",
      seeInvoice: "See invoice",
      invoiceDownload: "Download invoice",
      status: "Status",
      titles: {
        cancelled: "The product is cancelled {{- date, lower}}",
        overdue: "Overdue invoice, {{- date, lower}}",
        currentPlaceholder: "Current invoice",
        current: "Current invoice, {{- date, lower}}",
        extraPayment: "Would you like to pay extra?",
        paymentFreeMonth: "Would you like a payment free month?",
      },
      statuses: {
        due: "Due",
        cancel: "Cancelled",
        paid: "Paid",
        overdue: "Overdue",
        replaced: "Replaced",
        pending: "Pending",
      },
      emptyState: {
        header: "No result found!",
        text: "",
      },
    },
    card: {
      accountNumber: "Account number",
      accountName: "Account name",
      accountType: "Account type",
      brokenCard: "Broken card",
      credit: "Credit",
      coApplicant: "Co-applicant",
      card: "Card",
      insurance: "Insurance",
      lostCard: "Lost card",
      autogiro: "Autogiro",
      pfm: "Payment free month",
      cardNumber: "Card number",
      expiresAt: "Expires at",
      creditLimit: "Credit limit",
      orderCard: "Order card",
      order: "Order",
      hasCard: "Card is available",
      activateCard: "Activate card",
      status: "Card status",
      blockCard: "Block card",
    },
    deposit: {
      accountDetailsLabel: "Account details",
      accountBalanceLabel: "Balance",
      availableAmountLabel: "Available amount",
      withdrawalTimeLabel: "Withdrawal time",
      myDeposit: "My deposit",
      myDepositWithdrawalTime: "1-3 days",
      myDepositPlus: "My deposit+",
      myDepositPlusWithdrawalTime: "31 days",
      interestFeesLabel: "Interest and fees",
      accumulatedInterestLabel: "Accumulated interest this year",
      freeOfChargeLabel: "Fee",
      freeOfCharge: "Free of charge",
      transferAccount: "Withdrawal account",
      chooseTransferAccount: "Choose transfer account",
      chooseReceiverOrCreate: "Select a saved account or create a new one",
      newReceiver: "New recipient",
      accountInformation: "Account information",
      accruedInterest: "Accrued interest",
      tax: "Tax",
      closingDate: "Closing date",
      closeAccount: "Close account",
      closeAccountConfirmation: "Close account",
      closeAccountWarningText: "Are you sure you want to close your account, this action cannot be undone.",
      receiverSuccessLabel: "Your money will be paid out to",
      receiverAccount: "Withdrawal account",
      accountRedemptionErrorHeader: "Something went wrong",
      accountRedemptionErrorText: "Something went wrong, try again later",
      totalSavingAmount: "Total savings amount",
      totalTransferAmount: "Total to be paid out",
      openDate: "Open date",
      accountOpenDateLabel: "Oprettet dato",
      availableBalance: "Available balance",
      bookedBalance: "Booked balance",
      interestCarryingBalance: "Interest Carrying Balance",
      terminationFee: "Termination Fee",
      expirationDate: "Expiration Date",
      nationalPayoutAccountInformation: "Transaction will always be to your NemKonto",
      availableCredit: "Available credit",
      usedCredit: "Used credit",
      creditAmount: "Credit amount",
      getCreditInsurance: "Take out credit protection - an insurance policy for your card",
    },
    transfer: {
      fromAccount: "From account",
      toAccount: "To account",
      amount: "Amount",
      bank: "Bank",
      transferMoney: "Transfer",
      accountNumber: "Account number",
      addNewAccount: "Add new account",
      clearingNumber: "Clearing number",
      manageSavedAccounts: "Manage saved accounts",
      addAccount: "Add account",
      availableAmount: "Available amount",
      ownedAccountsLabel: "Your accounts",
      savedAccountsLabel: "Your saved accounts",
      newReceiverLabel: "New recipient",
      addNewReceiver: "Add recipient",
      chooseReceiver: "Choose recipient...",
      amountToTransfer: "Amount to transfer",
      bankTransferFee: "Bank transfer fee",
      balanceAfterTransfer: "Balance after transfer",
      noAmountError: "Remember to specify the amount you want to transfer.",
      noFromAccountError: "Remember to select the account you want to transfer from.",
      noToAccountError: "Remember to select the account you want to transfer to.",
      saveToAccountError: "Remember to save the new recipient account.",
      amountError: "You cannot transfer more than the amount available in the account",
      newTransfer: "Make a new transfer",
      transferedAmount: "Transfered amount",
      transferErrorHeader: "Something went wrong",
      transferErrorText: "Something went wrong, try again later",
      clearingNumberError: "Check that you have entered the correct clearing number.",
      accountNumberError: "Check that you have entered the correct account number.",
      invalidTotalLengthError: "You have entered the account number incorrectly, please try again.",
      clearingStartsWithEightThenFiveDigitsError: "Clearing numbers starting with 8 always have 5 digits",
      displayNameError: "Enter a name",
      duplicateAccount: "The account number is already in your list of saved recipients.",
      accountName: "Account name",
    },
    loan: {
      totalSum: "Total sum",
      informationLabel: "Loan details",
      amount: "Loan amount",
      credit: "Balance",
      coAppliant: "Co-borrower",
      insurance: "Loan protection",
      directDebit: "Autogiro",
      number: "Loan number",
      terminationAmountError: "Something went wrong. Try again later",
      paymentInfo: "Payment information",
      getLoanInsurance: "Take out loan protection - an insurance policy for your loan",
      technicalIssueTitle: "Technical issues",
      technicalIssueText: "We're currently having trouble getting certain account types. Please try again later.",
    },
    leasing: {
      licensePlate: "License number",
      leaseInformationLabel: "Information about the lease",
      accountNumberLabel: "Account number",
      expirationDateLabel: "First due date for rent",
      rentalPeriodLabel: "Rental period",
      dealerLabel: "Dealer",
      modelLicenseLabel: "Model and license number",
      interestFeesLabel: "Interest and fees",
      aviFeeLabel: "Administration fee",
    },
    misc: {
      customer: "Customer",
      chooseProduct: "Choose product",
      chooseACard: "Choose card",
      chooseEmploymentStatus: "Choose employment status",
      fee: "Fee",
      interest: "Interest",
      interestFees: "Interest and fees",
      effectiveInterest: "Effective interest",
      nominalInterest: "Nominal interest",
      title: "Net Bank",
      logout: "Logout",
      published: "Published",
      name: "Name",
      accountNumber: "Account number",
      accountName: "Account name",
      balance: "Balance",
      date: "Date",
      purchaseDate: "Purchase date",
      dateRange: "Date range",
      foreignAmount: "Foreign amount",
      exchangeRate: "Exchange rate",
      reservedAmount: "Reserved amount",
      amount: "amount",
      receiver: "Recipient",
      accountingDate: "Accounting date",
      transactionDate: "Transaction date",
      interestDate: "Accounting date",
      paymentDate: "Payment date",
      fromAccount: "From account",
      message: "Message",
      messages: "Messages",
      ocrMessage: "OCR-number/message",
      ocrNumber: "OCR-number",
      youHave: "You have",
      unreadMessage: "Unread message",
      unreadMessages: "Unread messages",
      unpaidInvoice: "Unread invoice",
      unpaidInvoices: "Unread invoices",
      and: "and",
      search: "Search",
      cancel: "Cancel",
      apply: "Apply",
      applyFor: "Apply for",
      amountFrom: "From amount",
      amountTo: "To amount",
      dateFrom: "Date from",
      dateTo: "Date to",
      selectDate: "Select date",
      clear: "Clear",
      clearFilter: "Clear filter",
      select: "Select",
      email: "E-mail",
      mobile: "Mobile number",
      phone: "Phone number",
      nameAdress: "Name & address",
      sms: "SMS",
      internetbank: "Netbank",
      saved: "Saved",
      updateInfo: "Update",
      downloadInfo: "Download",
      licensePlate: "License number",
      fetchMore: "Fetch more",
      showLess: "Show less",
      yes: "Yes",
      no: "No",
      invoiceFee: "Invoice fee",
      bankTransferFee: "Bank transfer fee",
      atmFee: "ATM withdrawal fee",
      currencyExchangeFee: "Currency exchange fee",
      status: "Status",
      active: "Active",
      inactive: "Inactive",
      ssn: "Social security number",
      storeName: "Description",
      atLeast: "At least",
      upTo: "Up to",
      exists: "Exists",
      missing: "Missing",
      blocked: "Blocked",
      notActive: "Not active",
      other: "Other",
      send: "Send",
      delete: "Delete",
      save: "Save",
      close: "Close",
      edit: "Edit",
      seeTerms: "See terms",
      saveAndUse: "Save and use",
      summary: "Summary",
      terminate: "Terminate",
      continue: "Continue",
      years: "year",
      monthShort: "mon",
      with: "with",
      contractNumber: "Contract number",
      directDebit: "Autogiro",
      document: "Document",
      headline: "Headline",
      done: "Done",
      chooseDifferentDate: "Choose a different date",
      calculate: "Calculate",
      termsButtonText: "I have read and understand the terms",
      order: "Order",
      doesNotExist: "Does not exist",
      accountInformation: "Account information",
      accountDetails: "Account details",
      nameAndDate: "Name / date",
      exportXLSX: "Export to spreadsheet (xlsx)",
      reserved: "Reserved",
      outgoing: "Outgoing",
      multiSelectNoOptions: "No matches",
      chooseCitizenshipPlaceholder: "Start typing, select country from the list",
    },
    date: {
      periodOfTime: "Period of time",
      last30: "Last 30 days",
      last3Months: "Last 3 months",
      thisYear: "This year",
      months: "months",
    },
    emptyState: {
      header: "No result found!",
      text: "Keep calm, light a fire and try again!",
    },
    login: {
      header: "Login",
      text: "In the netbank, you as a private customer can easily do your banking whenever you want.",
      updateRequired: "You need to download the latest version of the app to log in.",
      placeholder: "Enter social security number",
      loginButtonTextMobile: "Mobile BankID",
      loginButtonTextDesktop: "BankID on this device",
      loginMobileBankId: "Login with mobile BankID",
      waitingForMobileBankID: "Waiting for response from Mobile BankID",
      rememberMe: "Remember me",
      mockData: "Mock data",
      loginQRTitle: "Scan QR code with the BankID app",
      verifyingTextDesktop: "Enter your security code in the BankID app. Click here if you need help.",
      verifyingTextMobile: "To log in, launch your BankID application on your mobile.",
      bankIDLinkName: "here",
      santanderHomepageURL: "https://www.santanderconsumer.se",
    },
    message: {
      inbox: "Inbox",
      archive: "Archive",
      toArchive: "Archive",
      selectAccountText: "Select account",
      selectSubjectText: "Select subject",
      selectAreaText: "Select area",
      accountLabelText: "Select your account",
      subjectLabelText: "Select a subject",
      areaLabelText: "Select an area",
      composeNewMessage: "Compose new message",
      newMessage: "New message",
      sendMessage: "Send message",
      textareaPlaceholder: "Write your message here",
      textareaReplyPlaceholder: "Click to type your reply",
      attach: "Attach",
      faq: "Common questions",
      loadMore: "Load more mail",
      reply: "Reply",
      prevDialog: "Previous dialogue",
      backToInbox: "Back to the inbox",
      inboxEmpty: "Your inbox is empty",
      productError: "Select an account",
      subjectError: "Select a subject",
      areaError: "Select an area",
      messageError: "Enter a message",
      fileSizeError: "The file you tried to upload was too large",
      attachmentsError: "Maximum number of attachments has been reached",
      goBack: "Go back",
      sent: "Sent",
    },
    offers: {
      PPIC: "Credit protection",
      PFMC: "Payment-free month",
      PFML: "Payment-free month",
      LINC: "Credit increase",
      PPIL: "Payment protection",
      AOL: "Increase loan",
    },
    topUp: {
      header: "Increase loan",
      mainApplicantHeader: "Your details",
      coApplicantHeader: "Co-applicant",
      loanAmountLabel: "Loan amount",
      repaymentTimeLabel: "Repayment time",
      monthlyCostLabel: "Monthly cost",
      currentLoanAmountLabel: "Loan amount before increase",
      loanAmountIncreaseLabel: "Total increase",
      newLoanAmountLabel: "New loan amount",
      sharedAccomodationLabel: "Do you share a household with the main applicant?",
      creditCheckSliderText: "A credit check will be done.",
      creditCheckSummaryText:
        "Since you have chosen an amount that is higher than the promised amount, a credit check will need to be done",
      monthlyIncomeBeforeTaxLabel: "Monthly income (before tax)",
      monthlyIncomeLabel: "Monthly income",
      employmentStatusLabel: "Employment status",
      emailLabel: "E-mail",
      phoneLabel: "Phone number",
      employerLabel: "Current employer",
      employedFromLabel: "Employed as of year and month",
      fromLabel: "As of",
      toLabel: "Up to and including",
      unEmployedFromLabel: "Unemployed since",
      employedToLabel: "Employed up to and including year and month",
      housingCostLabel: "Housing cost",
      numberOfChildrenLabel: "Number of children living at home",
      loansMonthlyCostLabel: "Monthly cost of loan (not mortgage)",
      citizenShipsLabel: "Citizenship",
      loanInsuranceHeader: "Take out Loan Protection for extra security",
      mainApplicantSummaryHeader: "Your details",
      coApplicantSummaryHeader: "Co-applicant details",
      approveAndSign: "Approve and sign",
      payoutAccountLabel: "Payout account",
      choosePayoutAccount: "Choose payout account",
      employmentStatus: {
        PermanentPosition: "Permanent employment",
        TemporaryPosition: "Temporary employment",
        SelfEmployed: "Self-employed",
        Student: "Student",
        Retired: "Pension",
        Unemployed: "Unemployed",
      },
      sourceOfFundsSalaryLabel: "I will repay the loan with my main income",
      sourceOfFundsLabel: "Where does the money to repay the loan mainly come from?",
      customSourceOfFundsPlaceholder: "Specify source of refund",
      purposeOfLoanLabel: "I want to borrow to",
      customPurposeOfLoanPlaceholder: "The money will be used for",
      sourceOfFunds: {
        Salary: "Salary",
        Pension: "Pension",
        Savings: "Savings",
        SocialBenefits: "Social benefits",
        CommercialIncome: "Income from business activities including rental income",
        SaleOfRealEstate: "Gain on sale of property",
        SaleOfMovables: "Gains from the sale of movable property (car, boat, etc.)",
        InheritanceGifts: "Inheritance",
        YieldInvestments: "Profit from investments",
        ShareDividend: "Dividends on shares or similar income from business activities",
        Compensation: "Other type of compensation (e.g. from insurance, severance pay or similar)",
        Gambling: "Winnings from gambling/lotteries",
        TheOtherDebtorWillRepayLoan: "The second borrower repays the loan",
        EducationalSupport: "Educational support",
        Other: "Other",
      },
      purposeOfLoan: {
        TravelOrLeisure: "Travel or leisure",
        RefinanceRepayOtherLoansCredit: "Pay off other loans or credits",
        RenovationOfHouseCabinOrOtherProperty: "Renovation",
        HealthExpensesDentistEtc: "Health-related expenditure",
        MoveablesVehicleOrRelatedExpenses: "Vehicles",
        EducationOrProfessionalTraining: "Education",
        WeddingOrFamilyOccasion: "Weddings or family occasions",
        ToCoverForPeriodOfUnemployment: "To cover a period of unemployment",
        RelocationFurnishingSecurityDeposit: "Cost of moving or furnishing",
        Other: "Other",
      },
      housingTypeLabel: "Housing type",
      housingType: {
        None: "",
        Condominium: "Condominium",
        OwnedHouse: "Living in own house/property",
        Tenancy: "Tenancy via a first contract",
        SecondHandApartment: "Tenancy via a secondary contract",
        CollectiveHousing: "Living in a collective (shared accommodation)",
      },
      unsuccessfulTopUpHeader: "Something went wrong",
      finish: "Finish",
      choosePayoutAccountHeader: "Choose payout account",
      pepLabel: "Politically exposed person (PEP)",
      isPersonallyApplyingLabel: "Applying for another party",
      isPersonallyApplyingCheckboxText: "I confirm that I am not applying for a loan on behalf of another person.",
      isPersonallyApplyingErrorText:
        "We cannot proceed with your application if you are applying on behalf of someone other than yourself.",
      approveCreditCheckLabel:
        "I approve that Santander performs a credit check (UC) so that I can receive my personal interest rate.",
      tooManyCitizenshipsError: "You can enter a maximum of 5 citizenships.",
      processingHeader: "Thank you!",
      processingText: "We are currently processing the application. Please wait.",
    },
    pendingApplications: {
      bannerHeader: "You have an unfinished loan application.",
      bannerContent: "<p>To resume your loan application click on the button below</p>",
      disbursementBannerHeader: "Your increase is ready!",
      disbursementBannerContent: "<p>The money will be in your bank account within 1-3 business days.</p>",
      bannerButtonText: "Resume loan application",
      resumeTopUpText: "You have an initiated loan increase on this account. Click ",
      resumeTopUpLinkText: "here to resume",
    },
    placeholders: {
      chooseAccount: "Choose account",
      year: "YYYY",
      month: "MM",
    },
    error: {
      required: "The field is required.",
      incorrectEmail: "Something went wrong. Make sure you entered your email address correctly.",
      conflictingEmail: "Please enter a different e-mail address.",
      tooShortPhone: "Mobile numbers must be at least 8 digits.",
      tooLongPhone: "Mobile numbers may be a maximum of 13 digits.",
      conflictingPhone: "Please enter a different phone number.",
    },
    letters: {
      yearlyStatement: "Yearly statement",
    },
    retryPage: {
      title: "Oh.",
      text: "Something went wrong. Try reloading the page.",
      reloadLink: "Reload",
    },
    langPicker: {
      label: "Pick language",
      sv: "svenska",
      en: "English",
    },
  },
};
