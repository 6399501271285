import { IOffersResponse, OfferCategory } from "../../../models/Offer/Offer";

export const mockOffers: IOffersResponse = {
  offers: [
    {
      offerId: "1",
      accountNumber: "23151256593",
      offerType: "string",
      offerCategory: OfferCategory.AMC,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 1,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 0,
        costCalculatedOn: 0,
        ppiCostCalculatedOnText: "string",
      },
      offerAmount: 0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
    },
    {
      offerId: "2",
      accountNumber: "23151256593",
      offerType: "string",
      offerCategory: OfferCategory.PPIC,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 1,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 19.5,
        costCalculatedOn: 20000,
        ppiCostCalculatedOnText: "This is a test string",
      },
      offerAmount: 0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
      isInsuranceOffer: true,
    },
    {
      offerId: "3",
      accountNumber: "23151256593",
      offerType: "string",
      offerCategory: OfferCategory.LINC,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 0,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 0,
        costCalculatedOn: 0,
        ppiCostCalculatedOnText: "string",
      },
      offerAmount: 5000.0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
    },
    {
      offerId: "4",
      accountNumber: "23151256593",
      offerType: "string",
      offerCategory: OfferCategory.PFMC,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 0,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 0,
        costCalculatedOn: 0,
        ppiCostCalculatedOnText: "string",
      },
      offerAmount: 5000.0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
      isPFMOffer: true,
    },
    {
      offerId: "5",
      accountNumber: "628302",
      offerType: "string",
      offerCategory: OfferCategory.PPIL,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 1,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 19.5,
        costCalculatedOn: 20000,
        ppiCostCalculatedOnText: "This is a test string",
      },
      offerAmount: 0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
      isInsuranceOffer: true,
    },
    {
      offerId: "6",
      accountNumber: "4257557",
      offerType: "string",
      offerCategory: OfferCategory.AOL,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 1,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 19.5,
        costCalculatedOn: 20000,
        ppiCostCalculatedOnText: "This is a test string",
      },
      offerAmount: 10000,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
    },
    {
      offerId: "7",
      accountNumber: "628302",
      offerType: "string",
      offerCategory: OfferCategory.PFML,
      validFrom: "2018-02-02T10:27:33.622Z",
      validTo: "2030-03-02T10:27:33.622Z",
      priority: 0,
      cardNumber: "1234123412341234",
      insurance: {
        schemaNumber: "string",
        cost: 0,
        costCalculatedOn: 0,
        ppiCostCalculatedOnText: "string",
      },
      offerAmount: 5000.0,
      campaignId: "1",
      ansokCampaignId: "1",
      ansokStoreId: "1",
      latestResponseCode: "string",
      salesText: "string",
      description: "string",
      categoryText: "string",
      latestResponseText: "string",
      inVisible: true,
      isPFMOffer: true,
    },
    {
      offerId: "900756052",
      accountNumber: "4257557",
      offerCategory: OfferCategory.CIR,
      validFrom: "2023-01-11T11:06:11Z",
      validTo: "2023-01-13T11:06:11Z",
      priority: 155,
      offerAmount: 0,
      inVisible: false,
      isInsuranceOffer: false,
      isPFMOffer: false,
      isAddCardOffer: false,
      isActivateCardOffer: false,
    },
  ],
};
