import { ILoanAccount } from "libs/models/CustomerProducts";
import { AccountStatus, AccountType, IBaseAccount } from "../../../../models/CustomerProducts/BaseAccount";
import dayjs from "dayjs";
import { PaymentHolidayConsecutivePeriodConditionType } from "../../../../models/Content/Enums";

export const mockBaseSecuredLoanAccountOne: IBaseAccount = {
  name: "Billån 1",
  nickname: "My Personal Billån 1",
  accountId: "123355",
  accountStatus: AccountStatus.AccountMisManaged,
  accountType: AccountType.SecuredLoan,
  isDisabled: false,
  displayNumber: "123355",
  availableBalance: 1000,
  hasOverdueInvoice: true,
};

export const mockBaseSecuredLoanAccountTwo: IBaseAccount = {
  name: "Billån 2",
  nickname: undefined,
  accountId: "4334434",
  accountStatus: AccountStatus.AccountTerminated,
  accountType: AccountType.SecuredLoan,
  isDisabled: false,
  displayNumber: "4334434",
  availableBalance: -5555,
};

export const mockSecuredLoanAccountOne: ILoanAccount = {
  ...mockBaseSecuredLoanAccountOne,
  name: "Billån 1",
  mainDebtor: "Test Testsson 2",
  creditLimit: 125000,
  bookedBalance: -10000,
  accountInterest: 8.66,
  contractMileage: 45000,
  hasPendingApplication: false,
  canRequestMileageChange: true,
  canReduceInstallments: true,
  canIncreaseInstallments: true,
  canExtendResidualAmountPayment: true,
  canAddInstallment: true,
  canRequestInsurance: true,
  canRequestPaymentHoliday: true,
  paymentHolidayDetails: {
    elligibleFromDate: dayjs().toString(),
    elligibleToDate: dayjs().add(6, "months").toString(),
    isRecurringPaymentHoliday: false,
    allowedPaymentHolidayMonths: 2,
    allowedPaymentHolidayMonthsPerYear: 2,
    consecutivePeriodCondition: PaymentHolidayConsecutivePeriodConditionType.ConsecutiveMonthsAllowed,
    currentPaymentHolidayMonths: [dayjs().add(3, "months").toString()],
    canUpdateCurrentPaymentHolidayMonths: false,
  },
  fees: {
    mileageChangeFee: 5,
    reduceInstallmentsFee: 50,
    extendResidualAmountPaymentFee: 5,
    paymentHolidayFee: 5,
  },
};

export const mockSecuredLoanAccountTwo: ILoanAccount = {
  ...mockBaseSecuredLoanAccountTwo,
  name: "Billån 2",
  mainDebtor: "Test Testsson 2",
  creditLimit: 125000,
  bookedBalance: -10000,
  accountInterest: 8.66,
  insurances: [{ type: "Santander Lainaturva", canTerminate: true }],
  hasPendingApplication: false,
  canRequestInsurance: false,
};
