import { observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import closingOfDepositAccountsStyles from "./ClosingOfDepositAccounts.scss";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { AccountRedemptionStatus, Colors, DepositTypeName } from "netbank-shared/src/libs/models/Content/Enums";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { IClosingOfDepositAccountPage } from "netbank-shared/src/libs/models/Content/Page";
import { ICustomer } from "netbank-shared/src/libs/models/Customer/Customer";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { IToAccountFields, ITransferAccountFields } from "netbank-shared/src/libs/models/Transfer/Transfer";
import { formatDate, toLocaleString } from "netbank-shared/src/libs/utils";
import rootStyles from "~views/pages/Root.scss";
import { Button, CheckboxConfirmation, InfoText } from "~views/shared";
import { SavedAccountForm } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/SavedAccountForm";
import { TransferAccountDropdown } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/TransferAccountDropdown";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { ISummaryRow, Summary } from "~views/shared/Summary/Summary";

interface IClosingOfDepositAccountsForm {
  accountId: string;
}

export const ClosingOfDepositAccountsForm = observer(({ accountId }: IClosingOfDepositAccountsForm) => {
  const [canceling, setCanceling] = useState(false);
  const [createToAccount, setCreateToAccount] = useState(false);
  const [noToAccount, setNoToAccount] = useState(false);

  const { transferStore, contentStore, uiStore, depositStore, customerStore } = useStores();
  const page = contentStore.currentPage as IClosingOfDepositAccountPage;
  const currentCustomer = customerStore.currentCustomer as ICustomer | null;
  const { toAccount, setToAccount } = transferStore;
  const navigate = useNavigate();

  const hasNationalPayoutAccount = currentCustomer?.capabilities.hasNationalPayoutAccount;
  const { accountRedemptionStatus, accountRedemptionStatusCode } = transferStore;

  const dataLayer = useTracking((window as any).dataLayer);

  const createSavedAccount = async () => {
    const created = await transferStore.createSavedAccount(true);
    if (created) {
      setCreateToAccount(false);
      setNoToAccount(false);
    }
  };

  const goBackToAccount = () => {
    navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}/${accountId}`);
  };

  const cancelAction = async () => {
    setCanceling(true);
    if (accountRedemptionStatusCode === 201) {
      await transferStore.updateEngagements();

      const currentAccountExists =
        depositStore.depositCustomerProducts?.findIndex((acc) => depositStore.currentAccountId === acc.accountId) > -1;

      if (!currentAccountExists) {
        transferStore.resetAccountRedemption();
        if (depositStore.depositCustomerProducts && depositStore.depositCustomerProducts.length > 1) {
          navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}`);
        } else {
          navigate(`/${tx("routing.lang")}`);
        }
      }
    } else {
      transferStore.resetAccountRedemption();
      navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}/${accountId}`);
    }
    setCanceling(false);
  };

  const applyAction = async () => {
    if (hasNationalPayoutAccount) {
      setToAccount({
        value: tx("withdrawal.withdrawal"),
        accountId: tx("withdrawal.nationalPayoutAccount"),
        isNationalPayoutAccount: true,
      });
      await transferStore.createAccountRedemption();
      return;
    }

    if (!transferStore.toAccount) {
      setNoToAccount(true);
      return;
    }
    await transferStore.createAccountRedemption();
  };

  const hasFixedInterestRate = (depositTypeName?: string) => {
    return (
      depositTypeName === DepositTypeName.Opsparing6 ||
      depositTypeName === DepositTypeName.Opsparing12 ||
      depositTypeName === DepositTypeName.Opsparing24
    );
  };

  const { currency, locale } = uiStore;

  const accountOpenDate = depositStore.currentAccount?.openDate;
  const redemptionData = transferStore.accountRedemptionData;

  let summaryRows: ISummaryRow[] = [
    {
      cols: [
        {
          label: `${page.accountNameLabel || tx("misc.accountName")}:`,
          value: redemptionData?.accountName || "",
          key: uuidv4(),
        },
        {
          label: `${page.accountNumberLabel || tx("misc.accountNumber")}:`,
          value: redemptionData?.accountNumber || "",
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
    {
      cols: [
        {
          label: `${page?.closingDateLabel || tx("deposit.closingDate")}: `,
          value: formatDate(redemptionData?.closeDate) || "",
          key: uuidv4(),
        },
        {
          label: `${page?.openDateLabel || tx("deposit.openDate")}: `,
          value: formatDate(accountOpenDate) || "",
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
    {
      cols: [
        {
          label: `${page.interestCarryingBalanceLabel || tx("deposit.interestCarryingBalance")}: `,
          value: toLocaleString(redemptionData?.interestCarryingBalance || 0, currency, locale),
          key: uuidv4(),
        },
        {
          label: `${page?.accruedInterestLabel || tx("deposit.accruedInterest")}:`,
          value: toLocaleString(redemptionData?.interest || 0, currency, locale),
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
    {
      cols: [
        {
          label: `${page.balanceLabel || tx("deposit.availableBalance")}:`,
          value: toLocaleString(redemptionData?.availableBalance || 0, currency, locale),
          key: uuidv4(),
        },
        {
          label: `${page?.totalTransferAmountLabel || tx("deposit.totalTransferAmount")}:`,
          value: toLocaleString(redemptionData?.totalPayment || 0, currency, locale),
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
  ];

  if (hasFixedInterestRate(redemptionData?.accountName)) {
    const fixedInterestRateInfo: ISummaryRow = {
      cols: [
        {
          label: `${page.expirationDateLabel || tx("deposit.expirationDate")}:`,
          value: formatDate(redemptionData?.openDate) || "",
          key: uuidv4(),
        },
        {
          label: `${page.terminationFeeLabel || tx("deposit.terminationFee")}:`,
          value: toLocaleString(redemptionData?.penaltyInterest || 0, currency, locale),
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    };
    summaryRows = summaryRows.concat(fixedInterestRateInfo);
  }

  if (accountRedemptionStatus === AccountRedemptionStatus.RedemptionSuccessful && toAccount) {
    dataLayer.pushInteraction(TrackingCategory.ProductDeposit, TrackingAction.CloseAccountOK);
    return <Success page={page} summaryRows={summaryRows} toAccount={toAccount} goBackToAccount={goBackToAccount} />;
  }

  if (accountRedemptionStatus === AccountRedemptionStatus.RedemptionFailed) {
    dataLayer.pushInteraction(TrackingCategory.ProductDeposit, TrackingAction.CloseAccountKO);
    return (
      <ErrorMessage
        statusCode={accountRedemptionStatusCode}
        page={page}
        cancelAction={cancelAction}
        canceling={canceling}
      />
    );
  }

  if (accountRedemptionStatus === AccountRedemptionStatus.NoRedemptionInfo) {
    return (
      <ErrorMessage
        statusCode={accountRedemptionStatusCode}
        page={page}
        cancelAction={cancelAction}
        canceling={canceling}
      />
    );
  }

  return (
    <div>
      {!hasNationalPayoutAccount && (
        <div className={closingOfDepositAccountsStyles.transferAccountDropdown}>
          <TransferAccountDropdown
            infoPopover={{
              content: page?.transferAccountInformation,
              popoverTitle: page?.chooseTransferAccountLabel || tx("deposit.chooseTransferAccount"),
            }}
            label={page?.chooseTransferAccountLabel || tx("deposit.chooseTransferAccount")}
            value={
              toAccount
                ? `${toAccount.displayName} | ${toAccount.clearingNumber}-${toAccount.accountNumber}`
                : page?.chooseReceiverOrCreateLabel || tx("deposit.chooseReceiverOrCreate")
            }
            onChange={(value: ListItem) => {
              setNoToAccount(false);
              setToAccount({ value: value.label });
            }}
            createToAccount={createToAccount}
            setCreateToAccount={(value: boolean) => {
              if (value) {
                setToAccount({});
              }
              setCreateToAccount(value);
            }}
            error={(noToAccount && !createToAccount && tx("transfer.noToAccountError")) || undefined}
          />
          {createToAccount && (
            <SavedAccountForm
              handleSavedAccount={createSavedAccount}
              account={transferStore.newAccount}
              setAccount={(account: ITransferAccountFields) => {
                setNoToAccount(false);
                transferStore.setNewAccount(account);
              }}
              loading={transferStore.creatingSavedAccount}
              clearingNumberLabel={page?.clearingNumberLabel || tx("transfer.clearingNumber")}
              accountNumberLabel={page?.accountNumberLabel || tx("transfer.accountNumber")}
              clearingNumberInformation={page?.clearingNumberInformation}
              clearingStartsWithEightThenFiveDigitsError={page?.clearingStartsWithEightThenFiveDigitsError}
              accountNumberInformation={page?.accountNumberInformation}
              saveLabel={page?.saveAndUseLabel}
              error={
                (transferStore.duplicateSavedAccountError &&
                  contentStore.currentPage?.duplicateSavedAccountError &&
                  tx("transfer.duplicateAccount")) ||
                (noToAccount &&
                  createToAccount &&
                  transferStore.newAccount.clearingNumber &&
                  transferStore.newAccount.accountNumber &&
                  transferStore.newAccount.displayName &&
                  tx("transfer.saveToAccountError")) ||
                undefined
              }
            />
          )}
        </div>
      )}
      <div className={closingOfDepositAccountsStyles.accountInformationSummary}>
        <Summary
          label={page?.accountInformationLabel || tx("deposit.accountInformation")}
          rows={summaryRows}
          color={Colors.LightSuccess}
        />
      </div>
      {hasNationalPayoutAccount && (
        <InfoText
          text={`${page.nationalPayoutAccountInformation || tx("deposit.nationalPayoutAccountInformation")}`}
          level={NotificationLevel.information}
        />
      )}
      <CheckboxConfirmation
        applyAction={applyAction}
        cancelAction={cancelAction}
        canceling={canceling}
        confirmationButtonLabel={page?.closeAccountConfirmationButtonLabel || tx("deposit.closeAccountConfirmation")}
        confirmationWarningText={page?.closeAccountWarningText || tx("deposit.closeAccountWarningText")}
        loading={transferStore.loadingAccountRedemption}
        trackingCategory={TrackingCategory.ProductDeposit}
        trackingAction={TrackingAction.CloseAccountCTA}
      />
    </div>
  );
});

const Success = ({
  page,
  summaryRows,
  toAccount,
  goBackToAccount,
}: {
  page: IClosingOfDepositAccountPage;
  summaryRows: ISummaryRow[];
  toAccount: IToAccountFields;
  goBackToAccount: () => void;
}) => {
  const toAccountText = toAccount.isNationalPayoutAccount
    ? `${toAccount.accountNumber}`
    : `${toAccount.clearingNumber}-${toAccount.accountNumber}`;
  return (
    <div>
      {page?.closingAccountSuccessTopContent && (
        <HtmlContent
          className={closingOfDepositAccountsStyles.topContent}
          html={page.closingAccountSuccessTopContent}
        />
      )}
      {toAccount && (
        <div>
          <span className={rootStyles.label}>{page?.receiverSuccessLabel || tx("deposit.receiverSuccessLabel")}</span>

          <div className={closingOfDepositAccountsStyles.receiverSuccess}>
            <p>
              {`${tx("deposit.receiverAccount")}:`}
              <span>{toAccountText}</span>
            </p>
          </div>
        </div>
      )}
      <div className={closingOfDepositAccountsStyles.accountInformationSummary}>
        <Summary
          label={page?.accountInformationLabel || tx("deposit.accountInformation")}
          rows={summaryRows}
          color={Colors.LightSuccess}
        />
      </div>
      <div className={closingOfDepositAccountsStyles.buttonContainer}>
        <Button title={tx("misc.close")} onClick={goBackToAccount} color="white" borderColor="black" bordered large />
      </div>
    </div>
  );
};

const ErrorMessage = ({
  statusCode,
  page,
  cancelAction,
  canceling,
}: {
  statusCode?: number;
  page: IClosingOfDepositAccountPage;
  cancelAction: () => Promise<void>;
  canceling: boolean;
}) => {
  const accountRedemptionStatus = page?.accountRedemptionStatuses?.find(
    (status) => statusCode && status.statusCode === statusCode.toString(),
  );

  return (
    <div>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        {accountRedemptionStatus ? (
          <InfoText text={accountRedemptionStatus?.text} level={NotificationLevel.error} />
        ) : (
          <InfoText
            header={tx("deposit.accountRedemptionErrorHeader")}
            text={tx("deposit.accountRedemptionErrorText")}
            level={NotificationLevel.error}
          />
        )}
      </div>
      <div className={closingOfDepositAccountsStyles.buttonContainer}>
        <Button
          title={tx("misc.close")}
          onClick={cancelAction}
          loading={canceling}
          color="white"
          borderColor="black"
          large
          centered
        />
      </div>
    </div>
  );
};
