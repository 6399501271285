/* eslint-disable no-restricted-properties */

import { ILoanCalculation } from "libs/models/Offer/Offer";

const getPMT = (rate: number, months: number, principal: number): number => {
  if (rate == null || months == null || principal == null) {
    return 0;
  }
  const rateOfInterest = rate / 100 / 12;
  const monthlyCost =
    (principal * rateOfInterest * Math.pow(1 + rateOfInterest, months)) / (Math.pow(1 + rateOfInterest, months) - 1);

  return Math.round(monthlyCost);
};

const getRate = (
  periods: number,
  payment: number,
  present: number,
  future: number = 0,
  type: number = 0,
  guess: number = 0.1
) => {
  const epsMax = 0.00004539992;

  const iterMax = 20;

  let y = 0;
  let y0 = 0;
  let y1 = 0;
  let x0 = 0;
  let x1 = 0;
  let f = 0;
  let i = 0;
  let rate = guess;
  if (Math.abs(rate) < epsMax) {
    y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = 0;
  x0 = 0;
  x1 = rate;
  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    i += 1;
  }
  return rate;
};

const getNetMonthlyCost = (
  months: number,
  purchaseAmount: number,
  startUpFees: number,
  billingFees: number,
  interest: number
) => {
  const principal = (purchaseAmount + startUpFees + billingFees) * (1 + interest / 100 / 12);

  const netMonthlyCost = getPMT(interest, months, principal);
  return netMonthlyCost != null ? netMonthlyCost : 0;
};

export const getMonthlyCost = (
  interest: number,
  months: number,
  purchaseAmount: number,
  startUpFees: number,
  billingFees: number,
  minPayment: number
) => {
  const netMonthlyCost = getNetMonthlyCost(months, purchaseAmount, startUpFees, billingFees, interest);
  let monthlyCost = 0;
  if (netMonthlyCost === 0) {
    monthlyCost = netMonthlyCost;
  } else if (netMonthlyCost >= minPayment) {
    monthlyCost = netMonthlyCost + billingFees;
  } else {
    monthlyCost = minPayment + billingFees;
  }
  return Math.round(monthlyCost);
};

export const getTotalCost = (
  interest: number,
  months: number,
  purchaseAmount: number,
  startUpFees: number,
  billingFees: number,
  minPayment: number
) => {
  const netMonthlyCost = getMonthlyCost(interest, months, purchaseAmount, startUpFees, billingFees, minPayment);
  const monthlyCost = netMonthlyCost + billingFees;
  return Math.round(monthlyCost * months);
};

export const getEffectiveInterest = (interestRate: number, months: number, loanAmount: number, adminFees: number) => {
  const pmt = getPMT(interestRate, months, loanAmount);
  if (pmt != null) {
    const principalAmount = loanAmount * -1 + adminFees;
    const rate = getRate(months, pmt, principalAmount);
    const exactRate = (1 + rate) ** 12 - 1;
    const effectiveInterestRate = exactRate * 100;
    return effectiveInterestRate;
  }
  return 0;
};

export const getLoanCalculationData = (
  interest: number,
  years: number,
  purchaseAmount: number,
  startUpFees = 0,
  billingFees = 0,
  minPayment = 0
) => {
  if (!interest || !years || !purchaseAmount) return undefined;
  const months = years * 12;

  const calculationData: ILoanCalculation = {
    effectiveInterest: getEffectiveInterest(interest, months, purchaseAmount, startUpFees),
    totalCost: getTotalCost(interest, months, purchaseAmount, startUpFees, billingFees, minPayment),
    monthlyCost: getMonthlyCost(interest, months, purchaseAmount, startUpFees, billingFees, minPayment),
  };

  return calculationData;
};
