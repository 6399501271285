import {
  IBaseAccount,
  IConsentReply,
  IDocument,
  ILoanAccount,
  ILoanAccountSubmitTermination,
  ILoanAccountTerminationDetails,
  IPayment,
  IGetPaymentsRequest,
  PaymentType,
  IGetInvoicesRequest,
  InvoiceStatus,
  ILoanBaseAccount,
  InvoiceUpdateStatus,
  InvoiceDistributionFormatType,
  LoanTerminationDebtor,
} from "../libs/models/CustomerProducts";
import { action, computed, makeObservable, observable } from "mobx";
import { AccountType, PromiseToPayStatusRequest, SelfServiceSubmissionState } from "../libs/models/Content/Enums";
import { Store } from "./Store";
import { LoanApi } from "libs/api";
import { formatDate } from "../libs/utils";
import dayjs, { Dayjs } from "dayjs";
import { IUpdateDueDayFormInfo } from "libs/models/UpdateDueDay";
import { IPowerOfAttorneyTerms } from "libs/models/PowerOfAttorney";
import { IPromiseToPayData } from "libs/models/PromiseToPay";
import { IAttachment } from "libs/models/Attachment/Attachment";
import { v4 as uuidv4 } from "uuid";
import {
  IAddInstallmentForm,
  IExtendResidualAmountPaymentForm,
  IIncreaseInstallmentsForm,
  IMileageChangeForm,
  IReduceInstallmentsForm,
} from "libs/forms/PaymentPlanChange";
import { IPowerOfAttorneyForm } from "libs/forms/PowerOfAttorneyForm";
import { IAddInsuranceForm } from "libs/forms/Insurances/AddInsuranceForm";
import { ITerminateInsuranceForm } from "libs/forms/Insurances/TerminateInsuranceForm";
import { IPaymentHolidayForm } from "libs/forms/PaymentHoliday/PaymentHolidayForm";

export class LoanStore {
  loanApi: LoanApi;

  rootStore: Store;

  constructor(loanApi: LoanApi, rootStore: Store) {
    this.loanApi = loanApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  privateLoanAccountsError?: boolean;

  @observable
  securedLoanAccountsError?: boolean;

  @observable
  salesFinanceAccountsError?: boolean;

  @observable
  blockedLoanAccounts: ILoanAccount[] = [];

  @observable
  blockedPrivateLoanAccounts: ILoanAccount[] = [];

  @observable
  blockedSecuredLoanAccounts: ILoanAccount[] = [];

  @observable
  blockedSalesFinanceAccounts: ILoanAccount[] = [];

  @observable
  securedLoanAccounts: ILoanAccount[] = [];

  @observable
  privateLoanAccounts: ILoanAccount[] = [];

  @observable
  salesFinanceAccounts: ILoanAccount[] = [];

  @observable
  privateLoanCustomerProducts: ILoanBaseAccount[] = [];

  @observable
  securedLoanCustomerProducts: ILoanBaseAccount[] = [];

  @observable
  salesFinanceCustomerProducts: ILoanBaseAccount[] = [];

  @computed
  get allLoanAccounts(): ILoanAccount[] {
    return [
      ...this.privateLoanAccounts,
      ...this.securedLoanAccounts,
      ...this.salesFinanceAccounts,
      ...this.blockedLoanAccounts,
    ];
  }

  @computed
  get loanCustomerProducts(): IBaseAccount[] {
    return [
      ...this.privateLoanCustomerProducts,
      ...this.securedLoanCustomerProducts,
      ...this.salesFinanceCustomerProducts,
    ];
  }

  @computed
  get allPrivateLoanAccounts(): ILoanAccount[] {
    return [
      ...this.privateLoanAccounts,
      ...this.blockedLoanAccounts.filter((a) => a.accountType === AccountType.PrivateLoan),
    ];
  }

  @computed
  get allSecuredLoanAccounts(): ILoanAccount[] {
    return [
      ...this.securedLoanAccounts,
      ...this.blockedLoanAccounts.filter((a) => a.accountType === AccountType.SecuredLoan),
    ];
  }

  @computed
  get allSalesFinanceAccounts(): ILoanAccount[] {
    return [
      ...this.salesFinanceAccounts,
      ...this.blockedLoanAccounts.filter((a) => a.accountType === AccountType.SalesFinance),
    ];
  }

  @computed
  get hasCustomerProducts(): boolean {
    return this.loanCustomerProducts !== undefined && this.loanCustomerProducts.length > 0;
  }

  @computed
  get hasPrivateLoanCustomerProducts(): boolean {
    return this.privateLoanCustomerProducts !== undefined && this.privateLoanCustomerProducts.length > 0;
  }

  @computed
  get hasSecuredLoanCustomerProducts(): boolean {
    return this.securedLoanCustomerProducts !== undefined && this.securedLoanCustomerProducts.length > 0;
  }

  @computed
  get hasSalesFinanceCustomerProducts(): boolean {
    return this.salesFinanceCustomerProducts !== undefined && this.salesFinanceCustomerProducts.length > 0;
  }

  @observable
  terminationDate?: Date;

  @observable
  paymentReference?: string;

  @observable
  paymentAccount?: string;

  @observable
  terminationAmount?: number;

  @observable
  fetchingTerminationAmount: boolean = false;

  @observable
  terminationAmountError: boolean = false;

  @observable
  paymentPlan: IPayment[] | undefined = undefined;

  @observable
  loadingPaymentPlan: boolean = false;

  @observable
  loadingPaymentPlanError: boolean = false;

  @observable
  overduePayments: IPayment[] | undefined = undefined;

  @observable
  loadingOverduePayments: boolean = false;

  @observable
  loadingOverduePaymentsError: boolean = false;

  @observable
  duePayment: IPayment | null | undefined = undefined;

  @observable
  loadingDuePayment: boolean = false;

  @observable
  loadingDuePaymentError: boolean = false;

  @observable
  paidPayments: IPayment[] | undefined = undefined;

  @observable
  loadingPaidPayments: boolean = false;

  @observable
  loadingPaidPaymentsError: boolean = false;

  @observable
  archivedInvoices: IPayment[] | undefined = undefined;

  @observable
  loadingArchivedInvoices: boolean = false;

  @observable
  loadingArchivedInvoicesError: boolean = false;

  @observable
  fetchingCurrentDueDay: boolean = false;

  @observable
  fetchingCurrentDueDayError: boolean = false;

  @observable
  currentDueDay: number | undefined;

  @observable
  canUpdateDueDay: boolean = false;

  @observable
  updatingAccountDueDay: boolean = false;

  @observable
  updatingAccountDueDayError: boolean | undefined = undefined;

  @observable
  activePayment: IPayment | undefined = undefined;

  @observable
  contracts?: IDocument[];

  @observable
  loadingContracts: boolean = false;

  @observable
  powerOfAttorneyTerms: IPowerOfAttorneyTerms | undefined = undefined;

  @observable
  fetchingPowerOfAttorneyTerms: boolean = false;

  @observable
  fetchingPowerOfAttorneyTermsError: boolean | undefined = undefined;

  @observable
  creatingPowerOfAttorney: boolean = false;

  @observable
  creatingPowerOfAttorneyError: boolean | undefined = undefined;

  @observable
  newInvoiceMethod: InvoiceDistributionFormatType | undefined;

  @observable
  updatingInvoiceMethod: boolean = false;

  @observable
  invoiceUpdateStatus: InvoiceUpdateStatus = InvoiceUpdateStatus.Initial;

  @computed
  get templateData() {
    return {
      terminationAmountDate: formatDate(this.terminationDate),
      loanTerminationsDate: this.loanTerminationDetails?.validToDate
        ? formatDate(this.loanTerminationDetails.validToDate)
        : undefined,
    };
  }

  @computed
  get currentAccountId(): string {
    return this.rootStore.currentAccountId;
  }

  set currentAccountId(accountId: string) {
    this.rootStore.currentAccountId = accountId;
  }

  @observable
  loadingAccount: boolean = false;

  @observable
  loanAccountDocuments?: IDocument[];

  @observable
  loadingDocuments: boolean = false;

  @observable
  currentAccount: ILoanAccount | undefined;

  @observable
  loanTerminationDetails?: ILoanAccountTerminationDetails = undefined;

  @observable
  loanTerminationsCalculationDetails?: ILoanAccountSubmitTermination = undefined;

  @observable
  loanTerminationsCalcDate?: Dayjs;

  @observable
  loanTerminationDebtor?: LoanTerminationDebtor;

  @observable
  promiseToPayDateCalcDate?: Dayjs;

  @observable promiseToPayMessageBody: string = "";

  @observable promiseToPayAttachments: IAttachment[] = [];

  @observable promiseToPayPhoneNumber: string = "";

  @observable promiseToPayDateError: boolean = false;

  @observable promiseToPayMessageError: boolean = false;

  @observable creatingPromiseToPay: boolean = false;

  @observable promiseToPayStatus: PromiseToPayStatusRequest = PromiseToPayStatusRequest.Initial;

  @observable cantPayOnSelectableDates: boolean = false;

  @observable invalidFileSize: boolean = false;

  @observable invalidAttachments: boolean = false;

  @observable
  hasConsentTerminationDateError: boolean = false;

  @observable
  hasSelectedDebtorError: boolean = false;

  @observable
  consentReplies: IConsentReply[] = [];

  @observable
  loanTerminationsErrorList: string[] = [];

  @observable
  loanTerminationsPhoneError: boolean = false;

  @observable
  selfServiceSusbmissionState = SelfServiceSubmissionState.NotSubmitted;

  @observable
  fetchingInvoiceDocument: boolean = false;

  @computed
  get hasAccounts(): boolean {
    return this.allLoanAccounts !== undefined && this.allLoanAccounts.length > 0;
  }

  @computed
  get hasPrivateLoanAccounts(): boolean {
    return this.allPrivateLoanAccounts !== undefined && this.allPrivateLoanAccounts.length > 0;
  }

  @computed
  get hasSecuredLoanAccounts(): boolean {
    return this.allSecuredLoanAccounts !== undefined && this.allSecuredLoanAccounts.length > 0;
  }

  @computed
  get hasSalesFinanceLoanAccounts(): boolean {
    return this.salesFinanceAccounts !== undefined && this.allSalesFinanceAccounts.length > 0;
  }

  setCurrentAccount(accountId: string): void {
    this.currentAccountId = accountId;
  }

  setActivePayment(payment: IPayment): void {
    this.activePayment = payment;
  }

  getLoanAccount = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingAccount = true;
    const response = await this.loanApi.getLoanAccount(accountId);
    if (response?.ok && response.data) {
      let currentBaseAccount = this.rootStore.currentAccount;
      if (currentBaseAccount?.accountId !== accountId) {
        currentBaseAccount = this.rootStore.allCustomerProducts?.find(
          (customerProduct) => customerProduct.accountId === response.data?.loanAccount?.accountId,
        )!;
        this.currentAccountId = accountId;
      }
      this.currentAccount = response.data.loanAccount
        ? {
            ...response.data.loanAccount,
            nickname: currentBaseAccount?.nickname,
            accountType: currentBaseAccount?.accountType,
            isAmlFrozen: currentBaseAccount?.isAmlFrozen,
          }
        : undefined;
    }
    this.loadingAccount = false;
  };

  @action
  setNicknameOptimistically = async (nickname: string) => {
    if (this.currentAccount) {
      this.currentAccount.nickname = nickname;
    }
  };

  getPaymentPlan = async (accountId: string) => {
    this.loadingPaymentPlan = true;
    this.loadingPaymentPlanError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.All };
    const response = await this.loanApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.paymentPlan = response.data.payments;
    } else {
      this.loadingPaymentPlanError = true;
    }
    this.loadingPaymentPlan = false;
  };

  getOverduePayments = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingOverduePayments = true;
    this.loadingOverduePaymentsError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Overdue };
    const response = await this.loanApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.overduePayments = response.data.payments;
    } else if (response?.status === 404) {
      this.overduePayments = [];
    } else {
      this.loadingOverduePaymentsError = true;
    }
    this.loadingOverduePayments = false;
  };

  getDuePayment = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingDuePayment = true;
    this.loadingDuePaymentError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Due };
    const response = await this.loanApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.duePayment = response.data.payments[0];
    } else if (response?.status === 404) {
      this.duePayment = null;
    } else {
      this.loadingDuePaymentError = true;
    }
    this.loadingDuePayment = false;
  };

  getPaidPayments = async (accountId: string) => {
    this.loadingPaidPayments = true;
    this.loadingPaidPaymentsError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Paid };
    const response = await this.loanApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.paidPayments = response.data.payments;
    } else if (response?.status === 404) {
      this.paidPayments = [];
    } else {
      this.loadingPaidPaymentsError = true;
    }
    this.loadingPaidPayments = false;
  };

  getArchivedInvoices = async (accountId: string) => {
    this.loadingArchivedInvoices = true;
    this.loadingArchivedInvoicesError = false;
    const invoicesData: IGetInvoicesRequest = { accountId, invoiceStatus: InvoiceStatus.Paid };
    const response = await this.loanApi.getInvoices(invoicesData);
    if (response?.ok && response.data) {
      this.archivedInvoices = response.data.invoices;
    } else if (response?.status === 404) {
      this.paidPayments = [];
    } else {
      this.loadingArchivedInvoicesError = true;
    }
    this.loadingArchivedInvoices = false;
  };

  getLoanTerminationPaymentInformation = async (accountId: string) => {
    this.fetchingTerminationAmount = true;
    const response = await this.loanApi.getLoanAccountTerminationDetails(accountId);
    if (response?.ok && response.data) {
      this.loanTerminationDetails = response.data;
    }
    this.fetchingTerminationAmount = false;
  };

  getLoanAccountTerminationDetails = async (accountId: string, download?: boolean) => {
    this.loanTerminationsPhoneError = false;

    const response = await this.loanApi.getLoanAccountTerminationDetails(
      accountId,
      this.loanTerminationsCalcDate?.toISOString(),
      this.loanTerminationDebtor,
      download,
    );
    if (response?.ok && response.data) {
      this.loanTerminationDetails = response.data;
      this.createConsentReplies();
      return true;
    }
    return false;
  };

  submitLoanAccountTerminationDetails = async (accountId: string) => {
    let submitError = false;
    this.loanTerminationsErrorList = [];

    const phoneValidator = /^[\d]{8}$/;

    this.consentReplies.forEach((consentReply) => {
      const currentQuestion = this.loanTerminationDetails?.consentQuestions.find(
        (consentQuestion) => consentQuestion.questionId === consentReply.questionId,
      );
      const shouldHavePhoneNumber = currentQuestion?.answerOptions.find(
        (answerOption) => answerOption.answerId === consentReply.answerId,
      )?.collectContactPhoneNumber;

      if (!consentReply.answerId) {
        this.loanTerminationsErrorList.push(consentReply.questionId);
        submitError = true;
      }
      if (
        shouldHavePhoneNumber &&
        (!consentReply.contactPhoneNumber || !phoneValidator.test(consentReply.contactPhoneNumber))
      ) {
        this.loanTerminationsPhoneError = true;
        submitError = true;
      }
    });

    if (submitError) {
      return false;
    }

    const response = await this.loanApi.submitLoanAccountTermination(
      accountId,
      this.loanTerminationsCalcDate?.toISOString(),
      this.consentReplies,
    );
    if (response?.ok && response.data) {
      this.loanTerminationsCalculationDetails = response.data;
      return true;
    }
    return false;
  };

  createConsentReplies = () => {
    this.loanTerminationDetails?.consentQuestions?.forEach((consentQuestion) => {
      this.consentReplies.push({
        questionId: consentQuestion.questionId,
        answerId: "",
        contactPhoneNumber: "",
      });
    });
  };

  updateLoanTerminationsCalcDate = (date: Date) => {
    if (date) this.loanTerminationsCalcDate = dayjs(date).endOf("day");
  };

  updateLoanTerminationDebtor = (debtor: LoanTerminationDebtor) => {
    this.loanTerminationDebtor = debtor;
  };

  updatePromiseToPayCalcDate = (date?: Date) => {
    this.promiseToPayDateError = false;

    if (date) {
      this.promiseToPayDateCalcDate = dayjs(date).endOf("day");
    } else {
      this.promiseToPayDateCalcDate = undefined;
    }
  };

  setConsentTerminationDateError = (error: boolean) => {
    this.hasConsentTerminationDateError = error;
  };

  setSelectedDebtorError = (error: boolean) => {
    this.hasSelectedDebtorError = error;
  };

  updateConsentReplies = (questionId: string, answerId: string, contactPhoneNumber: string) => {
    const index = this.consentReplies.findIndex((i) => i.questionId === questionId);
    if (index === -1) {
      return;
    }

    this.loanTerminationsErrorList = this.loanTerminationsErrorList.filter((errorId) => errorId !== questionId);

    if (contactPhoneNumber) {
      this.loanTerminationsPhoneError = false;
    }

    this.consentReplies[index] = { questionId, answerId, contactPhoneNumber };
  };

  getCurrentDueDay = async (accountId: string | null | undefined) => {
    if (!accountId) return;
    this.fetchingCurrentDueDay = true;
    const response = await this.loanApi.getCurrentDueDay(accountId);
    this.fetchingCurrentDueDay = false;
    if (response?.ok && response.data) {
      this.fetchingCurrentDueDayError = false;
      this.currentDueDay = response.data.currentDueDay;
      this.canUpdateDueDay = response.data.canUpdateDueDay;
      return;
    }
    this.fetchingCurrentDueDayError = true;
  };

  updateDueDay = async (accountId: string, data: IUpdateDueDayFormInfo) => {
    this.updatingAccountDueDay = true;
    const response = await this.loanApi.updateDueDay(accountId, data);
    if (response?.ok) {
      this.updatingAccountDueDayError = false;
      return;
    }
    this.updatingAccountDueDayError = true;
  };

  getContracts = async () => {
    this.contracts = [];
    this.loadingContracts = true;
    const response = await this.loanApi.getContracts(this.currentAccountId);
    if (response?.ok && response.data) {
      this.contracts = response.data;
    }
    this.loadingContracts = false;
  };

  getPowerOfAttorneyTerms = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.fetchingPowerOfAttorneyTerms = true;
    const response = await this.loanApi.getPowerOfAttorneyTerms(accountId);
    this.fetchingPowerOfAttorneyTerms = false;
    if (response?.ok) {
      this.powerOfAttorneyTerms = response.data;
      return;
    }
    this.fetchingPowerOfAttorneyTermsError = true;
  };

  createPowerOfAttorney = async (accountId: string, data: IPowerOfAttorneyForm) => {
    this.creatingPowerOfAttorney = true;
    const response = await this.loanApi.createPowerOfAttorney(accountId, data);
    this.creatingPowerOfAttorney = false;
    if (response?.ok) {
      this.creatingPowerOfAttorneyError = false;
      return;
    }
    this.creatingPowerOfAttorneyError = true;
  };

  submitPromiseToPayRequest = async () => {
    this.promiseToPayDateError = false;
    this.promiseToPayMessageError = false;

    const accountId = this.currentAccount?.accountId;
    const invoiceInfo = this.currentAccount?.canPromiseToPayInvoiceInformation;

    if (!this.cantPayOnSelectableDates && !this.promiseToPayDateCalcDate) {
      this.promiseToPayDateError = true;
      return;
    }

    if (this.cantPayOnSelectableDates && this.promiseToPayMessageBody === "") {
      this.promiseToPayMessageError = true;
      return;
    }

    if (!accountId || !invoiceInfo?.invoiceNumber || !invoiceInfo.invoiceAmount) {
      this.promiseToPayStatus = PromiseToPayStatusRequest.Failed;
      return;
    }

    const data: IPromiseToPayData = {
      cantPayOnSelectableDates: this.cantPayOnSelectableDates,
      phoneNumber: this.promiseToPayPhoneNumber,
      message: this.promiseToPayMessageBody,
      dueDate: this.currentAccount?.canPromiseToPayInvoiceInformation?.dueDate,
      attachments: this.promiseToPayAttachments.map((a) => a.file).filter((f) => !!f) as File[],
      promiseToPayServiceData: {
        promisedPayDate: this.promiseToPayDateCalcDate,
        invoiceAmount: invoiceInfo.invoiceAmount,
      },
    };

    this.creatingPromiseToPay = true;
    const response = await this.loanApi.promiseToPayInvoice(accountId, invoiceInfo.invoiceNumber, data);
    if (response?.ok) {
      this.creatingPromiseToPay = false;
      this.promiseToPayStatus = PromiseToPayStatusRequest.Success;
    } else {
      this.creatingPromiseToPay = false;
      this.promiseToPayStatus = PromiseToPayStatusRequest.Failed;
    }
  };

  changeInvoiceMethod = async () => {
    if (!this.newInvoiceMethod || !this.currentAccountId || !this.currentAccount) return;
    this.updatingInvoiceMethod = true;
    const response = await this.loanApi.changeInvoiceMethod(this.currentAccountId, this.newInvoiceMethod);
    if (response?.ok) {
      this.currentAccount.invoiceDistributionFormat = this.newInvoiceMethod;
      this.invoiceUpdateStatus = InvoiceUpdateStatus.Success;
    } else {
      this.invoiceUpdateStatus = InvoiceUpdateStatus.Failed;
    }
    this.updatingInvoiceMethod = false;
  };

  getInvoiceDocument = async (invoiceId: string) => {
    if (!invoiceId) return;
    const { uiStore } = this.rootStore;
    this.fetchingInvoiceDocument = true;
    const response = await this.loanApi.getInvoiceDocument(this.currentAccountId, invoiceId);
    if (response?.ok && response.data) {
      if (response.data) {
        this.rootStore.commonService.downloadDocument(
          response.data,
          `${invoiceId}.pdf`,
          undefined,
          true,
          !!uiStore.modal || !!uiStore.modalAccordionView,
        );
      }
    }
    this.fetchingInvoiceDocument = false;
  };

  @action
  setCantPayOnSelectableDates = (value: boolean) => {
    this.cantPayOnSelectableDates = value;
  };

  @action
  setNewMessageBody = (value: string) => {
    this.promiseToPayMessageError = false;
    this.promiseToPayMessageBody = value;
  };

  @action
  setPromsieToPayPhoneNumber = (value: string) => {
    this.promiseToPayPhoneNumber = value;
  };

  @action
  addAttachments = (files: File[]) => {
    this.invalidFileSize = false;
    this.invalidAttachments = false;
    files.forEach((file) => {
      const attachment: IAttachment = {
        id: uuidv4(), // identifier while attachments only exist in state (have not yet been uploaded through the APIs)
        fileName: file.name,
        file,
      };
      this.promiseToPayAttachments.push(attachment);
    });
  };

  @action
  removeAttachment = (id: string) => {
    this.invalidAttachments = false;
    this.invalidFileSize = false;
    this.promiseToPayAttachments = this.promiseToPayAttachments.filter((attachment) => attachment.id !== id);
  };

  @action
  setUpdatedInvoiceMethod = (newInvoiceMethod: InvoiceDistributionFormatType) => {
    this.newInvoiceMethod = newInvoiceMethod;
  };

  @action
  resetChangeInvoiceMethod = () => {
    this.newInvoiceMethod = undefined;
    this.invoiceUpdateStatus = InvoiceUpdateStatus.Initial;
    this.updatingInvoiceMethod = false;
  };

  @action
  requestMileageChange = async (data: IMileageChangeForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canRequestMileageChange) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.requestMileageChange(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  reduceInstallments = async (data: IReduceInstallmentsForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canReduceInstallments) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.reduceInstallments(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  extendResidualAmountPayment = async (data: IExtendResidualAmountPaymentForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canExtendResidualAmountPayment) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.extendResidualAmountPayment(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  increaseInstallments = async (data: IIncreaseInstallmentsForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canIncreaseInstallments) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.increaseInstallments(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  addInstallment = async (data: IAddInstallmentForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canAddInstallment) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.addInstallment(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  addInsurance = async (data: IAddInsuranceForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canRequestInsurance) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.addInsurance(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  terminateInsurance = async (data: ITerminateInsuranceForm) => {
    if (
      !this.currentAccount?.accountId ||
      !this.currentAccount.insurances ||
      this.currentAccount.insurances.findIndex((insurance) => !!insurance.canTerminate) === -1
    ) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.loanApi.terminateInsurance(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  filterLockedPaymentHolidayMonths = (months: Date[]) => {
    if (!this.currentAccount?.paymentHolidayDetails) return [];
    const { currentPaymentHolidayMonths, canUpdateCurrentPaymentHolidayMonths } =
      this.currentAccount.paymentHolidayDetails;
    if (currentPaymentHolidayMonths && !canUpdateCurrentPaymentHolidayMonths) {
      // Filter out all current payment free months if update is not allowed
      return months.filter(
        (m) =>
          currentPaymentHolidayMonths
            .map((pfm) => new Date(pfm))
            .findIndex((pfm) => pfm.getMonth() === m.getMonth() && pfm.getFullYear() === m.getFullYear()) === -1,
      );
    }

    return months;
  };

  getPaymentHolidayFee = (months: Date[] | undefined) => {
    if (!this.currentAccount?.paymentHolidayDetails || !this.currentAccount.fees?.paymentHolidayFee) {
      return 0;
    }

    let requestedMonths = months;
    if (requestedMonths) {
      requestedMonths = this.filterLockedPaymentHolidayMonths(requestedMonths);
    }

    return (
      this.currentAccount.fees.paymentHolidayFee *
      (requestedMonths && requestedMonths?.length > 1 ? requestedMonths.length : 1)
    );
  };

  @action
  addPaymentHoliday = async (formData: IPaymentHolidayForm) => {
    if (
      !this.currentAccount?.accountId ||
      !this.currentAccount.canRequestPaymentHoliday ||
      !this.currentAccount.paymentHolidayDetails
    ) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const data = formData;

    data.months = this.filterLockedPaymentHolidayMonths(data.months);

    const response = await this.loanApi.addPaymentHoliday(this.currentAccount.accountId, data);
    this.selfServiceSusbmissionState = response?.ok
      ? SelfServiceSubmissionState.Success
      : SelfServiceSubmissionState.Error;
  };

  @action
  resetLoanAccountDocuments = () => {
    this.loanAccountDocuments = undefined;
  };

  @action
  resetTerminationAmountState = () => {
    this.terminationAmount = undefined;
    this.terminationAmountError = false;
    this.fetchingTerminationAmount = false;
    this.terminationDate = undefined;
    this.paymentReference = undefined;
    this.paymentAccount = undefined;
  };

  @action
  resetLoanTerminationsState = () => {
    this.consentReplies = [];
    this.loanTerminationDetails = undefined;
    this.loanTerminationsCalculationDetails = undefined;
    this.loanTerminationsCalcDate = undefined;
    this.loanTerminationDebtor = undefined;
    this.loanTerminationsErrorList = [];
    this.loanTerminationsPhoneError = false;
    this.hasConsentTerminationDateError = false;
    this.hasSelectedDebtorError = false;
  };

  @action
  resetLoanPayments = () => {
    this.activePayment = undefined;
    this.paymentPlan = undefined;
    this.loadingPaymentPlan = false;
    this.loadingPaymentPlanError = false;
    this.archivedInvoices = undefined;
    this.loadingArchivedInvoices = false;
    this.loadingArchivedInvoicesError = false;
    this.duePayment = undefined;
    this.loadingDuePayment = false;
    this.loadingDuePaymentError = false;
    this.overduePayments = undefined;
    this.loadingOverduePayments = false;
    this.loadingOverduePaymentsError = false;
    this.paidPayments = undefined;
    this.loadingPaidPayments = false;
    this.loadingPaidPaymentsError = false;
  };

  @action
  resetContracts = () => {
    this.contracts = undefined;
    this.loadingContracts = false;
  };

  @action
  resetUpdateDueDay = () => {
    this.fetchingCurrentDueDay = false;
    this.currentDueDay = undefined;
    this.fetchingCurrentDueDayError = false;
    this.updatingAccountDueDayError = undefined;
    this.updatingAccountDueDay = false;
    this.canUpdateDueDay = false;
  };

  @action
  resetPowerOfAttorneyState = () => {
    this.powerOfAttorneyTerms = undefined;
    this.fetchingPowerOfAttorneyTerms = false;
    this.fetchingPowerOfAttorneyTermsError = undefined;
    this.creatingPowerOfAttorney = false;
    this.creatingPowerOfAttorneyError = undefined;
  };

  @action
  resetPromiseToPayStates = () => {
    this.promiseToPayDateCalcDate = undefined;
    this.promiseToPayMessageBody = "";
    this.promiseToPayAttachments = [];
    this.promiseToPayPhoneNumber = "";
    this.promiseToPayDateError = false;
    this.creatingPromiseToPay = false;
    this.cantPayOnSelectableDates = false;
    this.promiseToPayStatus = PromiseToPayStatusRequest.Initial;
  };

  @action
  resetCurrentAccount = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
  };

  @action
  resetSelfServiceState = () => {
    this.selfServiceSusbmissionState = SelfServiceSubmissionState.NotSubmitted;
  };

  @action
  resetStore = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
    this.securedLoanAccounts = [];
    this.privateLoanAccounts = [];
    this.salesFinanceAccounts = [];
    this.privateLoanCustomerProducts = [];
    this.loadingAccount = false;
    this.securedLoanCustomerProducts = [];
    this.salesFinanceCustomerProducts = [];
    this.blockedLoanAccounts = [];
    this.blockedPrivateLoanAccounts = [];
    this.blockedSecuredLoanAccounts = [];
    this.resetTerminationAmountState();
    this.resetLoanTerminationsState();
    this.resetLoanPayments();
    this.resetUpdateDueDay();
    this.resetPowerOfAttorneyState();
    this.resetPromiseToPayStates();
    this.resetChangeInvoiceMethod();
    this.resetSelfServiceState();
  };
}
