import dayjs from "dayjs";
import { PaymentHolidayConsecutivePeriodConditionType } from "netbank-shared/src/libs/models/Content/Enums";
import { IPaymentHolidayDetails } from "netbank-shared/src/libs/models/CustomerProducts";
import React, { RefObject } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IInfoPopoverProps } from "~views/shared/InfoPopover/InfoPopover";
import { LabelRow } from "~views/shared/LabelRow/LabelRow";
import { MonthPicker } from "~views/shared/MonthPicker/MonthPicker";

interface IPaymentHolidayPickerProps {
  name: string;
  label: string;
  details: IPaymentHolidayDetails;
  infoPopover?: IInfoPopoverProps;
}

export const PaymentHolidayPicker = ({ label, name, details, infoPopover }: IPaymentHolidayPickerProps) => {
  const ref: RefObject<HTMLDivElement> = React.useRef(null);
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  if (!details) return null;

  const initialValue = details.currentPaymentHolidayMonths?.map((m) => new Date(m));
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div ref={ref}>
          <LabelRow label={label} infoPopover={infoPopover} />
          <MonthPicker
            {...field}
            value={field.value || initialValue}
            multiple
            showYearPicker={!details.isRecurringPaymentHoliday}
            maxAllowedMonths={details.allowedPaymentHolidayMonths}
            maxAllowedMonthsPerYear={details.allowedPaymentHolidayMonthsPerYear}
            allowConsecutive={
              details.consecutivePeriodCondition ===
              PaymentHolidayConsecutivePeriodConditionType.ConsecutiveMonthsAllowed
            }
            minDate={dayjs(details.elligibleFromDate).isValid() ? new Date(details.elligibleFromDate) : undefined}
            maxDate={dayjs(details.elligibleToDate).isValid() ? new Date(details.elligibleToDate) : undefined}
            initialValue={initialValue}
            canUpdateInitialValue={details.canUpdateCurrentPaymentHolidayMonths}
            disabled={isSubmitting}
            error={error}
          />
        </div>
      )}
    />
  );
};
