import { IMultiSelectOption } from "libs/models/MultiSelect/MultiSelectOption";
import { tx } from "../i18n";
import { InsuranceCompany } from "../models/Content/Enums";
import { IPowerOfAttorneyTerms } from "libs/models/PowerOfAttorney";
import { IPowerOfAttorneyPage } from "libs/models/Content/Page";
import * as yup from "yup";

export interface IPowerOfAttorneyForm {
  travelDate: Date;
  insuranceCompany: string;
  travellingToInvalidCountry?: "yes" | "no";
  acceptTermsAndExpense: boolean;
  countries?: IMultiSelectOption[];
  attachments?: File[];
}

interface IPowerOfAttorneySchemaProps {
  terms: IPowerOfAttorneyTerms;
  page: IPowerOfAttorneyPage;
  requiredError?: string;
  invalidError?: string;
}

export const generatePowerOfAttorneySchema = (props: IPowerOfAttorneySchemaProps) => {
  const {
    travelDateInput,
    insuranceCompanyInput,
    travellingToInvalidCountryInput,
    acceptTermsInput,
    countriesInput,
    insuranceDocumentInput,
  } = props.page;

  const invalidDateError = travelDateInput?.error || tx("validators.required");
  const invalidInsuranceCompanyError = insuranceCompanyInput?.error || tx("validators.required");
  const invalidTravellingToInvalidCountryError = travellingToInvalidCountryInput?.error || tx("validators.required");
  const invalidAcceptTermsAndExpenseError = acceptTermsInput?.error || tx("validators.required");
  const invalidCountriesError = countriesInput?.error || tx("validators.required");
  const invalidAttachmentsError = insuranceDocumentInput?.error || tx("validators.required");

  const { insuranceCompanyTerms } = props.terms;

  const mainSchema = yup.object({
    travelDate: yup.date().required(invalidDateError),
    insuranceCompany: yup
      .string()
      .oneOf(Object.values(InsuranceCompany), invalidInsuranceCompanyError)
      .required(invalidInsuranceCompanyError),
    travellingToInvalidCountry: yup
      .mixed()
      .oneOf(["yes", "no"], invalidTravellingToInvalidCountryError)
      .when(["insuranceCompany"], {
        is: (insuranceCompany: InsuranceCompany) => {
          const term = insuranceCompanyTerms?.find((t) => t.insuranceCompany === insuranceCompany);
          const hasInvalidCountries = term?.invalidCountries && term.invalidCountries.length > 0;
          return hasInvalidCountries;
        },
        then: (schema) => schema.required(invalidTravellingToInvalidCountryError),
      }),
    acceptTermsAndExpense: yup
      .boolean()
      .required(invalidAcceptTermsAndExpenseError)
      .oneOf([true], invalidAcceptTermsAndExpenseError),
    countries: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }),
      )
      .when(["travellingToInvalidCountry"], {
        is: "yes",
        then: (schema) => schema.min(1, invalidCountriesError),
      }),
    attachments: yup.array().when("travellingToInvalidCountry", {
      is: "yes",
      then: (schema) => schema.min(1, invalidAttachmentsError),
    }),
  });

  return mainSchema;
};
