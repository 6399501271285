import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { IConfig } from "../models/Config";
import { Environment } from "../models/Content/Enums";
import { Store } from "stores";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance, CancelToken } from "axios";
import { IConversationResponse, IConversations } from "../models/Message/Conversations";
import { IDownloadMessageAttachmentResponse } from "../models/Attachment/Attachment";
import { INewConversationRequest, INewMessage, IReplyRequest, IReplyResponse } from "../models/Message/NewMessage";
import { MockMessageApi } from "./mock/MockMessageApi";

export class MessageApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.MESSAGE_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getConversations = async (cancelToken: CancelToken) => {
    return this.client?.get<IConversations>(
      "/conversations",
      { v: "1.0" },
      {
        headers: this.getHeaders().headers,
        cancelToken,
      },
    );
  };

  getConversation = async (conversationId: string) =>
    this.client?.get<IConversationResponse>(`/conversations/${conversationId}`, { v: "1.0" }, this.getHeaders());

  createConversation = async (params: INewConversationRequest) => {
    const conversationFormData = new FormData();

    conversationFormData.append("accountId", params.accountNumber);
    conversationFormData.append("body", params.body);
    conversationFormData.append("subject", params.subject);
    conversationFormData.append("accountType", params.accountType);
    params.files?.forEach((f) => {
      conversationFormData.append("attachments", f);
    });
    if (params.area) conversationFormData.append("area", params.area); // Only SE

    return this.client?.post<INewMessage>(
      `/conversations`,
      conversationFormData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  markConversationAsRead = async (conversationId: string) =>
    this.client?.put(
      `/conversations/${conversationId}`,
      {
        v: "1.0",
        action: "setToRead",
      },
      this.getHeaders(),
    );

  archiveConversation = async (conversationId: string) =>
    this.client?.put(
      `/conversations/${conversationId}`,
      {
        v: "1.0",
        action: "archive",
      },
      this.getHeaders(),
    );

  createReplyMessage = async (params: IReplyRequest) => {
    const messageFormData = new FormData();

    messageFormData.append("v", "1.0");
    messageFormData.append("body", params.body);

    return this.client?.post<IReplyResponse>(
      `conversations/${params.conversationId}/messages`,
      messageFormData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  downloadAttachment = async (attachmentId: string) => {
    return this.client?.get<IDownloadMessageAttachmentResponse>(
      `attachments/${attachmentId}`,
      { v: 1.0 },
      this.getHeaders(),
    );
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockMessageApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
