import React from "react";
import { observer } from "mobx-react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IIncreaseInstallmentsPage } from "netbank-shared/src/libs/models/Content/Page";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IIncreaseInstallmentsForm,
  generateIncreaseInstallmentsSchema,
  getIncreaseInstallmentTypes,
} from "netbank-shared/src/libs/forms/PaymentPlanChange";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { DropdownFormField, FormWrapper, InputFormField } from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";

export const IncreaseInstallmentsPage = observer(() => {
  const navigate = useNavigate();
  const { contentStore, loanStore, uiStore } = useStores();
  const { currentPage } = contentStore;
  const page = currentPage as IIncreaseInstallmentsPage;

  const {
    amount,
    increaseInstallmentType,
    increaseInstallmentTypes,
    phone,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
  } = page;
  const { currentAccount, selfServiceSusbmissionState } = loanStore;

  const allowBalloonPayment =
    currentAccount && !!currentAccount.balloonAmount && !Number.isNaN(currentAccount.balloonAmount);

  const methods = useForm<IIncreaseInstallmentsForm>({
    resolver: yupResolver(
      generateIncreaseInstallmentsSchema({
        locale: uiStore.locale!,
        accountType: currentAccount?.accountType,
        page,
        allowBalloonPayment,
      }),
    ),
  });

  useFetchData({
    cleanup: () => loanStore.resetSelfServiceState(),
  });

  if (!currentAccount?.canIncreaseInstallments) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const increaseInstallmentTypeOptions = getIncreaseInstallmentTypes(increaseInstallmentTypes, allowBalloonPayment);

  const onSubmit: SubmitHandler<IIncreaseInstallmentsForm> = async (data) =>
    loanStore.increaseInstallments({
      ...data,
      increaseInstallmentType: increaseInstallmentTypeOptions.find(
        (option) => option.value === data.increaseInstallmentType,
      )?.enumValue,
    });

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <InputFormField
              type="number"
              label={amount?.label || tx("increaseInstallments.amountLabel")}
              infoPopover={{ popoverTitle: amount?.infoTitle, content: amount?.infoText }}
              name="amount"
              placeholder={amount?.placeholder}
              suffix={uiStore.currencySymbol}
            />
            <DropdownFormField
              label={increaseInstallmentType?.label || tx("increaseInstallments.typeLabel")}
              infoPopover={{
                popoverTitle: increaseInstallmentType?.infoTitle,
                content: increaseInstallmentType?.infoText,
              }}
              name="increaseInstallmentType"
              list={increaseInstallmentTypeOptions}
            />
            <InputFormField
              type="tel"
              label={phone?.label || tx("selfServiceLabels.phoneLabel")}
              infoPopover={{ popoverTitle: phone?.infoTitle, content: phone?.infoText }}
              name="phone"
              placeholder={phone?.placeholder}
              allowedCharacters={/[^0-9+]/g}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
