import React from "react";
import { observer } from "mobx-react";
import styles from "./InfoText.scss";
import rootStyles from "~views/pages/Root.scss";
import informationIcon from "~assets/information.svg";
import importantIcon from "~assets/important.svg";
import errorIcon from "~assets/error.svg";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { Button } from "..";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { HtmlContentQueryLinkBlock } from "../ContentArea/Blocks/HtmlContentQueryLinkBlock";
import { IHtmlContentQueryLinkBlock } from "netbank-shared/src/libs/models/Content/IHtmlContentQueryLinkBlock";

interface IInfoTextProps {
  text?: string;
  header?: string;
  className?: string;
  level?: NotificationLevel;
  buttonText?: string;
  showIcon?: boolean;
  accountNumber?: string;
  accountHasOverdueInvoiceText?: IHtmlContentQueryLinkBlock;
  buttonAction?: () => void;
}

export const InfoText = observer(
  ({
    header,
    text,
    className,
    level,
    buttonText,
    buttonAction,
    showIcon = true,
    accountNumber,
    accountHasOverdueInvoiceText,
  }: IInfoTextProps) => {
    const classes = [styles.wrapper];

    let icon = informationIcon;

    if (className) {
      classes.push(className);
    }

    if (level) {
      classes.push(styles.hasBackground);
      switch (level) {
        case NotificationLevel.information:
          classes.push(styles.information);
          break;

        case NotificationLevel.important:
          classes.push(styles.important);
          icon = importantIcon;
          break;

        case NotificationLevel.error:
          classes.push(styles.error);
          icon = errorIcon;
          break;

        case NotificationLevel.success:
          classes.push(styles.success);
          break;

        default:
          break;
      }
    }

    return (
      <div className={classes.join(" ")}>
        {showIcon && <img src={icon} alt={`${level}-icon`} />}
        <div>
          {header && <p className={rootStyles.bold}>{header}</p>}
          {text && <HtmlContent className={styles.container} html={text} />}
          {!!accountHasOverdueInvoiceText && (
            <HtmlContentQueryLinkBlock data={accountHasOverdueInvoiceText} accountNumber={accountNumber} />
          )}
          {buttonText && buttonAction && (
            <Button className={styles.button} title={buttonText} color="red" large onClick={buttonAction} />
          )}
        </div>
      </div>
    );
  },
);
