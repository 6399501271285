import { SelfServiceSubmissionState } from "../models/Content/Enums";
import { useStores } from "../../hooks";
import { AccountType, ILeaseAccount, ILoanAccount } from "../models/CustomerProducts";
import { ITerminateInsuranceForm } from "libs/forms/Insurances/TerminateInsuranceForm";

interface ITerminateInsuranceParams {
  terminateInsurance: (data: ITerminateInsuranceForm) => void;
  resetSelfServiceState: () => void;
  currentAccount: ILeaseAccount | ILoanAccount | undefined;
  selfServiceSusbmissionState: SelfServiceSubmissionState;
}

export const useDetermineTerminateInsuranceParamsByAccountType = (
  accountType: AccountType | undefined,
): ITerminateInsuranceParams => {
  const { loanStore, leasingStore } = useStores();
  const emptyResponse: ITerminateInsuranceParams = {
    terminateInsurance: () => {},
    resetSelfServiceState: () => {},
    currentAccount: undefined,
    selfServiceSusbmissionState: SelfServiceSubmissionState.NotSubmitted,
  };
  if (!accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        terminateInsurance: (data: ITerminateInsuranceForm) => {
          loanStore.terminateInsurance(data);
        },
        resetSelfServiceState: () => loanStore.resetSelfServiceState(),
        currentAccount: loanStore.currentAccount,
        selfServiceSusbmissionState: loanStore.selfServiceSusbmissionState,
      };
    case AccountType.Leasing:
    case AccountType.PrivateLeasing:
      return {
        terminateInsurance: (data: ITerminateInsuranceForm) => {
          leasingStore.terminateInsurance(data);
        },
        resetSelfServiceState: () => leasingStore.resetSelfServiceState(),
        currentAccount: leasingStore.currentAccount,
        selfServiceSusbmissionState: leasingStore.selfServiceSusbmissionState,
      };
    default:
      return emptyResponse;
  }
};
