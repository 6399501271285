import React from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { Button } from "~views/shared";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import {
  ApplicantLivingSituation,
  IApplicantLivingSituationLabelsProps,
} from "~views/shared/Applicant/ApplicantLivingSituation";
import { usePushInteraction } from "~hooks";

export interface LoanTopUpApplicantLivingSituationStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpApplicantLivingSituationStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpApplicantLivingSituationStepProps) => {
    const { offerStore, uiStore } = useStores();

    const { locale, currency } = uiStore;

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise2LivingSituation,
      eventPrefix: toLocaleString(offerStore.totalLoanAmount, currency, locale, 0),
      eventValue: offerStore.totalLoanAmount,
    });

    const { applicantDetailsStep, sharedContent } = data;

    if (!offerStore.currentAccount) return null;

    const { hasCoApplicant, coApplicantName } = offerStore.currentAccount;

    const applicantLabels = applicantDetailsStep.applicantEmploymentStatusBlock;

    const livingSituationLabels: IApplicantLivingSituationLabelsProps = {
      numberOfChildrenLabel: applicantLabels.numberOfChildrenLabel,
      numberOfChildrenInformation: applicantLabels.numberOfChildrenInformation,
      housingTypeLabel: sharedContent.housingTypeLabel,
      housingCostLabel: sharedContent.housingCostLabel,
      housingCostInformation: sharedContent.housingCostInformation,
      sharedAccomodationLabel: sharedContent.sharedAccomodationLabel,
    };

    return (
      <div>
        <HtmlContent html={`<h1>${applicantDetailsStep.mainApplicantHeader || tx("topUp.mainApplicantHeader")}</h1>`} />
        <ApplicantLivingSituation
          data={livingSituationLabels}
          applicant={offerStore.mainTopupApplicantData}
          setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setMainApplicantData(applicant)}
          showValidationErrors={offerStore.showTopUpValidationErrors}
          isMainApplicant
        />

        {hasCoApplicant && coApplicantName && (
          <>
            <h2>{applicantDetailsStep.coApplicantHeader || tx("topUp.coApplicantHeader")}</h2>
            <p className={rootStyles.bold}>{coApplicantName}</p>
            <ApplicantLivingSituation
              data={livingSituationLabels}
              applicant={offerStore.coTopupApplicantData}
              setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setCoApplicantData(applicant)}
              showValidationErrors={offerStore.showTopUpValidationErrors}
            />
          </>
        )}

        <div>
          <div className={offerStyles.submit}>
            <Button
              title={applicantDetailsStep.previousButtonText || tx("misc.back")}
              onClick={setPreviousTopUpStep}
              borderColor="black"
              bordered
              large
            />
            <Button
              title={applicantDetailsStep.continueButtonText || tx("misc.continue")}
              color="red"
              onClick={() => {
                const isValid = offerStore.validateApplicantsLivingSituation();

                if (isValid) {
                  setNextTopUpStep();
                }
              }}
              large
            />
          </div>
        </div>
      </div>
    );
  }
);
