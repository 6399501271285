import MockAdapter from "axios-mock-adapter";
import {
  mockDuePayment,
  mockLoanAccountTerminationsDetails,
  mockPaidPayments,
  mockSubmitLoanAccountTerminations,
  mockLoanAccounts,
  mockPaymentPlan,
  mockOverduePayments,
  mockCurrentDueDay,
  mockPowerOfAttorneyResponse,
  mockGetPowerOfAttorneyTerms,
} from "./loan";
import { IPayment, InvoiceStatus, PaymentType } from "../../models/CustomerProducts";

export const MockLoanApi = (mock: MockAdapter) => {
  const idRegexSegment = "[0-9]+";
  const singleAccountIdRegexSegment = "^[0-9]+$";

  const getAccountFromUrlAccountId = (url: string | undefined, index: number = 0) => {
    if (!url) return undefined;
    const urlArray = url.split("/");
    const accountId = urlArray[index];
    return mockLoanAccounts.loanAccounts?.find((a) => a.accountId === accountId);
  };

  mock.onGet("").reply(200, mockLoanAccounts);

  mock.onGet(new RegExp(`${singleAccountIdRegexSegment}`)).reply((config) => {
    if (config.url) {
      const loanAccount = getAccountFromUrlAccountId(config.url);

      if (loanAccount) {
        return [200, { loanAccount, error: "" }];
      }
    }
    return [500];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/payments`)).reply((config) => {
    const paymentType = config.params.paymentType as PaymentType;
    if (config.url) {
      const loanAccount = getAccountFromUrlAccountId(config.url, 1);
      if (loanAccount) {
        let data: IPayment[] | IPayment | null;
        switch (paymentType) {
          case PaymentType.All:
            data = mockPaymentPlan.payments;
            break;
          case PaymentType.Overdue:
            data = mockOverduePayments.payments;
            break;
          case PaymentType.Due:
            data = mockDuePayment.payments;
            break;
          case PaymentType.Paid:
            data = mockPaidPayments.payments;
            break;
          default:
            data = null;
        }

        return [200, { payments: data, error: "" }];
      }
    }
    return [404];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/invoices`)).reply((config) => {
    const status = config.params.invoiceStatus as InvoiceStatus;
    if (config.url) {
      const loanAccount = getAccountFromUrlAccountId(config.url, 1);
      if (loanAccount) {
        let data: IPayment[] | IPayment | null;
        switch (status) {
          case InvoiceStatus.Paid:
            data = mockPaidPayments.payments;
            break;
          default:
            data = null;
        }
        return [200, { invoices: data, error: "" }];
      }
    }
    return [404];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/terminations`)).reply(200, mockLoanAccountTerminationsDetails);

  mock.onPost(new RegExp(`${idRegexSegment}/terminations`)).reply(201, mockSubmitLoanAccountTerminations);

  mock.onPut(new RegExp(`${idRegexSegment}/due-days`)).reply(204);

  mock.onGet(new RegExp(`${idRegexSegment}/due-days`)).reply(200, mockCurrentDueDay);

  mock.onGet(new RegExp(`${idRegexSegment}/power-of-attorneys`)).reply(200, mockGetPowerOfAttorneyTerms);

  mock.onPost(new RegExp(`${idRegexSegment}/power-of-attorneys`)).reply(201, mockPowerOfAttorneyResponse);

  mock.onPut(new RegExp(`${idRegexSegment}/invoices/${idRegexSegment}`)).reply(201);

  mock.onPut(new RegExp(`${idRegexSegment}/invoice-methods`)).reply(204);

  mock.onPost(new RegExp(`${idRegexSegment}/mileages`)).reply(201);

  mock.onPost(new RegExp(`${idRegexSegment}/installments`)).reply(204);

  mock.onPut(new RegExp(`${idRegexSegment}/installments`)).reply(201);

  mock.onPost(new RegExp(`${idRegexSegment}/insurances`)).reply(201);

  mock.onDelete(new RegExp(`${idRegexSegment}/insurances`)).reply(201);

  mock.onPost(new RegExp(`${idRegexSegment}/payment-holidays`)).reply(201);
};
