import { SelfServiceSubmissionState } from "../models/Content/Enums";
import { useStores } from "../../hooks";
import { AccountType, ILeaseAccount, ILoanAccount } from "../models/CustomerProducts";
import { IAddInsuranceForm } from "../forms/Insurances/AddInsuranceForm";

interface IAddInsuranceParams {
  addInsurance: (data: IAddInsuranceForm) => void;
  resetSelfServiceState: () => void;
  currentAccount: ILeaseAccount | ILoanAccount | undefined;
  selfServiceSusbmissionState: SelfServiceSubmissionState;
}

export const useDetermineAddInsuranceParamsByAccountType = (
  accountType: AccountType | undefined,
): IAddInsuranceParams => {
  const { loanStore, leasingStore } = useStores();
  const emptyResponse: IAddInsuranceParams = {
    addInsurance: () => {},
    resetSelfServiceState: () => {},
    currentAccount: undefined,
    selfServiceSusbmissionState: SelfServiceSubmissionState.NotSubmitted,
  };
  if (!accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        addInsurance: (data: IAddInsuranceForm) => {
          loanStore.addInsurance(data);
        },
        resetSelfServiceState: () => loanStore.resetSelfServiceState(),
        currentAccount: loanStore.currentAccount,
        selfServiceSusbmissionState: loanStore.selfServiceSusbmissionState,
      };
    case AccountType.Leasing:
    case AccountType.PrivateLeasing:
      return {
        addInsurance: (data: IAddInsuranceForm) => {
          leasingStore.addInsurance(data);
        },
        resetSelfServiceState: () => leasingStore.resetSelfServiceState(),
        currentAccount: leasingStore.currentAccount,
        selfServiceSusbmissionState: leasingStore.selfServiceSusbmissionState,
      };
    default:
      return emptyResponse;
  }
};
