import React, { RefObject, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { tx } from "netbank-shared/src/libs/i18n";
import close from "~assets/cancel-white.svg";
import styles from "./Modal.scss";
import { ContentArea } from "../ContentArea/ContentArea";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { Button } from "../Button/Button";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { useCloseWithEsc, usePushInteraction } from "~hooks";

interface IModalProps {
  modalScrollRef: RefObject<HTMLDivElement>;
}

export const Modal = observer(({ modalScrollRef }: IModalProps) => {
  const [removingModal, setRemovingModal] = useState(false);
  const { uiStore, transferStore, depositStore } = useStores();
  const dataLayer = useTracking((window as any).dataLayer);
  const title = uiStore.modal?.title;
  const contentRef: RefObject<HTMLDivElement> = useRef(null);

  const contentWrapperClasses = [styles.scrollWrapper];

  if (uiStore.modal?.noPadding) {
    contentWrapperClasses.push(styles.noPadding);
  }

  const navigate = useNavigate();

  usePushInteraction({
    eventCategory: TrackingCategory.Popup,
    eventAction: TrackingAction.Open,
    eventPrefix: title,
  });

  const removeModal = async () => {
    setRemovingModal(true);
    dataLayer.pushInteraction(TrackingCategory.Popup, TrackingAction.Close);

    if (transferStore.accountRedemptionStatusCode && transferStore.accountRedemptionStatusCode === 200) {
      await transferStore.updateEngagements();

      const currentAccountExsists =
        depositStore.depositCustomerProducts?.findIndex((acc) => depositStore.currentAccountId === acc.accountId) > -1;
      if (!currentAccountExsists) {
        transferStore.resetAccountRedemption();
        if (depositStore.depositCustomerProducts && depositStore.depositCustomerProducts.length > 1) {
          navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}`);
        } else {
          navigate(`/${tx("routing.lang")}`);
        }
      }
      uiStore.removeModal();
    } else {
      transferStore.resetAccountRedemption();
      uiStore.removeModal();
    }
    setRemovingModal(false);
  };

  useCloseWithEsc(() => removeModal());

  return (
    <div className={styles.overlay}>
      <FocusTrap>
        <div className={styles.wrapper} ref={contentRef} id="modal">
          <div className={styles.headerWrapper}>
            {title && <span className={styles.modalTitle}>{title}</span>}
            <Button
              title=""
              iconSuffix={close}
              onClick={removeModal}
              justifySpaceBetween
              className={styles.closeWrapper}
              loading={removingModal}
              tabIndex={0}
            />
          </div>
          <div className={contentWrapperClasses.join(" ")} ref={modalScrollRef}>
            <ContentArea modal />
          </div>
        </div>
      </FocusTrap>
    </div>
  );
});
