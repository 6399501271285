import React from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { ILinkItemCollection } from "netbank-shared/src/libs/models/Content/LinkItem";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import styles from "../LoanTopUpFlow.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { Button } from "~views/shared";
import { ApplicantEmployment, IApplicantEmploymentLabelsProps } from "~views/shared/Applicant/ApplicantEmployment";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import { UspBlock } from "~views/shared/ContentArea/Blocks/UspBlock";
import { LinkItemCollectionBlock } from "~views/shared/ContentArea/Blocks/LinkItemCollectionBlock";
import { IDivider } from "netbank-shared/src/libs/models/Content/Divider";
import { DividerBlock } from "~views/shared/ContentArea/Blocks/DividerBlock";
import { IFaq } from "netbank-shared/src/libs/models/Content/Faq";
import { FaqBlock } from "~views/shared/ContentArea/Blocks/FaqBlock";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { ILoanAccount } from "netbank-shared/src/libs/models/CustomerProducts";
import { usePushInteraction } from "~hooks";

export interface LoanTopUpApplicantEmploymentStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpApplicantEmploymentStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpApplicantEmploymentStepProps) => {
    const { offerStore, uiStore } = useStores();

    const { locale, currency } = uiStore;

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise2Employment,
      eventPrefix: toLocaleString(offerStore.totalLoanAmount, currency, locale, 0),
      eventValue: offerStore.totalLoanAmount,
    });

    const {
      applicantDetailsStep,
      loanInsuranceHeader,
      loanInsuranceText,
      loanInsuranceUspList,
      loanInsuranceUspBackgroundColor,
      loanInsuranceUspBackgroundImage,
      loanInsuranceUspBackgroundImageMobile,
      loanInsuranceFaq,
      loanInsuranceTermsText,
      sharedContent,
    } = data;

    const { isMobile } = uiStore;

    if (!offerStore.currentAccount) return null;

    const { hasCoApplicant, coApplicantName } = offerStore.currentAccount as ILoanAccount;

    const applicantLabels = applicantDetailsStep.applicantEmploymentStatusBlock;

    const loanInsuranceUspBackground = isMobile
      ? loanInsuranceUspBackgroundImage
      : loanInsuranceUspBackgroundImageMobile;

    const hasLoanInsuranceOffer = false; // Hardcoded to false until BIAN supports PPI

    const mainApplicantLabels: IApplicantEmploymentLabelsProps = {
      monthlyIncomeLabel: applicantLabels.monthlyIncomeLabel,
      monthlyIncomeInformation: applicantLabels.monthlyIncomeInformation,
      employmentStatusLabel: applicantLabels.employmentStatusLabel,
      employmentStatusInformation: applicantLabels.employmentStatusInformation,
      employerLabel: applicantLabels.employerLabel,
      employerInformation: applicantLabels.employerInformation,
      employedFromLabel: applicantLabels.employedFromLabel,
      employedFromInformation: applicantLabels.employedFromInformation,
      unemployedFromLabel: applicantLabels.unemployedFromLabel,
      unemployedFromInformation: applicantLabels.unemployedFromInformation,
      employedToLabel: applicantLabels.employedToLabel,
      employedToInformation: applicantLabels.employedToInformation,
      numberOfChildrenLabel: applicantLabels.numberOfChildrenLabel,
      numberOfChildrenInformation: applicantLabels.numberOfChildrenInformation,
      loansMonthlyCostLabel: applicantLabels.loansMonthlyCostLabel,
      loansMonthlyCostInformation: applicantLabels.loansMonthlyCostInformation,
      sourceOfFundsSalaryLabel: sharedContent.sourceOfFundsSalaryLabel,
      sourceOfFundsLabel: sharedContent.sourceOfFundsLabel,
      customSourceOfFundsPlaceholder: sharedContent.customSourceOfFundsPlaceholder,
    };

    const coApplicantLabels: IApplicantEmploymentLabelsProps = {
      monthlyIncomeLabel: applicantLabels.monthlyIncomeLabel,
      monthlyIncomeInformation: applicantLabels.coMonthlyIncomeInformation,
      employmentStatusLabel: applicantLabels.employmentStatusLabel,
      employmentStatusInformation: applicantLabels.coEmploymentStatusInformation,
      employerLabel: applicantLabels.employerLabel,
      employerInformation: applicantLabels.coEmployerInformation,
      employedFromLabel: applicantLabels.employedFromLabel,
      employedFromInformation: applicantLabels.coEmployedFromInformation,
      unemployedFromLabel: applicantLabels.unemployedFromLabel,
      unemployedFromInformation: applicantLabels.coUnemployedFromInformation,
      employedToLabel: applicantLabels.employedToLabel,
      employedToInformation: applicantLabels.coEmployedToInformation,
      numberOfChildrenLabel: applicantLabels.numberOfChildrenLabel,
      numberOfChildrenInformation: applicantLabels.coNumberOfChildrenInformation,
      loansMonthlyCostLabel: applicantLabels.loansMonthlyCostLabel,
      loansMonthlyCostInformation: applicantLabels.coLoansMonthlyCostInformation,
      sourceOfFundsSalaryLabel: sharedContent.sourceOfFundsSalaryLabel,
      sourceOfFundsLabel: sharedContent.sourceOfFundsLabel,
      customSourceOfFundsPlaceholder: sharedContent.customSourceOfFundsPlaceholder,
    };

    const renderLoanInsuranceFaqs = () => {
      if (!loanInsuranceFaq || loanInsuranceFaq.length === 0) {
        return null;
      }

      return (
        <div className={styles.loanInsuranceFaqs}>
          {loanInsuranceFaq.map((item) => {
            switch (item.contentType?.[1]) {
              case "FaqBlock":
                return <FaqBlock data={item as IGenericContentLink<IFaq>} accordion />;

              case "LinkItemCollectionBlock":
                return <LinkItemCollectionBlock data={item as IGenericContentLink<ILinkItemCollection>} accordion />;
              case "DividerBlock":
                return <DividerBlock data={item as IGenericContentLink<IDivider>} accordion />;
              default:
                return null;
            }
          })}
        </div>
      );
    };

    return (
      <div>
        <HtmlContent html={`<h1>${applicantDetailsStep.mainApplicantHeader || tx("topUp.mainApplicantHeader")}</h1>`} />
        <ApplicantEmployment
          data={mainApplicantLabels}
          applicant={offerStore.mainTopupApplicantData}
          applicantType="main-applicant"
          setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setMainApplicantData(applicant)}
          showValidationErrors={offerStore.showTopUpValidationErrors}
        />

        {hasCoApplicant && coApplicantName && (
          <>
            <h2>{applicantDetailsStep.coApplicantHeader || tx("topUp.coApplicantHeader")}</h2>
            <p className={rootStyles.bold}>{coApplicantName}</p>
            <ApplicantEmployment
              data={coApplicantLabels}
              applicant={offerStore.coTopupApplicantData}
              applicantType="co-applicant"
              setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setCoApplicantData(applicant)}
              showValidationErrors={offerStore.showTopUpValidationErrors}
            />
          </>
        )}

        {!offerStore.currentAccount.hasInsurance && hasLoanInsuranceOffer && (
          <>
            <h2>{loanInsuranceHeader || tx("topUp.loanInsuranceHeader")}</h2>
            {loanInsuranceText && <HtmlContent className={styles.topText} html={loanInsuranceText} />}
            {loanInsuranceUspList && loanInsuranceUspList.length > 0 && (
              <div className={styles.insuranceUsp}>
                <div
                  className={styles.insuranceUspList}
                  style={{
                    backgroundColor: loanInsuranceUspBackgroundColor,
                  }}
                >
                  {loanInsuranceUspBackground && (
                    <img
                      className={styles.insuranceBgImage}
                      src={loanInsuranceUspBackground.url}
                      alt={loanInsuranceUspBackground.altText}
                    />
                  )}
                  {loanInsuranceUspList.map((usp) => {
                    if (!usp.content || !usp.icon) return null;
                    return <UspBlock key={usp.contentLink.guidValue} icon={usp.icon} content={usp.content} />;
                  })}
                </div>
              </div>
            )}
            {renderLoanInsuranceFaqs()}
            <Checkbox
              label={loanInsuranceTermsText}
              checked={offerStore.topUpInsuranceAccepted}
              onChange={(e) => {
                offerStore.topUpInsuranceAccepted = e.target.checked;
              }}
            />
          </>
        )}

        <div>
          <div className={offerStyles.submit}>
            <Button
              title={applicantDetailsStep.previousButtonText || tx("misc.back")}
              onClick={setPreviousTopUpStep}
              borderColor="black"
              bordered
              large
            />
            <Button
              title={applicantDetailsStep.continueButtonText || tx("misc.continue")}
              color="red"
              onClick={() => {
                const isValid = offerStore.validateApplicantsEmployment();

                if (isValid) {
                  setNextTopUpStep();
                }
              }}
              large
            />
          </div>
        </div>
      </div>
    );
  }
);
