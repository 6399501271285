import MockAdapter from "axios-mock-adapter";
import { mockCustomerProducts } from "./customerProducts";
import { mockOffers, mockPendingApplications } from "./offers";

export const MockCustomerProductsApi = (mock: MockAdapter) => {
  const idRegexSegment = "[a-zA-Z0-9]*";

  mock.onGet("").reply(200, mockCustomerProducts);
  mock.onGet("pending-applications").reply(200, mockPendingApplications);
  mock.onGet("offers").reply(200, mockOffers);
  mock.onGet(new RegExp(`offers/${idRegexSegment}`)).reply((config) => {
    if (config.url) {
      const urlArray = config.url.split("/");
      const offerId = urlArray[urlArray.length - 1];
      const offer = mockOffers.offers?.find((a) => a.offerId === offerId);
      if (offer) {
        return [200, offer];
      }
    }
    return [500];
  });
  mock.onPost("/nicknames").reply(204);
};
