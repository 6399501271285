export const getQueryParam = (queryString: string, key: string): string => {
  const query = queryString?.substring(1);
  const queryParam = query
    ?.split("&")
    ?.map((elem) => elem.split("="))
    .find((queryPair) => {
      return decodeURIComponent(queryPair[0]) === key;
    });
  return queryParam ? decodeURIComponent(queryParam[1]) : "";
};
