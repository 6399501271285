export interface IBaseAccount {
  name: string;
  nickname?: string;
  accountId: string;
  displayNumber: string;
  isDisabled: boolean;
  accountStatus: AccountStatus;
  accountType: AccountType;
  bookedBalance?: number;
  availableBalance?: number;
  hasDirectDebit?: boolean;
  isClosed?: boolean;
  hasOverdueInvoice?: boolean;
  isAmlFrozen?: boolean;
}

export enum AccountStatus {
  AccountOk = "AccountOk",
  AccountDecline = "AccountDecline",
  AccountToClose = "AccountToClose",
  AccountFinalInvoice = "AccountFinalInvoice",
  AccountManuallyStopped = "AccountManuallyStopped",
  AccountPartialPaid = "AccountPartialPaid",
  AccountFullyPaid = "AccountFullyPaid",
  AccountFinalPaid = "AccountFinalPaid",
  AccountTerminated = "AccountTerminated",
  AccountMisManaged = "AccountMisManaged",
  Other = "Other",
}

export enum AccountType {
  Credit = "Credit",
  Deposit = "Deposit",
  Leasing = "PrivateLeasing",
  SecuredLoan = "SecuredLoan",
  PrivateLeasing = "PrivateLeasing",
  PrivateLoan = "PrivateLoan",
  SalesFinance = "SalesFinance",
}
