/* eslint-disable no-undef */
import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";
import { OffersApi } from "../libs/api";
import { tx } from "../libs/i18n";
import {
  ApplicantEmploymentType,
  SourceOfFunds,
  PurposeOfLoan,
  TopUpFlowStep,
  TopupHousingType,
  PendingApplicationState,
} from "../libs/models/Content/Enums";
import { IBaseAccount, ILoanAccount } from "../libs/models/CustomerProducts";
import {
  IAddOnApplication,
  IAddTopUpRequest,
  IAddTopUpResponse,
  ITopUpApplicantForm,
  ILoanCalculation,
  IOffer,
  IPendingApplication,
  OfferCategory,
  IApplicantDetails,
  IUpdateApplicantDetails,
  IUpdateApplicationRequest,
} from "../libs/models/Offer/Offer";
import { IToAccountFields, ITransferAccountFields } from "../libs/models/Transfer/Transfer";
import { getLoanCalculationData, toLocaleString } from "../libs/utils";
import { calculateMonthlyCost } from "../libs/utils/creditCalculations";
import { ListItem } from "../libs/models/Content/ListItem";
import { Store } from "./Store";
import { TrackingAction, TrackingCategory } from "../libs/models/Tracking";

const emptyTopupApplicantData: ITopUpApplicantForm = {
  email: { value: "", isValid: false },
  phone: { value: "", isValid: false },
  monthlyIncome: { value: 0, isValid: false },
  employmentType: { value: "", isValid: false },
  employer: { value: "", isValid: false },
  housingType: { value: "", isValid: false },
  housingCost: { value: 0, isValid: false },
  numberOfChildren: { value: 0, isValid: true },
  sourceOfFunds: {
    value: tx(`topUp.sourceOfFunds.Salary`),
    isValid: true,
  },
  sourceOfFundsIsSalary: {
    value: undefined,
    isValid: false,
  },
  customSourceOfFunds: { value: "", isValid: false },
  hasApprovedCreditCheck: { value: false, isValid: false },
  citizenships: { value: [], isValid: false },
  isApplyingPersonally: { value: false, isValid: true },
  isPep: { value: undefined, isValid: false },
};

export class OfferStore {
  offersApi: OffersApi;

  rootStore: Store;

  employmentTypeList: ListItem[] = Object.keys(ApplicantEmploymentType)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (ApplicantEmploymentType as KeyObject<string>)[key],
      value: tx(`topUp.employmentStatus.${key}`),
      label: tx(`topUp.employmentStatus.${key}`),
    }));

  sourceOfFundsList: ListItem[] = Object.keys(SourceOfFunds)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (SourceOfFunds as KeyObject<string>)[key],
      value: tx(`topUp.sourceOfFunds.${key}`),
      label: tx(`topUp.sourceOfFunds.${key}`),
    }));

  purposeOfLoanList: ListItem[] = Object.keys(PurposeOfLoan)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (PurposeOfLoan as KeyObject<string>)[key],
      value: tx(`topUp.purposeOfLoan.${key}`),
      label: tx(`topUp.purposeOfLoan.${key}`),
    }));

  housingTypeList: ListItem[] = Object.keys(TopupHousingType)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (TopupHousingType as KeyObject<string>)[key],
      value: tx(`topUp.housingType.${key}`),
      label: tx(`topUp.housingType.${key}`),
    }));

  constructor(offersApi: OffersApi, rootStore: Store) {
    this.offersApi = offersApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  currentOffer?: IOffer = undefined;

  @observable
  currentPendingApplication?: IPendingApplication = undefined;

  @computed
  get currentAccount() {
    return this.rootStore.currentAccount;
  }

  set currentAccount(account: IBaseAccount | undefined) {
    if (typeof account === "string") {
      this.rootStore.currentAccount = Object.values(this.rootStore.accounts)
        .flat()
        .find((a: IBaseAccount) => a.accountNumber === account);
    } else {
      this.rootStore.currentAccount = account;
    }
  }

  @observable
  offers?: IOffer[] = undefined;

  @observable
  loading = false;

  @observable
  loanCalculationData?: ILoanCalculation = undefined;

  @observable
  loadingPendingApplication = false;

  @observable
  pendingApplications?: IPendingApplication[] = undefined;

  @observable
  appliedAmount: number = 0;

  @observable
  currentTopUpStep: number = 1;

  @observable
  detailsStepSummary: boolean = false;

  @observable
  topUpFlowStep: TopUpFlowStep = TopUpFlowStep.DecisionStep;

  @observable
  topUpAmount: number = 0;

  @observable
  topUpRequestedAmount: number = 0;

  @observable
  lowAmountPeriodLimit: number = 10;

  @observable
  highAmountPeriodLimit: number = 12;

  @observable
  topUpRepaymentTime: number = this.lowAmountPeriodLimit;

  @observable
  mainTopupApplicantData = emptyTopupApplicantData;

  @observable
  coTopupApplicantData = emptyTopupApplicantData;

  @observable
  purposeOfLoan: ValidationObject<string> = { value: "", isValid: false };

  @observable
  customPurposeOfLoan: ValidationObject<string> = { value: "", isValid: false };

  @observable
  applicantsShareLivingCost: ValidationObject<boolean> = {
    value: true,
    isValid: true,
  };

  @observable
  topUpInsuranceAccepted: boolean = false;

  @observable
  showTopUpValidationErrors: boolean = false;

  @observable
  addTopUpResponse?: IAddTopUpResponse;

  @observable
  canFinalizeTopUp: boolean = false;

  @observable
  payoutAccount: IToAccountFields | undefined;

  @observable
  pendingApplicationMonthlyCost: number = 0;

  @observable
  lowAmountLimit: number = 80000;

  @observable
  maxTotalExposure: number = 350000;

  @observable
  minimumTopUpAmount: number = 10000;

  @observable
  maxLoanAmount: number = 0;

  @observable
  topUpStateChecker?: NodeJS.Timeout;

  @observable
  pollForSigningRetries: number = 0;

  @observable
  shouldResetTopup: boolean = true; // Flag only used for mobile apps for controlling topup resets

  // Final scoring states if PendingApplicationState is 'Scoring'
  finalScoringPollingStates: PendingApplicationState[] = [
    PendingApplicationState.Rejected,
    PendingApplicationState.PendingAccept,
    PendingApplicationState.DownSellPendingAccept,
    PendingApplicationState.UnderInvestigation,
  ];

  // Final scoring states if PendingApplicationState is 'Accepted'
  finalAcceptedPollingStates: PendingApplicationState[] = [
    PendingApplicationState.ReadyForSigning,
    PendingApplicationState.WaitingForParticipantSigning,
    PendingApplicationState.UnderInvestigation,
    PendingApplicationState.Rejected,
  ];

  postSigningStates: PendingApplicationState[] = [
    PendingApplicationState.WaitingForParticipantSigning,
    PendingApplicationState.Disbursement,
    PendingApplicationState.Completed,
  ];

  @computed
  get currentAccountIsWay4Account() {
    return !!this.currentAccount?.accountNumber?.match(/^9353\d+$/);
  }

  @computed
  get topUpMonthlyCost() {
    if (this.currentPendingApplication) {
      return this.currentPendingApplication.monthlyCost;
    }

    return this.loanCalculationData?.monthlyCost || 0;
  }

  @computed
  get totalLoanAmount() {
    if (!this.currentAccount) return 0;

    if (this.currentPendingApplication && this.currentPendingApplication.approvedAmount)
      return this.currentPendingApplication.approvedAmount;

    return Math.abs(this.currentAccount?.bookedBalance || 0) + this.topUpAmount;
  }

  @computed
  get isLowTotalLoanAmount() {
    return this.totalLoanAmount <= this.lowAmountLimit;
  }

  @computed
  get templateData(): any {
    if (!this.currentOffer && !this.currentPendingApplication) return {};

    const { currency, locale } = this.rootStore.uiStore;

    const ppiPremieAmount = 5000;
    let ppiPremiePercentage = 0;
    if (
      this.currentOffer?.offerCategory === OfferCategory.AOL ||
      this.currentOffer?.offerCategory === OfferCategory.PPIL
    ) {
      ppiPremiePercentage = 8.85;
    } else {
      ppiPremiePercentage = this.currentAccountIsWay4Account ? 0.9 : 1;
    }
    const ppiPremieCalculatedAmount = ppiPremieAmount * (ppiPremiePercentage / 100);

    const ppiPremieExample = `${toLocaleString(ppiPremieAmount, currency, locale, 0)}, blir ${ppiPremiePercentage
      .toString()
      .replace(".", ",")}% av det  ${toLocaleString(ppiPremieCalculatedAmount, currency, locale, 0)}`;

    const topUpTotalCost = this.loanCalculationData?.totalCost || this.topUpMonthlyCost * this.topUpRepaymentTime * 12;

    const data: any = {
      ...this.currentOffer,
      ...this.currentAccount,
      accountName: this.currentAccount?.name,
      yearlyFee: toLocaleString(195, currency, locale, 0),
      withdrawalFee: "3 %",
      minimumWithdrawalFee: toLocaleString(35, currency, locale, 0),
      currentMonth: dayjs().format("MMMM YYYY"),
      invoiceFee: toLocaleString(this.currentAccount?.invoiceFee || 0, currency, locale, 0),
      ppiPremiePercentage: `${toLocaleString(
        ppiPremiePercentage,
        undefined,
        locale,
        Number.isInteger(ppiPremiePercentage) ? 0 : 2,
      )} %`,
      ppiPremieExample,
      topUpRequestedAmount: toLocaleString(this.topUpAmount, undefined, locale, 0),
      topUpApprovedAmount: toLocaleString(this.topUpAmount, undefined, locale, 0),
      // TODO: Depending on BIAN API, we might need to add the current balance of the loan here
      topUpTotalRequestedAmount: toLocaleString(this.topUpRequestedAmount, undefined, locale, 0),
      // TODO: Depending on BIAN API, we might need to add the current balance of the loan here
      topUpTotalApprovedAmount: toLocaleString(this.topUpAmount, undefined, locale, 0),
      topUpRepaymentTime: this.topUpRepaymentTime,
      topUpMonthlyCost: toLocaleString(this.topUpMonthlyCost, undefined, locale, 0),
      topUpTotalCost: toLocaleString(topUpTotalCost, undefined, locale, 0),
    };

    if (this.currentAccount?.accountInterest) {
      data.accountInterest = `${toLocaleString(this.currentAccount.accountInterest, undefined, locale, 2)} %`;

      const lincAmount = (this.currentAccount?.creditLimit ?? 0) + this.appliedAmount;
      const nominalRate = (this.currentAccount.accountInterest || 0) / 100;
      const effectiveInterest = this.loanCalculationData?.effectiveInterest || this.currentAccount?.effectiveInterest;

      if (effectiveInterest) {
        data.effectiveInterest = `${toLocaleString(effectiveInterest, undefined, locale, 2)} %`;
      }

      data.lincAmount = toLocaleString(lincAmount, currency, locale, 0);

      const monthlyCost = calculateMonthlyCost(lincAmount, nominalRate, 12, 195);

      data.lincMonthlyCost = toLocaleString(monthlyCost, currency, locale, 0);

      data.lincTotalCost = toLocaleString(monthlyCost * 12, currency, locale, 0);
    }

    if (this.currentOffer?.offerCategory === OfferCategory.AOL) {
      data.offerAmount = toLocaleString(
        this.maxLoanAmount ?? this.currentOffer.offerAmount ?? 0,
        this.rootStore.uiStore.currency,
        this.rootStore.uiStore.locale,
        0,
      );
    } else if (this.currentOffer?.offerCategory === OfferCategory.LINC) {
      data.offerAmount = toLocaleString(
        this.currentOffer.offerAmount ?? 0,
        this.rootStore.uiStore.currency,
        this.rootStore.uiStore.locale,
        0,
      );
    }
    return data;
  }

  showEmployer = (applicant: ITopUpApplicantForm) => {
    const employmentType = this.employmentTypeList.find((e) => e.value === applicant.employmentType.value);

    return (
      employmentType?.enumValue !== ApplicantEmploymentType.Retired &&
      employmentType?.enumValue !== ApplicantEmploymentType.SelfEmployed &&
      employmentType?.enumValue !== ApplicantEmploymentType.Student &&
      employmentType?.enumValue !== ApplicantEmploymentType.Unemployed
    );
  };

  @action
  setCurrentOffer = (offer?: IOffer) => {
    this.currentOffer = offer;
  };

  @action
  setCurrentPendingApplication = (pendingApplication?: IPendingApplication) => {
    this.currentPendingApplication = pendingApplication;

    if (this.pendingApplications && pendingApplication) {
      const index = this.pendingApplications.findIndex(
        (app) => app.applicationId === this.currentPendingApplication!.applicationId,
      );

      if (index > -1) {
        this.pendingApplications[index] = pendingApplication;
      }
    }
  };

  @action
  setCurrentAccount = () => {
    if (!this.currentOffer) return;
    this.currentAccount = this.rootStore.getOfferAccount(this.currentOffer);
  };

  @action
  getCurrentAccountTypeRoute = (): string => {
    switch (this.currentOffer?.offerCategory) {
      case OfferCategory.LINC:
      case OfferCategory.PFMC:
      case OfferCategory.PPI:
      case OfferCategory.PPIC:
        return "card";
      case OfferCategory.PFML:
      case OfferCategory.PPIL:
      case OfferCategory.AOL:
        return "loan";
      default:
        return "";
    }
  };

  @action
  setCurrentOfferById = async (offerId: string) => {
    if (this.offers === undefined) {
      await this.getOffers();
    }

    const offer = this.offers?.find((o) => o.offerId === offerId);

    if (offer) {
      this.setCurrentOffer(offer);

      if (offer.offerCategory === OfferCategory.LINC) this.setAppliedAmount(offer.offerAmount);
    }
  };

  @action
  getPendingApplicationByAccountNumber = (accountNumber: string) => {
    const pendingApplication = this.pendingApplications?.find((application) =>
      application.accountNumber.includes(accountNumber),
    );
    return pendingApplication;
  };

  @action
  pendingApplicationStateIsActive = (state: PendingApplicationState, includeInDisbursement = false) => {
    const activeStates = [
      PendingApplicationState.Scoring,
      PendingApplicationState.UnderInvestigation,
      PendingApplicationState.PendingAccept,
      PendingApplicationState.DownSellPendingAccept,
      PendingApplicationState.Accepted,
      PendingApplicationState.ReadyForSigning,
      PendingApplicationState.WaitingForParticipantSigning,
    ];
    if (includeInDisbursement) activeStates.push(PendingApplicationState.Disbursement);

    return activeStates.includes(state);
  };

  @action
  getActivePendingApplicationByAccountNumber = (displayNumber?: string, includeInDisbursement = false) => {
    if (!displayNumber) return undefined;

    const pendingApplication = this.pendingApplications?.find(
      (application) =>
        application.accountNumber.includes(displayNumber) &&
        this.pendingApplicationStateIsActive(application.applicationState, includeInDisbursement),
    );
    return pendingApplication;
  };

  @action
  setCurrentPendingApplicationByApplicationId = async (applicationId?: string) => {
    if (!applicationId) return;

    this.loadingPendingApplication = true;
    const response = await this.offersApi.getApplication(applicationId);
    if (response?.ok && response.data) {
      const pendingApplication = response.data;
      this.setCurrentPendingApplication(pendingApplication);
      this.setTopupFlowStateFromCurrentPendingApplication();
    }
    this.loadingPendingApplication = false;
  };

  @action
  setTopupFlowStateFromCurrentPendingApplication = () => {
    if (!this.currentPendingApplication) return;

    this.currentAccount = this.rootStore.getAccountByDisplayNumber(this.currentPendingApplication.accountNumber);
    this.topUpRepaymentTime = this.currentPendingApplication.paybackTime / 12;

    this.topUpAmount = this.currentPendingApplication.approvedAmount;
    this.topUpRequestedAmount = this.currentPendingApplication.requestedAmount;

    const status = this.currentPendingApplication.applicationState;

    this.canFinalizeTopUp =
      status === PendingApplicationState.PendingAccept ||
      status === PendingApplicationState.DownSellPendingAccept ||
      (status === PendingApplicationState.ReadyForSigning && !!this.currentPendingApplication.signUrl);

    const { signingApplicationId } = this.rootStore.customerPersistentStore;

    this.currentTopUpStep = 3;
    switch (status) {
      case PendingApplicationState.Scoring:
      case PendingApplicationState.Accepted:
        this.setTopUpFlowStep(TopUpFlowStep.Processing);
        if (this.topUpStateChecker === undefined) {
          this.startApplicationStatePolling(
            this.currentPendingApplication!.applicationId,
            status === PendingApplicationState.Scoring
              ? this.finalScoringPollingStates
              : this.finalAcceptedPollingStates,
          );
        }
        break;
      case PendingApplicationState.Rejected:
      case PendingApplicationState.UnderInvestigation:
      case PendingApplicationState.PendingAccept:
      case PendingApplicationState.DownSellPendingAccept:
      case PendingApplicationState.WaitingForParticipantSigning:
        this.setTopUpFlowStep(TopUpFlowStep.DecisionStep);
        break;
      case PendingApplicationState.ReadyForSigning:
        if (signingApplicationId && signingApplicationId === this.currentPendingApplication.applicationId) {
          this.setTopUpFlowStep(TopUpFlowStep.Processing);
          this.startApplicationSigningPolling(this.currentPendingApplication.applicationId);
        } else {
          this.setTopUpFlowStep(TopUpFlowStep.DecisionStep);
        }
        break;
      case PendingApplicationState.Disbursement:
      case PendingApplicationState.Completed:
        if (signingApplicationId === this.currentPendingApplication.applicationId)
          this.rootStore.customerPersistentStore.setSigningApplicationId("");
        this.currentTopUpStep = 4;
        break;
      default:
        break;
    }
  };

  @action
  getOffers = async (reset = false) => {
    this.loading = true;
    if (this.offers && !reset) {
      this.loading = false;
      return this.offers;
    }
    const response = await this.offersApi.getOffers();
    if (response?.ok && response.data?.offers) {
      this.offers = response.data.offers;
      this.loading = false;
      return response.data.offers;
    }
    this.offers = undefined;
    this.loading = false;
    return null;
  };

  getOffer = async () => this.offersApi.getOffers();

  @action
  getLoanCalculations = (offerId: string): void => {
    const offer = this.offers?.find((o) => o.offerId === offerId);
    if (!offer) return;

    const account = this.rootStore.getOfferAccount(offer);

    if (!account?.accountInterest) return;

    const currentBalance = Math.abs(account.bookedBalance ?? 0);

    const maxLoanAmount = Math.floor((this.maxTotalExposure - currentBalance) / 1000) * 1000;
    this.topUpAmount = maxLoanAmount;
    this.maxLoanAmount = maxLoanAmount;
    this.topUpRepaymentTime = this.isLowTotalLoanAmount ? this.lowAmountPeriodLimit : this.highAmountPeriodLimit;

    const totalAmount = maxLoanAmount + currentBalance;

    this.loanCalculationData = getLoanCalculationData(account.accountInterest, this.topUpRepaymentTime, totalAmount);
  };

  @action
  updateLoanCalculations = () => {
    if (!this.currentOffer || !this.topUpAmount || !this.topUpRepaymentTime || !this.currentAccount?.accountInterest)
      return;
    const currentBalance = Math.abs(this.currentAccount.bookedBalance ?? 0);

    const totalAmount = this.topUpAmount + currentBalance;

    this.loanCalculationData = getLoanCalculationData(
      this.currentAccount.accountInterest,
      this.topUpRepaymentTime,
      totalAmount,
    );
  };

  @action
  getPendingApplications = async () => {
    const response = await this.offersApi.getPendingApplications();
    if (response?.ok && response.data) {
      this.pendingApplications = response.data.pendingApplications;
      return;
    }
    this.pendingApplications = undefined;
  };

  @action
  consumeOffer = async (offerId: string) => {
    const offer = this.offers?.find((o) => o.offerId === offerId);
    if (!offer) return null;

    let response;

    switch (offer.offerCategory) {
      case OfferCategory.LINC:
        response = await this.offersApi.acceptLimitIncrease(offerId, this.appliedAmount);
        break;

      default:
        response = await this.offersApi.consumeOffer(offerId);
        break;
    }
    if (response?.ok) {
      this.offers = this.offers?.filter((o) => o.offerId !== offerId);
      await this.rootStore.getCustomerProducts();
    }
    return response;
  };

  @action
  addTopUp = async () => {
    if (!this.currentOffer) {
      return false;
    }

    if (!this.validateLoanTopUpDetails()) {
      // Go back to applicant details form
      this.showTopUpValidationErrors = true;
      this.detailsStepSummary = false;
      this.currentTopUpStep = 2;
      this.topUpFlowStep = TopUpFlowStep.ApplicantInformationStep;
      return false;
    }

    const parseApplicantDetails = (
      applicant: ITopUpApplicantForm,
      isCoApplicant: boolean,
    ): IApplicantDetails | undefined => {
      const employmentType = this.employmentTypeList.find((type) => type.value === applicant.employmentType.value);

      if (!employmentType) return undefined;

      const currentSourceOfFunds = this.sourceOfFundsList.find((e) => e.value === applicant.sourceOfFunds.value);

      if (!currentSourceOfFunds) return undefined;

      const currentHousingType = this.housingTypeList.find((e) => e.value === applicant.housingType.value);

      const sourceOfFunds = currentSourceOfFunds.enumValue as SourceOfFunds;

      const coApplicantSharedLivingCost = this.applicantsShareLivingCost.value && isCoApplicant;

      const result: IApplicantDetails = {
        accommodationStatus: coApplicantSharedLivingCost
          ? TopupHousingType.None
          : (currentHousingType?.enumValue as TopupHousingType),
        employmentDetails: {
          employer: applicant.employer.value,
          employmentStatus: employmentType.enumValue as ApplicantEmploymentType,
        },
        financialDetails: {
          monthlyGrossIncome: applicant.monthlyIncome.value,
          housingCost: coApplicantSharedLivingCost ? 0 : applicant.housingCost.value,
        },
        numberOfChildrenInHousehold: coApplicantSharedLivingCost ? 0 : applicant.numberOfChildren.value,
        email: applicant.email.value,
        mobile: applicant.phone.value,
        amlDetails: {
          // TODO: Investigate why originOfFunds is incorrectly set in BIAN
          originOfFunds: sourceOfFunds,
          originOfFundsOther: sourceOfFunds !== SourceOfFunds.Other ? applicant.customSourceOfFunds.value : undefined,
        },
      };

      return result;
    };

    const currentPurposeOfLoan = this.purposeOfLoanList.find((e) => e.value === this.purposeOfLoan.value);

    let addOnApplication: IAddOnApplication = {
      appliedAmount: this.topUpAmount,
      paybackTime: this.topUpRepaymentTime * 12,
      mainApplicantDetail: parseApplicantDetails(this.mainTopupApplicantData, false),
      purposeOfLoan: currentPurposeOfLoan?.enumValue as PurposeOfLoan,
    };

    if (addOnApplication.purposeOfLoan === PurposeOfLoan.Other) {
      addOnApplication.customPurposeOfLoan = this.customPurposeOfLoan.value;
    }

    if (this.currentAccount?.hasCoApplicant && this.currentAccount.coApplicantName) {
      addOnApplication = {
        ...addOnApplication,
        applicantsShareLivingCost: this.applicantsShareLivingCost.value,
        coApplicantDetail: parseApplicantDetails(this.coTopupApplicantData, true),
      };
    }

    const addTopUpRequest: IAddTopUpRequest = {
      addOnApplication,
    };

    const response = await this.offersApi.addTopUp(this.currentOffer.offerId, addTopUpRequest);

    if (response?.ok && response.data) {
      const { applicationId } = response.data;

      // Top Up successful. Refetch offers and pending applications since top up offer is consumed.
      const updatedOffers = this.getOffers(true);
      const updatedPendingApplications = this.getPendingApplications();

      await Promise.all([updatedOffers, updatedPendingApplications]);

      if (applicationId) {
        this.setTopUpFlowStep(TopUpFlowStep.Processing);
      }

      this.addTopUpResponse = response.data;
      return true;
    }

    return false;
  };

  @action
  updateApplication = async () => {
    if (!this.currentPendingApplication || !this.payoutAccount) {
      return;
    }

    if (!this.validateApplicantsEBAInformation()) {
      this.showTopUpValidationErrors = true;
    }

    const parseApplicantDetails = (applicant: ITopUpApplicantForm): IUpdateApplicantDetails | undefined => {
      const result: IUpdateApplicantDetails = {
        citizenships: applicant.citizenships.value.map((citizenship) => citizenship.value),
        politicallyExposedPerson: applicant.isPep.value!,
        applyingOnBehalfOfSomeoneElse: !applicant.isApplyingPersonally.value,
      };

      return result;
    };

    let updateApplicationRequest: IUpdateApplicationRequest = {
      mainApplicantDetail: parseApplicantDetails(this.mainTopupApplicantData),
      accountNumber: this.payoutAccount.accountNumber,
      clearingNumber: this.payoutAccount.clearingNumber!,
    };

    if (this.currentAccount?.hasCoApplicant && this.currentAccount.coApplicantName) {
      updateApplicationRequest = {
        ...updateApplicationRequest,
        coApplicantDetail: parseApplicantDetails(this.coTopupApplicantData),
      };
    }

    const response = await this.offersApi.updateApplication(
      this.currentPendingApplication.applicationId,
      updateApplicationRequest,
    );

    if (response?.ok) {
      this.setTopUpFlowStep(TopUpFlowStep.Processing);
      this.startApplicationStatePolling(this.currentPendingApplication!.applicationId, this.finalAcceptedPollingStates);
    } else {
      this.currentPendingApplication.applicationState = PendingApplicationState.UnexpectedError;
      this.canFinalizeTopUp = false;
      this.setTopUpFlowStep(TopUpFlowStep.DecisionStep);
    }
  };

  @action
  startApplicationStatePolling = async (applicationId: string, expectedStates: PendingApplicationState[]) => {
    if (this.topUpStateChecker === undefined) {
      this.topUpStateChecker = setInterval(async () => {
        const check = await this.pollForApplicationState(applicationId, expectedStates);
        if (check) {
          if (this.topUpStateChecker) {
            clearInterval(this.topUpStateChecker);
            this.topUpStateChecker = undefined;
          }
          this.setTopupFlowStateFromCurrentPendingApplication();
        }
      }, 5000);
    }
  };

  @action
  pollForApplicationState = async (applicationId: string, expectedStates: PendingApplicationState[]) => {
    const response = await this.offersApi.getApplication(applicationId);
    if (response?.ok && response.data) {
      this.setCurrentPendingApplication(response.data);
      return expectedStates.includes(response.data.applicationState);
    }
    if (response?.status === 404) {
      this.currentTopUpStep = 3;
      this.topUpFlowStep = TopUpFlowStep.DecisionStep;
      this.setCurrentPendingApplication(undefined);
      return true;
    }
    return false;
  };

  @action
  startApplicationSigningPolling = async (applicationId: string) => {
    if (this.topUpStateChecker === undefined) {
      this.topUpStateChecker = setInterval(async () => {
        const check = await this.pollForApplicationSigning(applicationId);
        if (check) {
          if (this.topUpStateChecker) {
            clearInterval(this.topUpStateChecker);
            this.topUpStateChecker = undefined;
          }
          this.rootStore.customerPersistentStore.setSigningApplicationId("");
          this.pollForSigningRetries = 0;
          this.setTopupFlowStateFromCurrentPendingApplication();
        }
      }, 5000);
    }
  };

  @action
  pollForApplicationSigning = async (applicationId: string) => {
    const response = await this.offersApi.getApplication(applicationId);
    if (response?.ok && response.data) {
      this.setCurrentPendingApplication(response.data);
      const isMissingSignUrl =
        response.data.applicationState === PendingApplicationState.ReadyForSigning && !response.data.signUrl;
      return this.postSigningStates.includes(response.data.applicationState) || isMissingSignUrl;
    }
    if (response?.status === 404) {
      // Here we are expecting a correct signing status, sometimes the application shortly returns 404 directly after signicat signing.
      // We safeguard this with a retry limit
      this.pollForSigningRetries += 1;
      if (this.pollForSigningRetries === 5) {
        this.currentTopUpStep = 3;
        this.topUpFlowStep = TopUpFlowStep.DecisionStep;
        this.pollForSigningRetries = 0;
        this.setCurrentPendingApplication(undefined);
        return true;
      }
    }
    return false;
  };

  @action
  setAppliedAmount = (amount: number) => {
    this.appliedAmount = amount;
  };

  @action
  setTopUpAmount = (amount: number) => {
    this.topUpAmount = amount;

    if (this.isLowTotalLoanAmount && this.topUpRepaymentTime > this.lowAmountPeriodLimit) {
      this.topUpRepaymentTime = this.lowAmountPeriodLimit;
    }
  };

  @action
  setTopUpRepaymentTime = (years: number) => {
    this.topUpRepaymentTime = years;
  };

  @action
  setNextTopUpStep = () => {
    this.currentTopUpStep += 1;
    if (this.currentTopUpStep === 2) {
      this.topUpFlowStep = TopUpFlowStep.ApplicantInformationStep;
    }
  };

  @action
  setPreviousTopUpStep = () => {
    this.currentTopUpStep -= 1;
  };

  @action
  setMainApplicantData = (applicant: ITopUpApplicantForm) => {
    this.mainTopupApplicantData = {
      ...this.mainTopupApplicantData,
      ...applicant,
    };
  };

  @action
  setCoApplicantData = (applicant: ITopUpApplicantForm) => {
    this.coTopupApplicantData = {
      ...this.coTopupApplicantData,
      ...applicant,
    };
  };

  @action
  setTopUpFlowStep = (value: TopUpFlowStep) => {
    this.topUpFlowStep = value;
  };

  @action
  setPayoutAccount = (value: ITransferAccountFields | undefined) => {
    this.payoutAccount = value;
  };

  @action
  setPayoutAccountByName = (value: string | undefined) => {
    if (!value) {
      this.payoutAccount = undefined;
    }
    const toSavedAccount = this.rootStore.transferStore.savedAccounts?.find((account) => account.displayName === value);

    if (toSavedAccount) {
      this.payoutAccount = toSavedAccount;
    }
  };

  validateCurrentOffer = () => {
    if (!this.currentOffer || !this.currentAccount) return false;

    switch (this.currentOffer.offerCategory) {
      case OfferCategory.PFMC:
        return this.currentOffer.isPFMOffer && this.currentOffer.offerCategory === OfferCategory.PFMC;
      case OfferCategory.LINC:
        return (
          this.currentOffer.offerAmount > 0 &&
          (this.currentAccount.creditLimit ?? 0) > 0 &&
          this.currentOffer.offerCategory === OfferCategory.LINC
        );
      case OfferCategory.PPI:
        return (
          this.currentOffer.isInsuranceOffer &&
          this.currentOffer.insurance?.schemaNumber &&
          this.currentOffer.offerCategory === OfferCategory.PPI
        );
      case OfferCategory.PPIC:
        return (
          this.currentOffer.isInsuranceOffer &&
          this.currentOffer.insurance?.schemaNumber &&
          this.currentOffer.offerCategory === OfferCategory.PPIC
        );
      case OfferCategory.PPIL:
        return (
          this.currentOffer.isInsuranceOffer &&
          this.currentOffer.insurance?.schemaNumber &&
          this.currentOffer.offerCategory === OfferCategory.PPIL
        );
      case OfferCategory.PFML:
        return this.currentOffer.isPFMOffer && this.currentOffer.offerCategory === OfferCategory.PFML;
      case OfferCategory.AOL:
        return this.currentOffer.offerCategory === OfferCategory.AOL && this.loanCalculationData?.monthlyCost;
      default:
        return true;
    }
  };

  validateCurrentPendingApplication = () => {
    return !!this.currentPendingApplication;
  };

  validateLoanTopUpDetails = () => {
    if (!this.currentAccount) return false;

    const isValid =
      this.validateApplicantsInformation() &&
      this.validateApplicantsLivingSituation() &&
      this.validateApplicantsEmployment();

    this.showTopUpValidationErrors = !isValid;
    return isValid;
  };

  validateApplicantsInformation = () => {
    if (!this.currentAccount) return false;

    const { hasCoApplicant, coApplicantName } = this.currentAccount as ILoanAccount;

    let isValid =
      this.mainTopupApplicantData.email.isValid &&
      this.mainTopupApplicantData.phone.isValid &&
      this.purposeOfLoan.isValid;

    const currentPurposeOfLoan = this.purposeOfLoanList.find((e) => e.value === this.purposeOfLoan.value);

    if (currentPurposeOfLoan?.enumValue === PurposeOfLoan.Other) {
      isValid = isValid && this.customPurposeOfLoan.isValid;
    }

    if (hasCoApplicant && coApplicantName) {
      if (this.coTopupApplicantData.email.value === this.mainTopupApplicantData.email.value)
        this.coTopupApplicantData.email.isValid = false;

      if (this.coTopupApplicantData.phone.value === this.mainTopupApplicantData.phone.value)
        this.coTopupApplicantData.phone.isValid = false;

      isValid = isValid && this.coTopupApplicantData.email.isValid && this.coTopupApplicantData.phone.isValid;
    }

    this.showTopUpValidationErrors = !isValid;
    return isValid;
  };

  validateApplicantsLivingSituation = () => {
    if (!this.currentAccount) return false;

    const { hasCoApplicant, coApplicantName } = this.currentAccount as ILoanAccount;

    let isValid =
      this.applicantsShareLivingCost.isValid &&
      this.mainTopupApplicantData.housingCost.isValid &&
      this.mainTopupApplicantData.housingType.isValid &&
      this.mainTopupApplicantData.numberOfChildren.isValid;

    const isSharingLivingCost = this.applicantsShareLivingCost.value;
    if (hasCoApplicant && coApplicantName && !isSharingLivingCost) {
      isValid =
        isValid &&
        this.coTopupApplicantData.housingCost.isValid &&
        this.coTopupApplicantData.housingType.isValid &&
        this.coTopupApplicantData.numberOfChildren.isValid;
    }

    this.showTopUpValidationErrors = !isValid;
    return isValid;
  };

  validateApplicantsEmployment = () => {
    if (!this.currentAccount) return false;

    const { hasCoApplicant, coApplicantName } = this.currentAccount as ILoanAccount;

    let isValid = this.validateApplicantEmployment(this.mainTopupApplicantData);

    if (hasCoApplicant && coApplicantName) {
      isValid = isValid && this.validateApplicantEmployment(this.coTopupApplicantData);
    }

    this.showTopUpValidationErrors = !isValid;
    return isValid;
  };

  validateApplicantEmployment = (applicant: ITopUpApplicantForm) => {
    const employmentType = this.employmentTypeList.find((type) => type.value === applicant.employmentType.value);

    if (!employmentType) {
      return false;
    }

    let isValid =
      applicant.monthlyIncome.isValid &&
      applicant.employmentType.isValid &&
      applicant.hasApprovedCreditCheck.isValid &&
      applicant.sourceOfFundsIsSalary.isValid &&
      !!employmentType.enumValue;

    switch (employmentType.enumValue) {
      case ApplicantEmploymentType.PermanentPosition:
      case ApplicantEmploymentType.TemporaryPosition:
        isValid = isValid && applicant.employer.isValid;
        break;
      case ApplicantEmploymentType.Unemployed:
      case ApplicantEmploymentType.SelfEmployed:
      case ApplicantEmploymentType.Student:
      case ApplicantEmploymentType.Retired:
      case ApplicantEmploymentType.None:
      default:
        break;
    }

    if (!applicant.sourceOfFundsIsSalary.value) {
      isValid = isValid && applicant.sourceOfFunds.isValid;

      const currentSourceOfFunds = this.sourceOfFundsList.find((e) => e.value === applicant.sourceOfFunds.value);

      if (currentSourceOfFunds?.enumValue === SourceOfFunds.Other) {
        isValid = isValid && applicant.customSourceOfFunds.isValid;
      }
    }

    return isValid;
  };

  validateApplicantsEBAInformation = () => {
    if (!this.currentAccount) return false;

    const { hasCoApplicant, coApplicantName } = this.currentAccount as ILoanAccount;

    let isValid =
      this.mainTopupApplicantData.isPep.isValid &&
      this.mainTopupApplicantData.isApplyingPersonally.value === true &&
      this.mainTopupApplicantData.citizenships.isValid;

    if (hasCoApplicant && coApplicantName) {
      isValid =
        isValid &&
        this.coTopupApplicantData.isPep.isValid &&
        this.coTopupApplicantData.isApplyingPersonally.value === true &&
        this.coTopupApplicantData.citizenships.isValid;
    }

    this.showTopUpValidationErrors = !isValid;
    return isValid;
  };

  getSpecificOfferFromType = (offerType?: OfferCategory) => {
    if (!offerType) return undefined;
    let currentAccount: any;

    switch (offerType) {
      case OfferCategory.LINC:
      case OfferCategory.PFMC:
      case OfferCategory.PPI: {
        currentAccount = this.rootStore.creditStore?.currentAccount;
        if (!currentAccount) return undefined;
        return this.offers?.find(
          (o) => o.offerCategory === offerType && o.accountNumber.indexOf(currentAccount.accountNumber) > -1,
        );
      }
      case OfferCategory.PFML:
      case OfferCategory.PPIL:
      case OfferCategory.AOL:
        currentAccount = this.rootStore.loanStore?.currentAccount;
        if (!currentAccount) return undefined;
        return this.offers?.find(
          (o) =>
            o.offerCategory === offerType &&
            o.accountNumber.indexOf(
              offerType === OfferCategory.AOL ? currentAccount.displayNumber : currentAccount.accountNumber,
            ) > -1,
        );
      default:
        return undefined;
    }
  };

  customerCanActOn = (offerType: string) => {
    let offer: IOffer | undefined;
    const offerTypes = offerType.split(",");
    for (let i = 0; i < offerTypes.length; i += 1) {
      const type = offerTypes[i];
      offer = this.getSpecificOfferFromType(type as OfferCategory);
      if (offer) break;
    }
    return !!offer;
  };

  generateOfferQueryParams = (offerType?: string) => {
    if (!offerType) return "";
    const offerTypes = offerType.split(",");
    let offer: IOffer | undefined;
    for (let i = 0; i < offerTypes.length; i += 1) {
      const type = offerTypes[i];
      offer = this.getSpecificOfferFromType(type as OfferCategory);
      if (offer) break;
    }

    if (!offer) return "";

    return `offerId=${offer.offerId}`;
  };

  generateOfferId = (offerType?: string) => {
    if (!offerType) return "";
    const offerTypes = offerType.split(",");
    let offer: IOffer | undefined;
    for (let i = 0; i < offerTypes.length; i += 1) {
      const type = offerTypes[i];
      offer = this.getSpecificOfferFromType(type as OfferCategory);
      if (offer) break;
    }

    if (!offer) return "";

    return { offerId: offer.offerId };
  };

  getTrackingCategory = (offerCategory?: OfferCategory): TrackingCategory | undefined => {
    const offerType = this.currentOffer?.offerCategory || offerCategory;
    switch (offerType) {
      case OfferCategory.PPIC:
      case OfferCategory.LINC:
      case OfferCategory.PFMC:
      case OfferCategory.AMC:
        return TrackingCategory.ProductCard;
      case OfferCategory.PPIL:
      case OfferCategory.PFML:
      case OfferCategory.AOL:
        return TrackingCategory.ProductLoan;

      default:
        return undefined;
    }
  };

  getTrackingEventPrefix = (): string | undefined => {
    if (!this.currentOffer || !this.currentAccount) return undefined;

    const { currency, locale } = this.rootStore.uiStore;

    const offerType = this.currentOffer.offerCategory;
    switch (offerType) {
      case OfferCategory.LINC:
        return toLocaleString((this.currentAccount.creditLimit || 0) + this.appliedAmount, currency, locale, 0);

      default:
        return undefined;
    }
  };

  getTrackingValue = (): number | undefined => {
    if (!this.currentOffer || !this.currentAccount) return undefined;

    const offerType = this.currentOffer.offerCategory;
    switch (offerType) {
      case OfferCategory.LINC:
        return (this.currentAccount.creditLimit || 0) + this.appliedAmount;

      default:
        return undefined;
    }
  };

  getTrackingCTA = (offerCategory?: OfferCategory): TrackingAction | undefined => {
    const offerType = this.currentOffer?.offerCategory || offerCategory;
    switch (offerType) {
      case OfferCategory.PPIC:
      case OfferCategory.PPIL:
        return TrackingAction.InsuranceCTA;
      case OfferCategory.LINC:
        return TrackingAction.RaiseLimitCTA;
      case OfferCategory.AMC:
        return TrackingAction.ActivateCardCTA;
      case OfferCategory.PFMC:
      case OfferCategory.PFML:
        return TrackingAction.PaymentfreeMonthCTA;

      default:
        return undefined;
    }
  };

  getTrackingActionSuccess = (offerCategory?: OfferCategory): TrackingAction | undefined => {
    const offerType = this.currentOffer?.offerCategory || offerCategory;
    switch (offerType) {
      case OfferCategory.PPIC:
      case OfferCategory.PPIL:
        return TrackingAction.InsuranceOK;

      case OfferCategory.AMC:
        return TrackingAction.ActivationCardOK;

      case OfferCategory.PFMC:
      case OfferCategory.PFML:
        return TrackingAction.PaymentfreeMonthOK;

      case OfferCategory.LINC:
        return TrackingAction.RaiseLimitOK;

      default:
        return undefined;
    }
  };

  getTrackingActionError = (offerCategory?: OfferCategory): TrackingAction | undefined => {
    const offerType = this.currentOffer?.offerCategory || offerCategory;
    switch (offerType) {
      case OfferCategory.PPIC:
      case OfferCategory.PPIL:
        return TrackingAction.InsuranceKO;

      case OfferCategory.AMC:
        return TrackingAction.ActivationCardKO;

      case OfferCategory.PFMC:
      case OfferCategory.PFML:
        return TrackingAction.PaymentfreeMonthKO;

      case OfferCategory.LINC:
        return TrackingAction.RaiseLimitKO;

      default:
        return undefined;
    }
  };

  @action
  resetOffer = () => {
    const offerType = this.currentOffer?.offerCategory;
    switch (offerType) {
      case OfferCategory.PPIC:
      case OfferCategory.PPIL:
      case OfferCategory.AMC:
      case OfferCategory.PFMC:
      case OfferCategory.PFML:
        this.setCurrentOffer(undefined);
        break;
      case OfferCategory.LINC:
        this.setCurrentOffer(undefined);
        this.appliedAmount = 0;
        break;
      case OfferCategory.AOL:
        this.resetTopUp();
        break;
      default:
        // Empty default
        break;
    }
  };

  hasOfferType = (offerCategory: OfferCategory): boolean => {
    return (
      this.offers?.some(
        (o) => o.accountNumber === this.currentAccount?.displayNumber && o.offerCategory === offerCategory,
      ) || false
    );
  };

  @action
  resetTopUp = () => {
    this.addTopUpResponse = undefined;
    this.loanCalculationData = undefined;
    this.currentTopUpStep = 1;
    this.topUpAmount = 0;
    this.topUpRepaymentTime = this.lowAmountPeriodLimit;
    this.mainTopupApplicantData = emptyTopupApplicantData;
    this.coTopupApplicantData = emptyTopupApplicantData;
    this.purposeOfLoan = { value: "", isValid: false };
    this.customPurposeOfLoan = { value: "", isValid: false };
    this.detailsStepSummary = false;
    this.topUpFlowStep = TopUpFlowStep.DecisionStep;
    this.topUpInsuranceAccepted = false;
    this.showTopUpValidationErrors = false;
    this.canFinalizeTopUp = false;
    this.addTopUpResponse = undefined;
    this.payoutAccount = undefined;
    this.loadingPendingApplication = false;
    this.rootStore.transferStore.resetTransaction();
    this.offersApi.mockApplicationState = PendingApplicationState.Scoring;
    this.setCurrentOffer(undefined);
    this.setCurrentPendingApplication(undefined);
    if (this.topUpStateChecker) {
      clearInterval(this.topUpStateChecker);
      this.topUpStateChecker = undefined;
    }
    this.pollForSigningRetries = 0;
  };

  @action
  resetStore = () => {
    this.currentOffer = undefined;
    this.currentAccount = undefined;
    this.offers = undefined;
    this.appliedAmount = 0;
    this.resetTopUp();
  };
}
