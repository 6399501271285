import React, { useState } from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import { Button } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import {
  ApplicantEBAInformation,
  IApplicantEBAInformationLabelsProps,
} from "~views/shared/Applicant/ApplicantEBAInformation";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { usePushInteraction } from "~hooks";

export interface ILoanTopUpEBAStepProps {
  data: ILoanTopUpPage;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpEBAStep = observer(({ data, setPreviousTopUpStep }: ILoanTopUpEBAStepProps) => {
  const { offerStore } = useStores();
  const [loading, setLoading] = useState(false);

  const { ebaAndSigningStep } = data;

  usePushInteraction({
    eventCategory: TrackingCategory.ProductLoan,
    eventAction: TrackingAction.LoanRaise3EBA,
  });

  if (!offerStore.currentAccount) return null;

  const { hasCoApplicant, coApplicantName } = offerStore.currentAccount;

  const ebaLabels: IApplicantEBAInformationLabelsProps = {
    pepScreenTips: ebaAndSigningStep.pepScreenTips,
    pepLabel: ebaAndSigningStep.pepLabel,
    pepInformation: ebaAndSigningStep.pepInformation,
    isPersonallyApplyingLabel: ebaAndSigningStep.isPersonallyApplyingLabel,
    isPersonallyApplyingCheckboxText: ebaAndSigningStep.isPersonallyApplyingCheckboxText,
    isPersonallyApplyingErrorText: ebaAndSigningStep.isPersonallyApplyingErrorText,
  };

  const updateApplication = async () => {
    setLoading(true);
    await offerStore.updateApplication();
    window.scrollTo(0, 0);
    setLoading(false);
  };

  return (
    <div>
      {ebaAndSigningStep.header && <HtmlContent html={`<h1>${ebaAndSigningStep.header}</h1>`} />}
      {ebaAndSigningStep.topText && <HtmlContent html={ebaAndSigningStep.topText} />}
      <ApplicantEBAInformation
        data={ebaLabels}
        applicant={offerStore.mainTopupApplicantData}
        applicantType="main-applicant"
        setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setMainApplicantData(applicant)}
        showValidationErrors={offerStore.showTopUpValidationErrors}
      />

      {hasCoApplicant && coApplicantName && (
        <>
          <h2>{tx("topUp.coApplicantHeader")}</h2>
          <p className={rootStyles.bold}>{coApplicantName}</p>
          <ApplicantEBAInformation
            data={ebaLabels}
            applicant={offerStore.coTopupApplicantData}
            applicantType="co-applicant"
            setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setCoApplicantData(applicant)}
            showValidationErrors={offerStore.showTopUpValidationErrors}
          />
        </>
      )}
      <div className={offerStyles.submit}>
        <Button
          title={ebaAndSigningStep.previousButtonText || tx("misc.cancel")}
          onClick={setPreviousTopUpStep}
          borderColor="black"
          bordered
          large
        />
        <Button
          title={ebaAndSigningStep.continueButtonText || tx("topUp.approveAndSign")}
          color="red"
          onClick={() => {
            const isValid = offerStore.validateApplicantsEBAInformation();

            if (isValid) {
              updateApplication();
            }
          }}
          loading={loading}
          large
        />
      </div>
    </div>
  );
});
