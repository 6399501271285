import { PendingApplicationState } from "../../../models/Content/Enums";
import { IPendingApplication, IPendingApplicationsResponse } from "../../../models/Offer/Offer";

export const mockPendingApplications: IPendingApplicationsResponse = {
  pendingApplications: [
    {
      accountNumber: "4-354176",
      accountType: "L",
      requestedAmount: 42890.23,
      approvedAmount: 42890.23,
      applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
      customerId: 1443325,
      interestRate: 7.7,
      monthlyCost: 511,
      paybackTime: 120,
      applicationState: PendingApplicationState.Disbursement,
    },
  ],
  status: 0,
};

export const mockPendingApplication: IPendingApplication = {
  accountNumber: "4-354176",
  accountType: "L",
  requestedAmount: 42890.23,
  approvedAmount: 42890.23,
  applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
  customerId: 1443325,
  interestRate: 7.7,
  monthlyCost: 511,
  paybackTime: 120,
  applicationState: PendingApplicationState.Scoring,
};
