import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { Locale, PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { Store } from "netbank-shared/src/stores";
import { tx } from "netbank-shared/src/libs/i18n";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { OffersPage } from "../Offers/Offers";
import { TermsPage } from "../Terms/Terms";
import { NewMessage } from "../Messages/NewMessage/NewMessage";
import { NotFoundPage } from "../NotFound/NotFound";
import { LoanTopUpPage } from "../SelfServicePages/LoanTopUp/LoanTopUp";
import { CmsSkeleton } from "../Skeletons/CmsSkeleton";
import { ClosingOfDepositAccountPage } from "../SelfServicePages/ClosingOfDepositAccounts/ClosingOfDepositAccountsPage";
import { CustomerCompliancePage } from "../CustomerCompliancePage/CustomerCompliancePage";
import { LoanTerminationsPage } from "../SelfServicePages/LoanTerminations/LoanTerminationsPage";
import { FILoanTerminationsPage } from "../SelfServicePages/LoanTerminations/FILoanTerminationsPage";
import { MessageInbox } from "../Messages/MessageInbox";
import { UpdateDueDayPage } from "../SelfServicePages/UpdateDueDay/UpdateDuedayPage";
import { PowerOfAttorneyPage } from "../SelfServicePages/PowerOfAttorney/PowerOfAttorneyPage";
import { PromiseToPayPage } from "../SelfServicePages/PromiseToPay/PromiseToPayPage";
import { MileageChangePage } from "../SelfServicePages/MileageChangePage/MileageChangePage";
import { ReduceInstallmentsPage } from "../SelfServicePages/ReduceInstallmentsPage/ReduceInstallmentsPage";
import { IncreaseInstallmentsPage } from "../SelfServicePages/IncreaseInstallmentsPage/IncreaseInstallmentsPage";
import { ExtendResidualAmountPaymentPage } from "../SelfServicePages/ExtendResidualAmountPaymentPage/ExtendResidualAmountPaymentPage";
import { AddInstallmentPage } from "../SelfServicePages/AddInstallmentPage/AddInstallmentPage";
import { getQueryParam } from "netbank-shared/src/libs/utils";
import { AddInsurancePage } from "../SelfServicePages/Insurances/AddInsurancePage";
import { TerminateInsurancePage } from "../SelfServicePages/Insurances/TerminateInsurancePage";
import { PaymentHolidayPage } from "../SelfServicePages/PaymentHolidayPage/PaymentHolidayPage";

export interface CmsPageProps {
  location: Location;
  store: Store;
}

const CmsPage = observer(() => {
  const { rootStore, contentStore, uiStore, creditStore, loanStore, depositStore, leasingStore } = useStores();

  const location = useLocation();
  const navigate = useNavigate();
  const accountId = getQueryParam(location.search, "accountNumber");
  useEffect(() => {
    const fetchPage = async () => {
      // Homepage must exist before fetching page. This useEffect will be rerun when homePage is set
      if (contentStore.getHomePage(tx("routing.lang"))) {
        if (accountId) {
          await rootStore.getAccountDetailsByNumber(accountId);
        }
        await contentStore.getPage(location.pathname);

        const lastContentTypeIndex = contentStore.currentPage?.contentType?.length ?? 0;
        const pageType = contentStore.currentPage?.contentType?.[lastContentTypeIndex - 1];

        if (creditStore.creditCustomerProducts?.length === 1 && pageType === PageType.CardTransitPage) {
          navigate(`/${tx("routing.lang")}/${tx("routing.card")}/${creditStore.creditCustomerProducts[0].accountId}`);
          return;
        }
        if (loanStore.privateLoanCustomerProducts.length === 1 && pageType === PageType.PrivateLoanTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.privateLoan")}/${loanStore.privateLoanCustomerProducts[0].accountId}`,
          );
        }
        if (loanStore.securedLoanCustomerProducts.length === 1 && pageType === PageType.SecuredLoanTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.securedLoan")}/${loanStore.securedLoanCustomerProducts[0].accountId}`,
          );
        }
        if (loanStore.salesFinanceCustomerProducts.length === 1 && pageType === PageType.SalesFinanceTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.salesFinance")}/${
              loanStore.salesFinanceCustomerProducts[0].accountId
            }`,
          );
        }
        if (depositStore.depositCustomerProducts?.length === 1 && pageType === PageType.DepositTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.deposit")}/${depositStore.depositCustomerProducts[0].accountId}`,
          );
        }
        if (leasingStore.leasingCustomerProducts?.length === 1 && pageType === PageType.LeasingTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.leasing")}/${leasingStore.leasingCustomerProducts[0].accountId}`,
          );
        }
      }
    };
    fetchPage();

    return () => {
      if (accountId) {
        rootStore.resetAccountDetails();
      }
    };
  }, [location, contentStore.homePage]);

  useEffect(() => {
    if (uiStore.logoAnimationLoaded && (contentStore.currentPage || contentStore.is404)) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded, contentStore.currentPage, contentStore.is404]);

  const renderCmsPage = (data?: IPage) => {
    if (contentStore.is404) return <NotFoundPage />;
    const length = data?.contentType?.length ?? 0;
    const page = data?.contentType?.[length - 1];

    switch (page) {
      case PageType.MessageInbox:
        return <MessageInbox />;
      case PageType.NewMessage:
        return <NewMessage />;
      case PageType.OffersPage:
        return <OffersPage location={location} />;
      case PageType.LoanTopUpPage:
        return <LoanTopUpPage location={location} />;
      case PageType.TermsPage:
        return <TermsPage />;
      case PageType.ClosingOfDepositAccountPage:
        return <ClosingOfDepositAccountPage />;
      case PageType.CustomerCompliancePage:
        return <CustomerCompliancePage />;
      case PageType.LoanTerminationsPage:
        if (uiStore.locale === Locale.fi) {
          return <FILoanTerminationsPage />;
        }
        return <LoanTerminationsPage />;
      case PageType.UpdateDueDayPage:
        return <UpdateDueDayPage />;
      case PageType.PowerOfAttorneyPage:
        return <PowerOfAttorneyPage />;
      case PageType.PromiseToPayPage:
        return <PromiseToPayPage />;
      case PageType.MileageChangePage:
        return <MileageChangePage />;
      case PageType.ReduceInstallmentsPage:
        return <ReduceInstallmentsPage />;
      case PageType.IncreaseInstallmentsPage:
        return <IncreaseInstallmentsPage />;
      case PageType.ExtendResidualAmountPaymentPage:
        return <ExtendResidualAmountPaymentPage />;
      case PageType.AddInstallmentPage:
        return <AddInstallmentPage />;
      case PageType.AddInsurancePage:
        return <AddInsurancePage />;
      case PageType.TerminateInsurancePage:
        return <TerminateInsurancePage />;
      case PageType.PaymentHolidayPage:
        return <PaymentHolidayPage />;
      case PageType.ContainerPage:
        return <NotFoundPage />;
      default:
        return <ContentArea />;
    }
  };

  if (contentStore.fetchingCmsPage || rootStore.fetchingCurrentAccountDetails) {
    return <CmsSkeleton />;
  }

  return <div className={styles.wrapper}>{renderCmsPage(contentStore.currentPage)}</div>;
});

export default withRoot(CmsPage);
