import React from "react";
import { observer } from "mobx-react";
import styles from "../SelfServicePage.scss";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IReduceInstallmentsPage } from "netbank-shared/src/libs/models/Content/Page";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IReduceInstallmentsForm,
  generateReduceInstallmentsSchema,
} from "netbank-shared/src/libs/forms/PaymentPlanChange";
import { tx } from "netbank-shared/src/libs/i18n";
import {
  EmploymentType,
  checkHasEmployer,
  getEmploymentTypeList,
  getHousingTypeList,
} from "netbank-shared/src/libs/forms/SharedFormDropdowns";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import {
  CheckboxFormField,
  DropdownFormField,
  FileAttachmentFormField,
  FormWrapper,
  InputFormField,
  TextAreaFormField,
} from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { HtmlContent } from "~views/shared";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { templateString, toLocaleString } from "netbank-shared/src/libs/utils";

export const ReduceInstallmentsPage = observer(() => {
  const navigate = useNavigate();
  const { contentStore, loanStore, uiStore } = useStores();
  const { currentPage } = contentStore;
  const page = currentPage as IReduceInstallmentsPage;

  const {
    amount,
    mileage,
    phone,
    message,
    fileUploader,
    employmentType,
    employer,
    occupation,
    housingType,
    income,
    termsText,
    termsCheckbox,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
  } = page;
  const { currentAccount, selfServiceSusbmissionState } = loanStore;

  const methods = useForm<IReduceInstallmentsForm>({
    resolver: yupResolver(
      generateReduceInstallmentsSchema({ locale: uiStore.locale!, accountType: currentAccount?.accountType, page }),
    ),
  });

  useFetchData({
    cleanup: () => loanStore.resetSelfServiceState(),
  });

  if (!currentAccount?.canReduceInstallments) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const employmentTypeOptions = getEmploymentTypeList();
  const housingTypeOptions = getHousingTypeList();
  const selectedEmploymentType = employmentTypeOptions.find(
    (option) => option.value === methods.watch("employmentType"),
  );
  const hasEmployer = selectedEmploymentType && checkHasEmployer(selectedEmploymentType.enumValue as EmploymentType);

  const onSubmit: SubmitHandler<IReduceInstallmentsForm> = async (data) =>
    loanStore.reduceInstallments({
      ...data,
      employmentType: employmentTypeOptions.find((option) => option.value === data.employmentType)?.enumValue,
      housingType: housingTypeOptions.find((option) => option.value === data.housingType)?.enumValue,
    });

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <InputFormField
              type="number"
              label={amount?.label || tx("reduceInstallments.amountLabel")}
              infoPopover={{ popoverTitle: amount?.infoTitle, content: amount?.infoText }}
              name="amount"
              placeholder={amount?.placeholder}
              suffix={uiStore.currencySymbol}
            />
            {currentAccount.accountType === AccountType.SecuredLoan && (
              <InputFormField
                type="number"
                label={mileage?.label || tx("reduceInstallments.mileageLabel")}
                infoPopover={{ popoverTitle: mileage?.infoTitle, content: mileage?.infoText }}
                name="mileage"
                placeholder={mileage?.placeholder}
                suffix="km"
              />
            )}
            <DropdownFormField
              label={employmentType?.label || tx("selfServiceLabels.employmentTypeLabel")}
              infoPopover={{ popoverTitle: employmentType?.infoTitle, content: employmentType?.infoText }}
              name="employmentType"
              list={employmentTypeOptions}
            />
            {hasEmployer && (
              <>
                <InputFormField
                  type="text"
                  label={employer?.label || tx("selfServiceLabels.employerLabel")}
                  infoPopover={{ popoverTitle: employer?.infoTitle, content: employer?.infoText }}
                  name="employer"
                  placeholder={employer?.placeholder}
                />
                <InputFormField
                  type="text"
                  label={occupation?.label || tx("selfServiceLabels.occupationLabel")}
                  infoPopover={{ popoverTitle: occupation?.infoTitle, content: occupation?.infoText }}
                  name="occupation"
                  placeholder={occupation?.placeholder}
                />
              </>
            )}
            <DropdownFormField
              label={housingType?.label || tx("selfServiceLabels.housingTypeLabel")}
              infoPopover={{ popoverTitle: housingType?.infoTitle, content: housingType?.infoText }}
              name="housingType"
              list={housingTypeOptions}
            />
            <InputFormField
              type="number"
              label={income?.label || tx("selfServiceLabels.incomeLabel")}
              infoPopover={{ popoverTitle: income?.infoTitle, content: income?.infoText }}
              name="income"
              suffix={uiStore.currencySymbol}
            />
            <InputFormField
              type="tel"
              label={phone?.label || tx("selfServiceLabels.phoneLabel")}
              infoPopover={{ popoverTitle: phone?.infoTitle, content: phone?.infoText }}
              name="phone"
              placeholder={phone?.placeholder}
              allowedCharacters={/[^0-9+]/g}
            />
            <TextAreaFormField
              label={message?.label || tx("selfServiceLabels.messageLabel")}
              infoPopover={{ popoverTitle: message?.infoTitle, content: message?.infoText }}
              name="message"
              placeholder={message?.placeholder}
            />
            <FileAttachmentFormField
              fileInputId="reduceInstallments"
              name="attachments"
              label={fileUploader?.label || tx("selfServiceLabels.fileUploaderLabel")}
              infoPopover={{ popoverTitle: fileUploader?.infoTitle, content: fileUploader?.infoText }}
              desktopLabel={fileUploader?.desktopLabel}
              desktopLabelSuffix={fileUploader?.desktopLabelSuffix}
              mobileLabel={fileUploader?.mobileLabel}
              maxFileSizeLabel={fileUploader?.maxFileSizeLabel}
              acceptedExtensionsLabel={fileUploader?.acceptedExtensionsLabel}
            />

            <HtmlContent
              className={styles.termsWrapper}
              html={templateString(termsText || tx("selfServiceLabels.termsText"), {
                selfServiceFee: toLocaleString(currentAccount.fees?.reduceInstallmentsFee, undefined, uiStore.locale),
              })}
            />
            <CheckboxFormField
              name="termsAccepted"
              label={termsCheckbox?.label || tx("selfServiceLabels.termsCheckboxLabel")}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
