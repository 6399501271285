import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReactPlugin, AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Helmet } from "react-helmet";
import { initStore, Store } from "netbank-shared/src/stores/Store";
import { StoreContext } from "netbank-shared/src/contexts/storesContext";
import { PageLoader } from "~views/shared";
import { OutdatedBrowser } from "~views/pages/OutdatedBrowser/OutdatedBrowser";
import { getConfig } from "../config/config";
import { WebCommonService } from "./WebCommonService";
import WebPlatformStore from "./WebPlatformStore";
import { ConfirmationPopup } from "~views/shared/ConfirmationPopup/ConfirmationPopup";
import { RetryPage } from "~views/pages/RetryPage/RetryPage";
import { useNavigate } from "react-router-dom";

const onError = () => <RetryPage />;

export const withStore = (Comp: any) => {
  const ComponentWithStore = () => {
    const confirmationPopupRef: RefObject<HTMLDivElement> = useRef(null);
    const [store, setStore] = useState<Store>();
    const navigate = useNavigate();
    const [jentisInitialized, setJentisInitialized] = useState(false);

    const initializeTelemetry = (connectionString?: string) => {
      if (!connectionString) return undefined;

      const reactPlugin = new ReactPlugin();

      const appInsights = new ApplicationInsights({
        config: {
          connectionString,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
          isStorageUseDisabled: true,
        },
      });
      appInsights.loadAppInsights();

      return reactPlugin;
    };

    useEffect(() => {
      if (window.jentis && !jentisInitialized) {
        setJentisInitialized(true);
      }
    }, [window.jentis]);

    useEffect(() => {
      const mountStore = async () => {
        const config = await getConfig();
        const s = initStore(localStorage, localStorage, new WebCommonService(navigate), new WebPlatformStore(config));
        if (!s.telemetryPlugin) {
          s.telemetryPlugin = initializeTelemetry(config?.TELEMETRY_CONNECTION_STRING);
        }

        const mountedStore = await s.onMount(config, window, navigate);
        setStore(mountedStore);
      };

      mountStore();
    }, []);

    const jentisHelmet = (jentisId?: string, jentisDomain?: string) => {
      if (!jentisId || !jentisDomain || jentisInitialized) return null;

      return (
        <Helmet>
          <script>
            {
              // eslint-disable-next-line max-len
              `!function(e,t,n,o){null!==window.localStorage&&"object"==typeof window.localStorage&&"function"==typeof window.localStorage.getItem&&null!==window.sessionStorage&&"object"==typeof window.sessionStorage&&"function"==typeof window.sessionStorage.getItem&&(o=window.sessionStorage.getItem("jts_preview_version")||window.localStorage.getItem("jts_preview_version")||o),window.jentis=window.jentis||{},window.jentis.config=window.jentis.config||{},window.jentis.config.frontend=window.jentis.config.frontend||{},window.jentis.config.frontend.cdnhost=e+"/get/"+n+"/web/"+o+"/",window.jentis.config.frontend.vers=o,window.jentis.config.frontend.env=n,window.jentis.config.frontend.project=t,window._jts=window._jts||[];var s=document.getElementsByTagName("script")[0],i=document.createElement("script");i.async=!0,i.src=window.jentis.config.frontend.cdnhost+"ukmry6.js",s.parentNode.insertBefore(i,s)}("${jentisDomain}","${jentisId}","live","_");`
            }
          </script>
        </Helmet>
      );
    };

    if (!store?.config) {
      return null;
    }

    if (store.telemetryPlugin) {
      return (
        <StoreContext.Provider value={store}>
          <AppInsightsContext.Provider value={store.telemetryPlugin}>
            <AppInsightsErrorBoundary appInsights={store.telemetryPlugin} onError={onError}>
              <>
                {jentisHelmet(store.config.JENTIS_ID, store.config.JENTIS_DOMAIN)}
                {store.uiStore.hasOutdatedBrowser ? (
                  <OutdatedBrowser />
                ) : (
                  <>
                    <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />
                    <PageLoader />
                    <Comp store={store} />
                  </>
                )}
              </>
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        </StoreContext.Provider>
      );
    }

    return (
      <StoreContext.Provider value={store}>
        {jentisHelmet(store.config.JENTIS_ID, store.config.JENTIS_DOMAIN)}
        {store.uiStore.hasOutdatedBrowser ? (
          <OutdatedBrowser />
        ) : (
          <>
            <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />
            <PageLoader />
            <Comp store={store} />
          </>
        )}
      </StoreContext.Provider>
    );
  };

  return ComponentWithStore;
};
