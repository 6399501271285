import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { IAttachment } from "../libs/models/Attachment/Attachment";
import { InboxTabs } from "../libs/models/Message/Inbox";
import { ListItem } from "../libs/models/Content/ListItem";
import { IConversation } from "../libs/models/Message/Conversations";
import {
  INewConversationRequest,
  IReplyRequest,
  ISelectedAccount,
  ISelectedArea,
  ISelectedSubject,
} from "../libs/models/Message/NewMessage";
import { ConversationEmptyState, Direction } from "../libs/models/Content/Enums";
import { CommonService } from "../libs/models/CommonService";
import { MessageApi } from "../libs/api";
import { CancelToken } from "apisauce";
import { CancelTokenSource } from "axios";

export class MessageStore {
  messageApi: MessageApi;

  commonService: CommonService;

  constructor(messageApi: MessageApi, commonService: CommonService) {
    this.messageApi = messageApi;
    this.commonService = commonService;
    makeObservable(this);
  }

  @observable attachments: IAttachment[] = [];

  @observable newMessageBody: string = "";

  @observable replyMessageBody: string = "";

  @observable allConversations?: IConversation[] = undefined;

  @observable pageSize: number = 20;

  @observable hasNextPage: boolean = false;

  @observable selectedAccount?: ISelectedAccount = undefined;

  @observable selectedSubject?: ISelectedSubject = undefined;

  @observable selectedArea?: ISelectedArea = undefined;

  @observable activeTab: InboxTabs = InboxTabs.Inbox;

  @observable conversationData?: IConversation = undefined;

  @observable fetchingConversations: boolean = false;

  @observable fetchingConversation: boolean = false;

  @observable downloadingAttachments: Set<string> = new Set();

  @observable sendingMessage: boolean = false;

  @observable newConversationSuccess?: boolean = undefined;

  @observable replyMessageSuccess?: boolean = undefined;

  // TODO: Should get this from API or CMS
  @observable maxFilesPerMessage: number = 5;

  // TODO: Should get this value from API or CMS
  @observable maxFileSize: number = 5000000; // 5 MB

  @observable invalidFileSize: boolean = false;

  @observable invalidAttachments: boolean = false;

  @observable messageViewContent?: IConversation = undefined;

  @observable conversationEmptyState?: ConversationEmptyState = undefined;

  @observable pollingConversations: boolean = false;

  @observable getConversationCancelTokens: CancelTokenSource[] = [];

  // This is based on FI, possibly different in other countries
  acceptedDocumentMimeTypes = ["application/pdf", "image/jpeg", "image/gif", "image/png", "application/octet-stream"];

  acceptedDocumentExtensions = [".jpg", ".jpeg", ".gif", ".png", ".pdf"];

  @computed
  get inboxConversations(): IConversation[] | undefined {
    return this.allConversations && this.filterConversationList(this.allConversations).filter((c) => !c.isArchived);
  }

  @computed
  get archivedConversations(): IConversation[] | undefined {
    return this.allConversations && this.filterConversationList(this.allConversations).filter((c) => c.isArchived);
  }

  @computed
  get sentConversations(): IConversation[] | undefined {
    return this.allConversations?.filter(
      (c) => c.messages.find((m) => m.direction === Direction.Sent) && !c.isArchived,
    );
  }

  @computed
  get unreadAmount(): number {
    const filteredConversations =
      this.allConversations &&
      this.filterConversationList(this.allConversations)?.filter((conversation) => conversation.hasUnreadMessages);
    return filteredConversations?.length || 0;
  }

  @computed
  get conversations(): IConversation[] | undefined {
    switch (this.activeTab) {
      case InboxTabs.Inbox:
        return this.inboxConversations;
      case InboxTabs.Archive:
        return this.archivedConversations;
      case InboxTabs.Sent:
        return this.sentConversations;
      default:
        return undefined;
    }
  }

  @computed
  get subjectDropdownDisabled(): boolean {
    return !this.selectedAccount;
  }

  @action setMessageViewContent = (content?: IConversation) => {
    this.messageViewContent = content;
  };

  @action removeMessageViewContent = () => {
    this.messageViewContent = undefined;
  };

  @action
  setActiveTab = (tab: InboxTabs) => {
    this.activeTab = tab;
  };

  @action
  setNewMessageBody = (value: string) => {
    this.newMessageBody = value;
  };

  @action
  setReplyMessageBody = (value: string) => {
    this.replyMessageBody = value;
  };

  @action
  setAccount = (listItem: ListItem) => {
    if (listItem.value) {
      this.selectedAccount = {
        label: listItem.label,
        accountNumber: listItem.value,
        accountType: listItem.extra.key,
      };
    }
    if (!listItem.value) {
      this.selectedAccount = undefined;
    }
    this.selectedSubject = undefined;
    this.selectedArea = undefined;
  };

  @action
  setSubject = (listItem: ListItem) => {
    this.attachments = [];
    this.selectedArea = undefined;
    if (listItem.value) {
      this.selectedSubject = {
        label: listItem.label,
        tag: listItem.value,
        areas: listItem.extra.tags,
        showTextarea: listItem.extra.showTextarea,
        sendable: listItem.extra.sendable,
        allowedAttachmentAmountSetting: listItem.extra.allowedAttachmentAmountSetting,
      };

      if (listItem.extra.tags?.length === 1) {
        const areaItem = listItem.extra.tags[0];
        const area: ISelectedArea = {
          label: areaItem.text,
          tag: areaItem.key,
          showTextarea: areaItem.showTextarea,
          sendable: areaItem.sendable,
        };
        this.selectedArea = area;
      }
    }
    if (!listItem.value) {
      this.selectedSubject = undefined;
    }
  };

  @action
  setArea = (listItem: ListItem) => {
    if (listItem.value) {
      this.selectedArea = {
        label: listItem.label,
        tag: listItem.value,
        showTextarea: listItem.extra.showTextarea,
        sendable: listItem.extra.sendable,
      };
    }
    if (!listItem.value) {
      this.selectedArea = undefined;
    }
  };

  @observable newConversationRequest: INewConversationRequest = {
    title: "",
    body: "",
    accountNumber: "",
    accountType: "",
    subject: "",
    area: "",
    files: undefined,
  };

  @observable newReplyMessageRequest: IReplyRequest = {
    conversationId: "",
    body: "",
    files: undefined,
  };

  @action
  addAttachments = (files: File[]) => {
    this.invalidFileSize = false;
    this.invalidAttachments = false;
    files.forEach((file) => {
      const attachment: IAttachment = {
        id: uuidv4(), // identifier while attachments only exist in state (have not yet been uploaded through the APIs)
        fileName: file.name,
        file,
      };
      this.attachments.push(attachment);
    });
  };

  @action
  removeAttachment = (id: string) => {
    this.invalidAttachments = false;
    this.invalidFileSize = false;
    this.attachments = this.attachments.filter((attachment) => attachment.id !== id);
  };

  hasResponseFromCustomerService = (conversation: IConversation) => {
    return conversation.messages.some((message) => message.direction === Direction.Received);
  };

  filterConversationList = (list: IConversation[]) => {
    return list.filter((conversation) => this.hasResponseFromCustomerService(conversation));
  };

  @action
  getConversations = async (loading: boolean = true) => {
    const cancelSource = CancelToken.source();
    this.getConversationCancelTokens.push(cancelSource);

    this.fetchingConversations = loading;
    this.pollingConversations = !loading;
    const response = await this.messageApi.getConversations(cancelSource.token);

    if (response?.ok && response.data) {
      const conversationList = [...response.data.conversations].sort((a, b) => {
        const messagesA = [...a.messages].sort((messageA, messageB) => {
          return new Date(messageB.createdDate).getTime() - new Date(messageA.createdDate).getTime();
        });
        const messagesB = [...b.messages].sort((messageA, messageB) => {
          return new Date(messageB.createdDate).getTime() - new Date(messageA.createdDate).getTime();
        });

        const createdDateA = messagesA[0].createdDate;
        const createdDateB = messagesB[0].createdDate;
        const dateA = new Date(createdDateA).getTime();
        const dateB = new Date(createdDateB).getTime();
        return dateB - dateA;
      });

      this.allConversations = conversationList;
    }
    this.fetchingConversations = false;
    this.pollingConversations = false;
    this.getConversationCancelTokens = this.getConversationCancelTokens.filter(
      (source) => source.token !== cancelSource.token,
    );
  };

  @action
  getConversation = async (id: string) => {
    this.fetchingConversation = true;
    const response = await this.messageApi.getConversation(id);
    if (response?.ok && response.data) {
      const { conversation } = response.data;
      this.setMessageViewContent(conversation);
      const conversationIdFromUrl = this.commonService.getQueryParam("conversationId");
      if (conversationIdFromUrl === conversation.conversationId) {
        this.conversationData = conversation;
      } else {
        this.conversationEmptyState = ConversationEmptyState.ConversationNotFound;
      }
    }
    this.fetchingConversation = false;
  };

  @action
  createConversation = async () => {
    this.sendingMessage = true;
    if (this.selectedAccount && this.selectedSubject) {
      this.newConversationRequest = {
        accountNumber: this.selectedAccount.accountNumber,
        accountType: this.selectedAccount.accountType,
        subject: this.selectedSubject.tag,
        area: this.selectedSubject.areas?.length > 0 && this.selectedArea ? this.selectedArea.tag : "",
        files: this.attachments.map((a) => a.file).filter((f) => !!f) as File[], // Filter doesn't know what the end type is, therefore "as File[]" is needed
        body:
          this.newMessageBody ||
          `${this.selectedAccount.label} ${this.selectedSubject.label} ${
            this.selectedArea ? this.selectedArea.label : ""
          }`,
        title: `${this.selectedAccount.label} ${this.selectedSubject.label}`,
      };

      const response = await this.messageApi.createConversation(this.newConversationRequest);

      if (response?.ok) {
        this.newConversationSuccess = true;
      } else {
        this.newConversationSuccess = false;
      }
    }
    this.sendingMessage = false;
  };

  @action
  reply = async (conversationId: string) => {
    this.sendingMessage = true;
    if (this.replyMessageBody) {
      this.newReplyMessageRequest = {
        conversationId,
        body: this.replyMessageBody,
        files: this.attachments.map((a) => a.file).filter((f) => !!f) as File[], // Filter doesn't know what the end type is, therefore "as File[]" is needed
      };

      const response = await this.messageApi.createReplyMessage(this.newReplyMessageRequest);

      if (response?.ok) {
        this.conversationEmptyState = ConversationEmptyState.ReplyMessageSuccess;
      } else {
        this.conversationEmptyState = ConversationEmptyState.ReplyMessageFailed;
      }
    }
    this.sendingMessage = false;
  };

  @action
  markAsRead = async (conversationId: string) => {
    // Cancel all ongoing get-conversation requests as they can overwrite the updated read state
    this.getConversationCancelTokens.forEach((source) => {
      source.cancel();
    });
    const response = await this.messageApi.markConversationAsRead(conversationId);

    // If marAsRead request succeeds we can assume the conversation will have the hasUnreadMessage
    // state set to false. To avoid flashing read/unread state while waiting for get conversations,
    // we set it directly in state.
    if (response?.ok) {
      if (this.allConversations) {
        const index = this.allConversations?.findIndex((c) => c.conversationId === conversationId);
        const updatedConversations = this.allConversations;
        updatedConversations[index].hasUnreadMessages = false;
        this.allConversations = updatedConversations;
      }
    }
  };

  @action
  archive = async (conversationId: string) => this.messageApi.archiveConversation(conversationId);

  @action
  downloadAttachment = async (attachmentId: string) => {
    if (this.downloadingAttachments.has(attachmentId)) return;

    this.downloadingAttachments.add(attachmentId);
    const response = await this.messageApi.downloadAttachment(attachmentId);

    if (response?.ok && response.data?.attachment) {
      const { fileData, fileName, mimeType } = response.data.attachment;
      if (fileData) {
        await this.commonService.downloadDocument(fileData, fileName, mimeType);
      }
    }
    this.downloadingAttachments.delete(attachmentId);
  };

  @action
  resetConversationData = () => {
    this.conversationData = undefined;
  };

  @action
  resetStore = (resetConversations = false) => {
    this.selectedAccount = undefined;
    this.selectedSubject = undefined;
    this.selectedArea = undefined;
    this.attachments = [];
    this.newMessageBody = "";
    this.replyMessageBody = "";
    this.newConversationRequest = {
      accountNumber: "",
      accountType: "",
      subject: "",
      area: "",
      body: "",
      files: undefined,
      title: "",
    };
    this.newReplyMessageRequest = {
      conversationId: "",
      body: "",
      files: undefined,
    };
    this.activeTab = InboxTabs.Inbox;
    this.conversationData = undefined;
    this.fetchingConversations = false;
    this.fetchingConversation = false;
    this.newConversationSuccess = undefined;
    this.replyMessageSuccess = undefined;
    this.invalidFileSize = false;
    this.invalidAttachments = false;
    this.sendingMessage = false;
    this.messageViewContent = undefined;
    this.conversationEmptyState = undefined;
    if (resetConversations) {
      this.allConversations = undefined;
      this.hasNextPage = false;
    }
  };
}
