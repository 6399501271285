import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Locale } from "libs/models/Content/Enums";
import { capitalize } from "./commands";

dayjs.extend(isBetween);

const dateIsBetween = (date: Dayjs, from: Dayjs, to: Dayjs) => date.isBetween(from, to, undefined, "[]");

export const isMorning = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(5).minute(0), today.hour(9).minute(59));
};

export const isForenoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(10).minute(0), today.hour(11).minute(59));
};

export const isNoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(12).minute(0), today.hour(14).minute(59));
};

export const isAfternoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(15).minute(0), today.hour(17).minute(59));
};

export const isEvening = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(18).minute(0), today.hour(23).minute(59));
};

export const isNight = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(0).minute(0), today.hour(4).minute(59));
};

export const formatDate = (dateToFormat?: string | Date | Dayjs | null) => {
  if (!dateToFormat) return undefined;
  return dayjs(dateToFormat).format("L").toString(); // "L" is a format based on global locale
};

export const getDaysLeftUntil = (targetDate: string, includeLastDay: boolean = true) => {
  const today = dayjs().startOf("day");
  const diff = dayjs(targetDate).diff(today, "days");
  return Math.max(0, diff + (includeLastDay ? 1 : 0));
};

export const getMonthFromInt = (monthNumber: number, locale?: string) => {
  const date = new Date();
  date.setMonth(monthNumber);
  return date.toLocaleString(locale, { month: "long" });
};

export const getMonthNames = (locale: Locale) => {
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    .map((month) => {
      const date = new Date();
      date.setMonth(month);
      return date;
    })
    .map((date) => capitalize(date.toLocaleString(locale, { month: "long" })));

  return months;
};

export const sortDates = (dates: Date[]) => dates.sort((a, b) => a.getTime() - b.getTime());

export const monthIsConsecutive = (date: Date, monthIndex: number, year?: number) => {
  if (!date) return false;

  const compareDate = dayjs(date);
  const nextMonth = compareDate.add(1, "month");
  const prevMonth = compareDate.subtract(1, "month");
  let isConsecutiveDate = nextMonth.get("month") === monthIndex || prevMonth.get("month") === monthIndex;
  if (year) {
    isConsecutiveDate =
      (nextMonth.get("month") === monthIndex && nextMonth.get("year") === year) ||
      (prevMonth.get("month") === monthIndex && prevMonth.get("year") === year);
  }

  return isConsecutiveDate;
};
