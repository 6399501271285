import React from "react";
import { observer } from "mobx-react";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { PendingApplicationState, TopUpFlowStep } from "netbank-shared/src/libs/models/Content/Enums";
import styles from "./LoanTopUpFlow.scss";
import rootStyles from "~views/pages/Root.scss";
import checkmarkIcon from "~assets/checkmark.svg";
import { LoanTopUpAmountStep } from "./LoanTopUpSteps/LoanTopUpAmountStep";
import { LoanTopUpApplicantEmploymentStep } from "./LoanTopUpSteps/LoanTopUpApplicantEmploymentStep";
import { LoanTopUpApplicantDetailsSummaryStep } from "./LoanTopUpSteps/LoanTopUpApplicantDetailsSummaryStep";
import { LoanTopUpDecisionStep } from "./LoanTopUpSteps/LoanTopUpDecisionStep";
import { LoanTopUpFinalStep } from "./LoanTopUpSteps/LoanTopUpFinalStep";
import { LoanTopUpPayoutSelectionStep } from "./LoanTopUpSteps/LoanTopUpPayoutSelectionStep";
import { LoanTopUpApplicantInformationStep } from "./LoanTopUpSteps/LoanTopUpApplicantInformationStep";
import { LoanTopUpApplicantLivingSituationStep } from "./LoanTopUpSteps/LoanTopUpApplicantLivingSituationStep";
import { LoanTopUpEBAStep } from "./LoanTopUpSteps/LoanTopUpEBAStep";
import { LoanTopUpProcessing } from "./LoanTopUpSteps/LoanTopUpProcessing";

export interface ILoanTopUpFlowProps {
  data: ILoanTopUpPage;
}

export const LoanTopUpFlow = observer(({ data }: ILoanTopUpFlowProps) => {
  const { offerStore } = useStores();

  const dataLayer = useTracking((window as any).dataLayer);

  const { loanAmountStep, applicantDetailsStep, payoutSelectionStep, finalStep } = data;

  const renderStepHeaderElement = (name: string, step: number) => {
    const stepClasses = [styles.stepNumber];
    const passedStep = offerStore.currentTopUpStep > step;
    const activeStep = offerStore.currentTopUpStep === step;

    if (activeStep) {
      stepClasses.push(styles.active);
    }

    if (passedStep) {
      stepClasses.push(styles.passed);
    }
    return (
      <div className={styles.stepElement}>
        <div className={stepClasses.join(" ")}>
          {passedStep ? <img src={checkmarkIcon} alt="checkmark" /> : <span className={rootStyles.bold}>{step}</span>}
        </div>
        <span className={styles.stepName}>{name}</span>
      </div>
    );
  };

  const setStep = (action?: () => void) => {
    if (action) {
      action();
      const newStep = offerStore.currentTopUpStep;
      dataLayer?.pushPageView(`${window.location.pathname}step-${newStep}`, window.location.search);
    }
    window.scrollTo(0, 0);
  };

  const setNextTopUpStep = () => {
    setStep(offerStore.setNextTopUpStep);
  };

  const setPreviousTopUpStep = () => {
    setStep(offerStore.setPreviousTopUpStep);
  };

  const setTopUpFlowStep = (step: TopUpFlowStep) => {
    offerStore.setTopUpFlowStep(step);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.wrapper}>
      {offerStore.currentPendingApplication?.applicationState !== PendingApplicationState.Rejected && (
        <div className={styles.stepHeader}>
          {renderStepHeaderElement(loanAmountStep.stepName, 1)}
          {renderStepHeaderElement(applicantDetailsStep.stepName, 2)}
          {renderStepHeaderElement(payoutSelectionStep.stepName, 3)}
          {renderStepHeaderElement(finalStep.stepName, 4)}
        </div>
      )}
      {offerStore.currentTopUpStep === 1 && <LoanTopUpAmountStep data={data} setNextTopUpStep={setNextTopUpStep} />}
      {offerStore.currentTopUpStep === 2 && offerStore.topUpFlowStep === TopUpFlowStep.ApplicantInformationStep && (
        <LoanTopUpApplicantInformationStep
          data={data}
          setNextTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.ApplicantEmploymentStep)}
          setPreviousTopUpStep={setPreviousTopUpStep}
        />
      )}

      {offerStore.currentTopUpStep === 2 && offerStore.topUpFlowStep === TopUpFlowStep.ApplicantEmploymentStep && (
        <LoanTopUpApplicantEmploymentStep
          data={data}
          setNextTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.ApplicantLivingSituationStep)}
          setPreviousTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.ApplicantInformationStep)}
        />
      )}

      {offerStore.currentTopUpStep === 2 && offerStore.topUpFlowStep === TopUpFlowStep.ApplicantLivingSituationStep && (
        <LoanTopUpApplicantLivingSituationStep
          data={data}
          setNextTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.SummaryStep)}
          setPreviousTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.ApplicantEmploymentStep)}
        />
      )}

      {offerStore.currentTopUpStep === 2 && offerStore.topUpFlowStep === TopUpFlowStep.SummaryStep && (
        <LoanTopUpApplicantDetailsSummaryStep
          data={data}
          setNextTopUpStep={() => {
            setNextTopUpStep();
          }}
          setPreviousTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.ApplicantLivingSituationStep)}
        />
      )}

      {offerStore.currentTopUpStep === 3 && offerStore.topUpFlowStep === TopUpFlowStep.Processing && (
        <LoanTopUpProcessing data={data} />
      )}

      {offerStore.currentTopUpStep === 3 && offerStore.topUpFlowStep === TopUpFlowStep.DecisionStep && (
        <LoanTopUpDecisionStep data={data} />
      )}

      {offerStore.currentTopUpStep === 3 && offerStore.topUpFlowStep === TopUpFlowStep.PayoutSelectionStep && (
        <LoanTopUpPayoutSelectionStep
          data={data}
          setNextTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.EBAStep)}
          setPreviousTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.DecisionStep)}
        />
      )}

      {offerStore.currentTopUpStep === 3 && offerStore.topUpFlowStep === TopUpFlowStep.EBAStep && (
        <LoanTopUpEBAStep
          data={data}
          setPreviousTopUpStep={() => setTopUpFlowStep(TopUpFlowStep.PayoutSelectionStep)}
        />
      )}

      {offerStore.currentTopUpStep === 4 && <LoanTopUpFinalStep data={data} />}
    </div>
  );
});
