import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { INewMessagePage } from "netbank-shared/src/libs/models/Content/Page";
import { AllowedAttachmentAmountSetting, Colors } from "netbank-shared/src/libs/models/Content/Enums";
import { Button, Dropdown } from "~views/shared";
import { TextArea } from "~views/shared/TextArea/TextArea";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "./NewConversation.scss";
import rootStyles from "../../Root.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { FaqBlock } from "~views/shared/ContentArea/Blocks/FaqBlock";
import { MessageEmptyState } from "./MessageEmptyState";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import {
  getAreasFromSubject,
  getFilteredFaqs,
  getAccountDropdownList,
  getProductForAccount,
  getSubjectsFromProduct,
  getMaxAttachmentsAmount,
} from "netbank-shared/src/libs/utils";
import { FileAttachment } from "~views/shared/FileAttachment/FileAttachment";
import { getQueryParam } from "~utils/misc";

export const NewConversation = observer(() => {
  const { messageStore, contentStore, uiStore, rootStore } = useStores();
  const page = contentStore.currentPage as INewMessagePage;
  const navigate = useNavigate();

  const accountIdFromQueryParam = getQueryParam(window.location.search, "accountNumber");
  const subjectFromQueryParam = getQueryParam(window.location.search, "subject");

  const products = page?.productDropdown;

  useEffect(() => {
    const account = accountList.find((acc) => acc.value === accountIdFromQueryParam);
    if (account) messageStore.setAccount(account);

    if (messageStore.selectedAccount) {
      const product = getProductForAccount(messageStore.selectedAccount, products);
      const subjects = (product && getSubjectsFromProduct(product, uiStore.lang)) || [];
      const subject = subjects.find((s) => s.value.toLowerCase() === subjectFromQueryParam.toLowerCase());
      if (subject) messageStore.setSubject(subject);
    }

    return () => messageStore.resetStore();
  }, []);

  const handleCancel = () => {
    navigate(page?.messageInboxPage ?? `/${tx("routing.lang")}`);
    messageStore.resetStore();
  };

  const [invalidProduct, setInvalidProduct] = useState(false);
  const [invalidSubject, setInvalidSubject] = useState(false);
  const [invalidArea, setInvalidArea] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);

  const selectAccountText = page.selectAccountText || tx("message.selectAccountText");
  const selectSubjectText = page.selectSubjectText || tx("message.selectSubjectText");
  const selectAreaText = page.selectAreaText || tx("message.selectAreaText");

  const { accounts } = rootStore;

  const accountList = getAccountDropdownList(accounts, products);

  // Get product based on selected account type
  const product = messageStore.selectedAccount && getProductForAccount(messageStore.selectedAccount, products);

  // Get subjects from product
  const subjects = (product && getSubjectsFromProduct(product, uiStore.lang)) || [];

  // Get areas from selected subject
  const areas = (messageStore.selectedSubject && getAreasFromSubject(messageStore.selectedSubject)) || [];

  // Filter FAQ's based on selected product, subject and area
  const rawFaqs = page.faqBlocks || [];
  const productTag = messageStore.selectedAccount?.accountType;
  const subjectTag = messageStore.selectedSubject?.tag;
  const areaTag = messageStore.selectedArea?.tag;
  const faqs = getFilteredFaqs(rawFaqs, productTag, subjectTag, areaTag);

  const showTextarea =
    product?.showTextarea || messageStore.selectedSubject?.showTextarea || messageStore.selectedArea?.showTextarea;

  const showFileAttachment =
    !!messageStore.selectedSubject?.allowedAttachmentAmountSetting &&
    messageStore.selectedSubject.allowedAttachmentAmountSetting !== AllowedAttachmentAmountSetting.None;

  const sendable = product?.sendable || messageStore?.selectedSubject?.sendable || messageStore.selectedArea?.sendable;

  const handleSubmit = async () => {
    const { selectedSubject, selectedArea, newMessageBody } = messageStore;

    let validInput = true;

    if (!product) {
      setInvalidProduct(true);
      validInput = false;
    }
    if (!selectedSubject) {
      setInvalidSubject(true);
      validInput = false;
    }
    if (selectedSubject?.areas && selectedSubject.areas.length > 0 && !selectedArea) {
      setInvalidArea(true);
      validInput = false;
    }
    if (showTextarea && !newMessageBody) {
      setInvalidMessage(true);
      validInput = false;
    }
    if (validInput) {
      await messageStore.createConversation();
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {messageStore.newConversationSuccess && <MessageEmptyState data={page.happyCaseEmptyState} />}
      {messageStore.newConversationSuccess === false && <MessageEmptyState data={page.errorCaseEmptyState} />}
      {messageStore.newConversationSuccess === undefined && (
        <>
          {page.pageDescription && (
            <section className={styles.pageDescription}>
              <HtmlContent html={page.pageDescription} />
            </section>
          )}
          <section className={uiStore.isMobile ? rootStyles.white : ""}>
            <div className={styles.newMessageWrapper}>
              <div className={styles.dropdownSection}>
                <div className={styles.dropdownWrapper}>
                  <Dropdown
                    list={accountList}
                    onChange={(item) => {
                      messageStore.setAccount(item);
                      setInvalidProduct(false);
                      setInvalidArea(false);
                    }}
                    fontWeight="regular"
                    value={
                      messageStore.selectedAccount !== undefined
                        ? messageStore.selectedAccount.label
                        : selectAccountText
                    }
                    label={page.accountLabelText || tx("message.accountLabelText")}
                    innerLabel={selectAccountText}
                    error={(invalidProduct && tx("message.productError")) || undefined}
                  />
                </div>
                <div className={styles.dropdownWrapper}>
                  <Dropdown
                    list={subjects}
                    onChange={(item) => {
                      messageStore.setSubject(item);
                      setInvalidSubject(false);
                    }}
                    disabled={messageStore.subjectDropdownDisabled}
                    fontWeight="regular"
                    value={
                      messageStore.selectedSubject !== undefined
                        ? messageStore.selectedSubject.label
                        : selectSubjectText
                    }
                    label={page.subjectLabelText || tx("message.subjectLabelText")}
                    innerLabel={selectSubjectText}
                    error={(invalidSubject && tx("message.subjectError")) || undefined}
                  />
                </div>
              </div>
              <div className={styles.dropdownSection}>
                <div className={styles.dropdownWrapper}>
                  {areas && areas.length > 0 && (
                    <Dropdown
                      list={areas || []}
                      onChange={(item) => {
                        messageStore.setArea(item);
                        setInvalidArea(false);
                      }}
                      fontWeight="regular"
                      value={messageStore.selectedArea !== undefined ? messageStore.selectedArea.label : selectAreaText}
                      label={page.areaLabelText || tx("message.areaLabelText")}
                      innerLabel={selectAreaText}
                      error={(invalidArea && tx("message.areaError")) || undefined}
                    />
                  )}
                </div>
              </div>
              <div>
                {showFileAttachment && (
                  <FileAttachment
                    id="newConversation"
                    acceptedMimeTypes={messageStore.acceptedDocumentMimeTypes}
                    acceptedExtensions={messageStore.acceptedDocumentExtensions}
                    attachments={messageStore.attachments}
                    addAttachments={(attachments) => messageStore.addAttachments(attachments)}
                    removeAttachment={(fileId) => messageStore.removeAttachment(fileId)}
                    maxAttachments={getMaxAttachmentsAmount(
                      messageStore.selectedSubject?.allowedAttachmentAmountSetting ||
                        AllowedAttachmentAmountSetting.None,
                      messageStore.maxFilesPerMessage,
                    )}
                  />
                )}
              </div>
              {faqs.length > 0 && (
                <div className={styles.faqWrapper}>
                  <FaqBlock
                    label={page.faqSectionHeaderText}
                    faqBlocks={faqs}
                    color={Colors.BlackSqueeze}
                    noHorizontalPadding
                    noBorder
                  />
                </div>
              )}
              {showTextarea && (
                <TextArea
                  rows={6}
                  label={page.newMessageText || tx("message.newMessage")}
                  placeholder={page.textareaPlaceholder || tx("message.textareaPlaceholder")}
                  onChange={(e) => {
                    messageStore.setNewMessageBody(e.target.value);
                    setInvalidMessage(!e.target.value);
                  }}
                  error={(invalidMessage && tx("message.messageError")) || undefined}
                />
              )}
              <div className={styles.buttonWrapper}>
                <div className={styles.buttonActionsWrapper}>
                  <Button
                    title={page.cancelText || tx("misc.cancel")}
                    bordered
                    centered
                    borderColor="grey"
                    onClick={() => handleCancel()}
                  />
                  {sendable && (
                    <Button
                      title={page.sendMessageText || tx("message.sendMessage")}
                      bordered
                      centered
                      color="red"
                      borderColor="grey"
                      onClick={() => handleSubmit()}
                      loading={messageStore.sendingMessage}
                      trackingCategory={TrackingCategory.Messages}
                      trackingAction={TrackingAction.MessageCTA}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <ContentArea />
        </>
      )}
    </>
  );
});
