import { v4 as uuidv4 } from "uuid";

// This is based on FI, possibly different in other countries
export const acceptedDocumentMimeTypes = [
  "application/pdf",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/octet-stream",
];

export const acceptedDocumentExtensions = [".jpg", ".jpeg", ".gif", ".png", ".pdf"];

export const mapFilesToAttachments = (files: File[]) => {
  return files.map((file) => mapFileToAttachment(file));
};

export const mapFileToAttachment = (file: File) => {
  return {
    id: uuidv4(), // identifier while attachments only exist in state (have not yet been uploaded through the APIs)
    fileName: file.name,
    file,
  };
};
