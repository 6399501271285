import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RadioButtons } from "../RadioButtons/RadioButtons";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";

interface IRadioFormFieldProps {
  name: string;
  label: string;
  topText?: string;
  list: ListItem[];
  triggerValidation?: boolean; // Useful/needed when validation depends on other input values
  disabled?: boolean;
  infoPopover?: IInfoPopoverProps;
  valueResetList?: string[]; // List of field names that should reset onChange
}

export const RadioFormField = ({
  label,
  topText,
  name,
  list,
  triggerValidation,
  disabled,
  infoPopover,
  valueResetList = [],
}: IRadioFormFieldProps) => {
  const {
    control,
    trigger,
    resetField,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef, fieldState }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <RadioButtons
            {...field}
            list={list}
            onChange={(item) => {
              field.onChange(item.value);
              valueResetList.forEach((valueName) => {
                resetField(valueName);
              });
              if (triggerValidation) trigger();
            }}
            label={label}
            topText={topText}
            error={fieldState.error?.message?.toString()}
            disabled={disabled || isSubmitting}
            infoPopover={infoPopover}
          />
        );
      }}
    />
  );
};
